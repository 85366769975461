import {
    REPORTS_CARGANDO,
    REPORTS_ERROR,
    REPORTS_CLEAN_STATE,
    REPORTS_CHANGE_STATE,
    GetReportsFilters,
    PostSendReports,
    PostDownloadReports,
  } from "./reportTypes";
  
  import { errorHandler } from "../../components-api/ConfigApi";
  
  const parserJson = (obj) => {
    var jsonSerialize = "";
    for (const prop in obj) {
      jsonSerialize += `${prop}=${prop === "paychecks" ? "[" : ""}${obj[prop]}${
        prop === "paychecks" ? "]" : ""
      }&`;
    }
    jsonSerialize = jsonSerialize.slice(0, -1);
    return jsonSerialize;
  };
  
  export const reportsCleanState = () => (dispatch) => {
    dispatch({
      type: REPORTS_CLEAN_STATE,
    });
  };
  
  export const changeState = (variable, value) => (dispatch) => {
    dispatch({
      type: REPORTS_CHANGE_STATE,
      payload: {
        variable,
        value
      }
    })
  } 
  
  export const reportsMethods = (data, method) => async (dispatch) => {
    dispatch({
      type: REPORTS_CARGANDO,
    });
    try {
      let answer;
      switch (method) {
        case "GetReportsFilters":
          answer = await GetReportsFilters(data);
          break;
        case "PostSendReports":
          const dataFilterMeals = parserJson(data);
          answer = await PostSendReports(dataFilterMeals);
          break;
        case "PostDownloadReports":
          const subModel = parserJson(data);
          answer = await PostDownloadReports(subModel);
          break;
        default:
          break;
      }
  
      dispatch({
        type: answer.type,
        payload: answer.res.data,
      });
    } catch (error) {
      let messageError = errorHandler(error);
      dispatch({
        type: REPORTS_ERROR,
        payload: messageError,
      });
    }
  };
  
import React from 'react'
import './Loader.css'
import { Spinner } from 'reactstrap';

export const Loader = () => {
    return (
        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    )
}

export const Loader2 = () => {
  return (
    <div>
      <Spinner color="primary" />
    </div>
  );
}
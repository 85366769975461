import { createSelector } from "reselect";

const selectModule = (state) => state.cmsReducer;
const selectEntity = createSelector(selectModule, (module) => module.ui);

const selectStatusLoading = createSelector(
  selectEntity,
  (entity) => entity.loading.api_actions.cargando
);

export { selectStatusLoading };

import {
  INTEGRITY_CARGANDO,
  INTEGRITY_ERROR,
  INTEGRITY_CLEAN_STATE,
  INTEGRITY_CHANGE_STATE,
  GetCompanies,
  GetModules,
  GetSubModules,
} from "./integrityTypes";

import { errorHandler } from "../../components-api/ConfigApi";

const parserJson = (obj) => {
  var jsonSerialize = "";
  for (const prop in obj) {
    jsonSerialize += `${prop}=${prop === "paychecks" ? "[" : ""}${obj[prop]}${
      prop === "paychecks" ? "]" : ""
    }&`;
  }
  jsonSerialize = jsonSerialize.slice(0, -1);
  return jsonSerialize;
};

export const integrityCleanState = () => (dispatch) => {
  dispatch({
    type: INTEGRITY_CLEAN_STATE,
  });
};

export const changeState = (variable, value) => (dispatch) => {
  dispatch({
    type: INTEGRITY_CHANGE_STATE,
    payload: {
      variable,
      value
    }
  })
} 

export const integrityMethods = (data, method) => async (dispatch) => {
  dispatch({
    type: INTEGRITY_CARGANDO,
  });
  try {
    let answer;
 
    switch (method) {
      case "GetCompanies":
        answer = await GetCompanies();
        break;
      case "GetModules":
        answer = await GetModules(data);
        break;
      case "GetSubModules":
        //const subModel = parserJson(data);
        answer = await GetSubModules(data);
        break;
      default:
        break;
    }

    dispatch({
      type: answer.type,
      payload: answer.res.data,
    });
  } catch (error) {
    let messageError = errorHandler(error);
    dispatch({
      type: INTEGRITY_ERROR,
      payload: messageError,
    });
  }
};

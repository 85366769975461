import React, { useEffect } from "react";
import { StyledComponents } from "../components-config";
import { useDispatch } from "react-redux";
import { changeFilterStructureOptionEmployee } from "./actions/actions";

const OptionStructureTypeEmployee = () => {
  const dispatch = useDispatch();

  function handlerChange(e) {
    dispatch(changeFilterStructureOptionEmployee(e.target.value));
  }

  useEffect(() => {
    //dispatch(getTypes());
  }, []);

  return (
    <>
      <StyledComponents.SpecialContainer
        className="bg-grey130"
        padding="2px"
        margin="0 4px 0 0"
        borderRadius="40px"
        width="220px"
        minWidth="220px"
        height="32px"
      >
      <StyledComponents.MyInput
        borderRadius="40px"
        height="28px"
        textAlign="left"
        placeholder={"1, 2, 3..."}
        fontSize="12px"
        padding="10px"
        width="214px"
        minWidth="214px"
        className="font-weight-bold"
        onChange={handlerChange}
      ></StyledComponents.MyInput>
      </StyledComponents.SpecialContainer>
    </>
  )
}

export default OptionStructureTypeEmployee;
import {
  CAFETERIA_CARGANDO,
  CAFETERIA_ERROR,
  CAFETERIA_CLEAN_STATE,
  CAFETERIA_CHANGE_STATE,
  GetCafeteriaMeals,
  GetCafeteriaMealsFilter,
  GetQr,
} from "./cafeteriaTypes";

import { errorHandler } from "../../components-api/ConfigApi";

export const cafeteriaCleanState = () => (dispatch) => {
  dispatch({
    type: CAFETERIA_CLEAN_STATE,
  });
};

export const changeState = (variable, value) => (dispatch) => {
  dispatch({
    type: CAFETERIA_CHANGE_STATE,
    payload: {
      variable,
      value
    }
  })
} 

export const cafeteriaMethods = (data, method) => async (dispatch) => {
  dispatch({
    type: CAFETERIA_CARGANDO,
  });
  try {
    let answer;
    switch (method) {
      case "GetCafeteriaMeals":
        answer = await GetCafeteriaMeals(data);
        break;
      case "GetCafeteriaMealsFilter":
        answer = await GetCafeteriaMealsFilter(data);
        break;
      case "GetQr":
        answer = await GetQr();
        break;
      default:
        break;
    }

    dispatch({
      type: answer.type,
      payload: answer.res.data,
    });
  } catch (error) {
    let messageError = errorHandler(error);
    dispatch({
      type: CAFETERIA_ERROR,
      payload: messageError,
    });
  }
};

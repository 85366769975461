import React from "react";
import { useState } from "react";
import { Assets } from "../components-config";

const CheckBox = ({ onChange, status }) => {
  const [toggle, setToggle] = useState(status);

  const changeHandler = (e) => {
    setToggle(!toggle);
    onChange(!toggle);
  };

  return (
    <Assets.CheckBoxIcon toggle={toggle} onClick={(e) => changeHandler()}></Assets.CheckBoxIcon>
  );
};

export default CheckBox;

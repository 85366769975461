import {
    CFDIS_CARGANDO,
    CFDIS_ERROR,
    CFDIS_CLEAN_STATE,
    CFDIS_CHANGE_STATE,
    GetCfdisList,
    CfdisTransfer
  } from "./cfdisTypes";
  
  import { errorHandler } from "../../components-api/ConfigApi";
  
  export const cfdisCleanState = () => (dispatch) => {
    dispatch({
      type: CFDIS_CLEAN_STATE,
    });
  };
  
  export const changeState = (variable, value) => (dispatch) => {
    dispatch({
      type: CFDIS_CHANGE_STATE,
      payload: {
        variable,
        value
      }
    })
  } 
  
  export const cfdisMethods = (data, method) => async (dispatch) => {
    dispatch({
      type: CFDIS_CARGANDO,
    });
    try {
      let answer;
      switch (method) {
        case "GetCfdisList":
          answer = await GetCfdisList(data);
          break;
        case "CfdisTransfer":
          answer = await CfdisTransfer(data);
          break;
        default:
          break;
      }
  
      dispatch({
        type: answer.type,
        payload: answer.res.data,
      });
    } catch (error) {
      let messageError = errorHandler(error);
      dispatch({
        type: CFDIS_ERROR,
        payload: messageError,
      });
    }
  };
  
import TransferFile from "../../../components/UploadFile";
import { Modals } from "../../../../../components-config";
import { useSelector } from "react-redux";
import { selectStatusLoading } from "../../../store/selectors/ui";

function TransferContainer({ setting }) {
  const isLoading = useSelector(selectStatusLoading);

  return (
    <>
      {isLoading && <Modals.ModalLoading active={true} />}
      <TransferFile setting={setting} />
    </>
  );
}

export default TransferContainer;

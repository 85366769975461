import React from "react";
import {useHistory} from 'react-router-dom'
import { Container } from "reactstrap";
import { LoginPage } from "./session";
import { Navbars, Assets, StyledComponents } from "../components-config";

export const MainLayout = ({ children, focusInput, classContainer }) => {
  const hasToken = localStorage.getItem('token');
  const history = useHistory();
  hasToken && history.push('./dashboard')
  return (
    <StyledComponents.MyLayout
      onClick={focusInput}
      position="relative"
      height="100vh"
    >
      <Navbars.Home />

      <Container className="">{children}</Container>
      <StyledComponents.DivToRelative
        right="0"
        bottom="0"
        zIndex="-10"
        width="none"
      >
        <Assets.ImgWorkersHero></Assets.ImgWorkersHero>
      </StyledComponents.DivToRelative>
    </StyledComponents.MyLayout>
  );
};

export const LandingPage = () => {
  return (
    <MainLayout>
      <LoginPage></LoginPage>
    </MainLayout>
  );
};

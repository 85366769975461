import React from "react";
import styled from "styled-components";
import { SpecialContainer, MyButton, MyP } from "./StyledComponents";
import { signOff } from "../components-api/ConfigApi";

const MyCard = styled.div`
  max-width: 430px;

  div.description {
    height: 130px;
    margin-bottom: 10px;
    border-radius: 0 40px 0 0;
    padding: 20px 25px;
  }

  div:last-child {
    padding: 30px 25px;
    background: #eef1f6;
    border-radius: 0 0 0 40px;
  }

  p {
    font-size: 1.25rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &.card-purple div.description {
    background: #4b2192;
    color: white;
  }

  &.card-yellow div.description {
    background: #fff500;
    color: black;
  }

  @media (max-width: 400px) {
    p {
      font-size: 1rem;
    }
  }
`;

export const MainCardLayout = ({ description, children, typeCard }) => {
  return (
    <>
      <MyCard className={typeCard}>
        <div className="description">{description}</div>
        <div className="">{children}</div>
      </MyCard>
    </>
  );
};
const MyCard2 = styled.div`
  max-width: 230px;
  width: 230px;
  div.description2 {
    height: 95px;
    margin-bottom: 4px;
    border-radius: 40px 0 0 0;
    padding: 20px 25px;
  }
  div:last-child {
    padding: 20px 25px;
    background: #eef1f6;
    border-radius: 0 0 40px 0;
  }

  p {
    font-size:14px;
  }

  &.card-purple div.description2 {
    background: #511797;
    color: white;
  }

  &.card-yellow div.description2 {
    background: #fff500;
    color: black;
  }
`;

export const SecondCardLayout = ({
  description,
  children,
  typeCard,
  className = "text-center",
}) => {
  return (
    <>
      <MyCard2 className={typeCard}>
        <div className={`description2 ${className}`}>{description}</div>
        <div>{children}</div>
      </MyCard2>
    </>
  );
};

export const CardUser = () => {
  const logOut = (e) => {
    signOff(e);
  };
  return (
    <>
      <SecondCardLayout
        description={
          <>
            <MyP fontSize="14px">Usuario:</MyP>
            <MyP fontSize="14px" className="font-weight-bold">
              {localStorage.getItem("email")}
            </MyP>
          </>
        }
        typeCard="card-purple"
        className=""
      >
        <SpecialContainer margin=" 0 0 20px 0">
          <MyButton
            as="a"
            href="/change-password-from-user"
            className="btn-yellow font-weight-bold "
          >
            Cambiar Contraseña
          </MyButton>
        </SpecialContainer>
        <MyButton
          onClick={logOut}
          className="btn-red font-weight-bold text-white"
        >
          Cerrar Sesión
        </MyButton>
      </SecondCardLayout>
    </>
  );
};

export const CardExit = ({ toggle }) => {
  const logOut = (e) => {
    signOff(e);
  };
  return (
    <>
      <SecondCardLayout
        description="¿Desea salir de la aplicación?"
        typeCard="card-yellow"
      >
        <SpecialContainer margin=" 0 0 20px 0">
          <MyButton
            onClick={logOut}
            className="btn-green2 rounded-pill font-weight-bold text-white"
          >
            Sí
          </MyButton>
        </SpecialContainer>
        <MyButton
          onClick={toggle}
          className="btn-red rounded-pill font-weight-bold text-white"
        >
          No
        </MyButton>
      </SecondCardLayout>
    </>
  );
};

export const DropDownSelect = styled.ul`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  color: ${(props) => (props.color ? props.color : "#333333")};
  width: ${(props) => (props.width ? props.width : "100%")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "inherit")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "none")};
  height: ${(props) => (props.height ? props.height : "inherit")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "20px"};
 
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "white")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  border: ${(props) => (props.border ? props.border : "0")};
  li {
    color: ${(props) => (props.colorLi ? props.colorLi : "#333333")};
    list-style: none;
    text-align: left;
    padding: ${(props) =>
      props.paddingLi ? props.paddingLi : "16px 0 16px 20px;"};
    border-bottom: ${(props) =>
      props.borderLi ? props.borderLi : "2px  solid #eef1f9"};

    &:hover {
      background-color: ${(props) =>
        props.bgColorHover ? props.bgColorHover : "#eef1f9"};
        /* border-radius: ${(props) =>
    props.borderRadius ? `${props.borderRadius}px ` : "10px"}; */
    }

  }

  li:first-child {
    border-radius: ${(props) =>
    props.borderRadius ? `${props.borderRadius}px ${props.borderRadius}px 0 0 ` : "10px 10px 0 0"};
  }
  

  li:last-child {
    border: none;
    border-radius: ${(props) =>
    props.borderRadius ? ` 0 0 ${props.borderRadius}px ${props.borderRadius}px` : "0 0 10px 10px"};
  }
  li:only-child {
    border-radius: ${(props) =>
    props.borderRadius ? `${props.borderRadius}px ` : "10px"};
  }
`;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as userAPI from "../../api/api";
import { getURLParams } from "../../utils/url";

const ACTION_DOMAIN = "form/request";

// Initail value state
const initialState = {
  setting: {},
};

const fetchSettingForm = createAsyncThunk(`${ACTION_DOMAIN}/fetchData`, async () => {
  const { form_code } = getURLParams();
  const response = await userAPI.fetchForm(form_code);
  return response.data.setting;
});

const submitForm = createAsyncThunk(
  `${ACTION_DOMAIN}/submit`,
  async ({ formType, data }, { rejectWithValue }) => {
    const { form_code } = getURLParams();

    data["cocia"] = localStorage.getItem("x-app-cocia");
    data["email"] = localStorage.getItem("email");

    try {
      let response = null;
      response = await userAPI.submit(form_code, formType, data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const submitFormData = createAsyncThunk(
  `${ACTION_DOMAIN}/submit`,
  async ({ formType, data }, { rejectWithValue }) => {
    const { form_code } = getURLParams();

    data["cocia"] = localStorage.getItem("x-app-cocia");
    data["email"] = localStorage.getItem("email");

    try {
      let response = null;
      response = await userAPI.submitFormData(form_code, formType, data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const extraReducers = (builder) => {
  builder.addCase(fetchSettingForm.fulfilled, (state, action) => {
    state.setting = action.payload;
  });

  builder.addCase(submitForm.fulfilled, (state, action) => {});
};

const slice = createSlice({
  name: `${ACTION_DOMAIN}`,
  initialState: initialState,
  reducers: {},
  extraReducers: extraReducers,
});

export { fetchSettingForm, submitForm, submitFormData };
export default slice.reducer;

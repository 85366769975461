import React, { useState } from "react";
import { Assets, Dropdown, StyledComponents } from "../components-config";
import { StyleCardGroup } from "./styleComponets";
import PropTypes from 'prop-types'

function List({ options, keyValue, keyLabel, handlerChange }) {
  return (
    <ul>
      {options.map((item) => {
        return (
          <li key={item[keyValue]} className="pointer" onClick={() => handlerChange(item)}>
            {item[keyLabel]}
          </li>
        );
      })}
    </ul>
  );
}

const SelectGroup = ({ dropdownTop, group, itemKeyValue, itemKeyLabel, handlerChange }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [value, setValue] = useState(group.name);

  const handlerChangeStateGroup = (item) => {
    setValue(item[itemKeyLabel]);
    toggle();
    handlerChange(item);
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <Dropdown.LayoutDropDown
        maxWidthToggle="224px"
        dropdownToggle={
          <StyledComponents.SpecialContainer
            margin="0 4px 0 0"
            className={`d-flex justify-content-between align-items-center bg-grey130`}
            width="220px"
            height="32px"
            padding="0 10px"
            borderRadius="40px">
            <StyledComponents.MyP fontSize="12px" className="font-weight-bold" margin="0">
              {value}
            </StyledComponents.MyP>
            <span className="d-flex align-items-center">
              <Assets.IconArrows />
            </span>
          </StyledComponents.SpecialContainer>
        }
        dropdownOpen={dropdownOpen}
        toggle={toggle}>
        <StyledComponents.DivToRelative top={dropdownTop} zIndex="100">
          <StyleCardGroup>
            <List keyValue={itemKeyValue} keyLabel={itemKeyLabel} options={group.parameters} handlerChange={handlerChangeStateGroup}></List>
          </StyleCardGroup>
        </StyledComponents.DivToRelative>
      </Dropdown.LayoutDropDown>
    </>
  );
};

SelectGroup.defaultProps = {
  itemKeyValue: 'id',
  itemKeyLabel: 'name',
}

export default SelectGroup;

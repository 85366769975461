import { axios_api } from "../../components-api/ConfigApi";

export const CFDI_STAMP_BALANCE_CHARGING = "CFDI_STAMP_BALANCE_CHARGING";
export const CFDI_STAMP_BALANCE_ERROR = "CFDI_STAMP_BALANCE_ERROR";
export const CFDI_STAMP_BALANCE_CLEAN_STATE = "CFDI_STAMP_BALANCE_CLEAN_STATE";
export const CFDI_STAMP_BALANCE_CHANGE_STATE = "CFDI_STAMP_BALANCE_CHANGE_STATE";
export const CFDI_STAMP_BALANCE_GET = "CFDI_STAMP_BALANCE_GET";


export const GetStampBalances = async (data) => {
  const answer = await axios_api(`/timbox/consumptions/`, "company", "get");
  return { res: answer, type: CFDI_STAMP_BALANCE_GET };
};

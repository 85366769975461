import * as ComponentsAssets from "./Assets";
import * as ComponentsNavbars from "./Navbars";
import * as ComponentsStyle from "./StyledComponents";
import * as ComponentsDropDown from "./Dropdowns";
import * as ComponentsCards from "./Cards";
import * as ComponentsModals from "./Modals";
import TableComponent from "./Tables";
import * as ComponentInputs from "./Inputs";
import * as ComponentUtils from "./Utils";
import * as ComponentButtons from "./Buttons";
import * as ComponentTabs from "./Tabs";
import * as ComponentLayouts from "./Layouts";
import {ReadPdf as ReadPdfComponent} from './ReadPdf'

export const Assets = ComponentsAssets;
export const Navbars = ComponentsNavbars;
export const StyledComponents = ComponentsStyle;
export const Dropdown = ComponentsDropDown;
export const Cards = ComponentsCards;
export const Modals = ComponentsModals;
export const Table = TableComponent;
export const Inputs = ComponentInputs;
export const Utils = ComponentUtils;
export const Buttons = ComponentButtons;
export const Tabs = ComponentTabs;
export const Layouts = ComponentLayouts;
export const ReadPdf = ReadPdfComponent;

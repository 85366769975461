import React from "react";
import {Meals} from '../../components-cafeteria'


export const MealsModule = () => {
  return <>
    <Meals></Meals>
  </>;
};


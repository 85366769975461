import { axios_api } from "../../components-api/ConfigApi";

export const EMAIL_CARGANDO = "EMAIL_CARGANDO";
export const EMAIL_ERROR = "EMAIL_ERROR";
export const EMAIL_CLEAN_STATE = "EMAIL_CLEAN_STATE";
export const EMAIL_SEND = "EMAIL_SEND";

export const SendEmail = async (uri, data) => {
  const answer = await axios_api(
    `${uri}${!data.model.to_emails ? `&to_emails=${data.model.to_email}` : ''}`,
    "json",
    "post",
    data.model?.data ? data.model.data : data.model
  );
  return { res: answer, type: EMAIL_SEND };
};

import React, { useState } from "react";
import { Modal } from "reactstrap";
import { Loader } from "./loader/Loader";
import { CardExit } from "./Cards";
import { SpecialContainer, MyP, OverFlowYY } from "./StyledComponents";
import { IconCheck, IconError, IconBack } from "./Assets";
import Table from "./Tables";

// Modal Layout.
export const MyModal = ({
  children,
  content,
  toggle,
  modal,
  backdrop = "",
  className,
}) => {
  return (
    <>
      <div onClick={toggle} className={className}>
        {children}
      </div>
      <Modal isOpen={modal} toggle={toggle} backdrop={backdrop}>
        {content}
      </Modal>
    </>
  );
};

// Modal de cargando.
export const ModalLoading = ({
  className,
  active = true,
  message = "Un momento, por favor...",
}) => {
  const [modal, setModal] = useState(active);
  const toggle = () => setModal(!modal);
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        backdrop="static"
        keyboard={modal}
      >
        <div className="text-white text-center">
          <div className="d-flex justify-content-center mb-5">
            <Loader></Loader>
          </div>

          <div>{message}</div>
        </div>
      </Modal>
    </>
  );
};

export const ModalSuccessOrError = ({
  status,
  className,
  active = true,
  message,
}) => {
  const [modal, setModal] = useState(active);
  const toggle = () => setModal(!modal);
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        //backdrop="static"
        keyboard={modal}
      >
        {status ? (
          <>
            <SpecialContainer
              width="822px"
              margin="100px 0 25px 0"
              className="d-flex justify-content-center align-items-center "
            >
              <MyP
                margin="0 20px 0 0"
                fontSize="60px"
                className="mr2 text-white"
              >
                ¡Listo!
              </MyP>
              <IconCheck color="var(--green30)" size="60"></IconCheck>
            </SpecialContainer>
            <SpecialContainer width="822px" className="text-center">
              <SpecialContainer width="822px" margin="0 0 100px 0">
                <MyP fontSize={"20px "} className="text-white">
                  {message}
                </MyP>
              </SpecialContainer>
              <SpecialContainer
                margin="200px auto 0 auto"
                className="d-flex justify-content-center"
                onClick={toggle}
              >
                <IconBack colorArrow="#fff" colorText="text-white"></IconBack>
              </SpecialContainer>
            </SpecialContainer>
          </>
        ) : (
          <>
            <SpecialContainer
              width="822px"
              margin="100px 0 25px 0"
              className="d-flex justify-content-center align-items-center "
            >
              <MyP
                margin="0 20px 0 0"
                fontSize="60px"
                className="mr2 text-white"
              >
                ¡ERROR!
              </MyP>
              <IconError color="var(--green30)" size="60"></IconError>
            </SpecialContainer>
            <SpecialContainer width="822px" className="text-center">
              <SpecialContainer width="822px" margin="0 0 100px 0">
                <MyP fontSize={"20px "} className="text-white">
                  {message}
                </MyP>
              </SpecialContainer>
              <SpecialContainer
                margin="200px auto 0 auto"
                className="d-flex justify-content-center"
                onClick={toggle}
              >
                <IconBack colorArrow="#fff" colorText="text-white"></IconBack>
              </SpecialContainer>
            </SpecialContainer>
          </>
        )}
      </Modal>
    </>
  );
};


export const ModalExit = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <div onClick={toggle} className={"text18 text-purple40 pointer"}>
        Salir
      </div>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={modal}>
        <CardExit toggle={toggle}></CardExit>
      </Modal>
    </>
  );
};

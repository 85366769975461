import React from "react";
import { Container } from "reactstrap";
import {
  MyLayoutSection,
  SpecialContainer,
  MyBorderBottom,
} from "./StyledComponents";

export const Info = ({
  children,
  layoutSectionHeight = "100px",
  layoutSectionClassName = "bg-grey50 d-flex align-items-end",
  containerClassName = "d-flex align-items-end",
  borderRadiusSContainer = "20px 20px 0 0",
  paddingSContainer = "13px 23px 11px",
  classNameSContainer = "d-flex bg-grey120",
  heightSContainer = "80px",
}) => {
  return (
    <MyLayoutSection
      height={layoutSectionHeight}
      className={layoutSectionClassName}
    >
      <Container className={containerClassName}>
        <SpecialContainer
          borderRadius={borderRadiusSContainer}
          padding={paddingSContainer}
          className={classNameSContainer}
          height={heightSContainer}
        >
          {children}
        </SpecialContainer>
      </Container>
    </MyLayoutSection>
  );
};

export const Card = (
  { children, margin, padding, height, borderRadius, bgColor = "bg-grey10", className, fontSize='14px' }
) => {
  return (
    <>
      <Container>
        <SpecialContainer
          margin={margin}
          padding={padding}
          height={height}
          borderRadius={borderRadius}
          className={`${bgColor} ${className}`}
          fontSize={fontSize}
        >
          {children}
        </SpecialContainer>
      </Container>
    </>
  );
};

export const Border = ({ border, margin }) => {
  return (
    <>
      <Container>
        <MyBorderBottom margin={margin} border={border}></MyBorderBottom>
      </Container>
    </>
  );
};

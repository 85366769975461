import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Collapse, Tooltip } from "reactstrap";
import { StyledComponents, Assets, Dropdown } from "../components-config";
import {
  InputAmountSettlement,
  HeaderConcepts,
  DeleteConcept,
} from "./Concepts";
import * as settlementActions from "./reducer/settlementActions";

const InputsSettlement = () => {
  const settlementReducer = useSelector((state) => state.settlementReducer);
  const { employee_number, type_settlement, discharge_date, cause_leave } =
    settlementReducer;

  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const select_type_settlement = [
    { id: 1, name: "Voluntario" },
    { id: 2, name: "Involutario s/liquidación" },
    { id: 3, name: "Involutario c/liquidación" },
  ];

  const select_causas = [
    { id: 1, name: "Causa Uno" },
    { id: 2, name: "Causa Dos" },
    { id: 3, name: "Causa Tres" },
    { id: 4, name: "Causa Cuatro" },
    { id: 5, name: "Límite de Faltas" },
    { id: 6, name: "Causa Cinco" },
  ];

  return (
    <StyledComponents.MyLayoutSection
      height="70px"
      className="bg-grey50 d-flex align-items-center"
    >
      <Container className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div className="mr20 ">
            <StyledComponents.MyInput
              placeholder="#Empleado"
              className="input-settlement"
            ></StyledComponents.MyInput>
          </div>
          <div className="mr20">
            <Dropdown.Select
              reducer={"settlementReducer"}
              reducerValue="type_settlement"
              maxWidthToggle="197px"
              items={select_type_settlement}
              // setValue={setValue}
              selectClass="filter-settlement"
              colorArrow="black"
              changeState={settlementActions.changeState}
              widthArrow="10"
              heightArrow="6"
              colorLi="var(--grey80)"
              borderLi="none"
              boxShadow={"0 3px 6px 0 rgba(0, 0, 0, 0.16)"}
              dropdownTop="38px"
              paddingLi={"7.5px 15px"}
            />
          </div>
          <div className="mr20">
            <StyledComponents.MyInput
              placeholder={focused ? "dd/mm/aaaa" : "Fecha de baja"}
              className="input-settlement"
              onFocus={onFocus}
              onBlur={onBlur}
            ></StyledComponents.MyInput>
          </div>
          <div className="mr20">
            <Dropdown.Select
              reducer={"settlementReducer"}
              reducerValue="cause_leave"
              maxWidthToggle="197px"
              items={select_causas}
              // setValue={setValue}
              selectClass="filter-settlement"
              colorArrow="black"
              changeState={settlementActions.changeState}
              widthArrow="10"
              heightArrow="6"
              colorLi="var(--grey80)"
              borderLi="none"
              boxShadow={"0 3px 6px 0 rgba(0, 0, 0, 0.16)"}
              dropdownTop="38px"
              paddingLi={"7.5px 15px"}
            />
          </div>
        </div>
        <div>
          <StyledComponents.MyButton width="185px" className="btn-settlement">
            Calcular Finiquito{" "}
            <span className="ml10">
              <Assets.IconUpdate color="var(--purple50)" />
            </span>
          </StyledComponents.MyButton>
        </div>
      </Container>
    </StyledComponents.MyLayoutSection>
  );
};

export const TypeSettlement = () => {
  return (
    <StyledComponents.MyLayoutSection
      height="30px"
      className="bg-grey50 d-flex align-items-center"
    >
      <Container className="h-100">
        <StyledComponents.SpecialContainer
          borderRadius="4px 4px 0 0"
          width="336px"
          className="bg-grey10 h-100 d-flex align-items-center justify-content-center"
        >
          <StyledComponents.MyP fontSize="14px" margin="0" className="d-flex">
            Se muestran finiquitos del periodo:{" "}
            <span className="ml10 font-weight-bold">
              <Dropdown.Select
                maxWidthToggle={"64px"}
                reducer={"settlementReducer"}
                reducerValue="period_settlement"
                items={""}
                // setValue={setValue}
                selectClass={"btn-period"}
                changeState={settlementActions.changeState}
                widthArrow="10"
                heightArrow="6"
                colorLi="var(--grey80)"
                borderLi="none"
                boxShadow={"0 3px 6px 0 rgba(0, 0, 0, 0.16)"}
                dropdownTop="25px"
                paddingLi={"7.5px 15px"}
              />
            </span>
          </StyledComponents.MyP>
        </StyledComponents.SpecialContainer>
      </Container>
    </StyledComponents.MyLayoutSection>
  );
};

// Lista de conceptos de usuario seleccionado
export const ConceptsSettlement = () => {
  const settlementReducer = useSelector((state) => state.settlementReducer);
  const { list_concepts } = settlementReducer;
  const [toggle, setToggle] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleToolTip = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      {/*Collapse title*/}
      <StyledComponents.SpecialContainer
        fontSize="14px"
        padding="17px 20px"
        margin={`${toggle ? "4px 0 0 0" : "4px 0"}`}
        className="d-flex bg-grey10"
      >
        <StyledComponents.SpecialContainer
          minWidth={toggle ? "73px" : "77px"}
          width={toggle ? "73px" : "77px"}
          className={`${toggle && "text-purple40 font-weight-bold"}`}
        >
          428769
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer
          width="318px"
          minWidth="318px"
          className={`${toggle && "text-purple40 font-weight-bold"}`}
        >
          PALENCIA GONZALEZ JUAN FRANCISCO
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer
          width="252px"
          minWidth="252px"
          className={`${toggle && "text-purple40 font-weight-bold"}`}
        >
          Retiro Involuntario (sin liquidación)
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer
          width="178px"
          minWidth="178px"
          className={`${toggle && "text-purple40 font-weight-bold"}`}
        >
          A partir de: 08/04/2021
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer
          width="104px"
          minWidth="104px"
          className={`${toggle && "text-purple40 "} font-weight-bold`}
        >
          $ 28,981.00
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer
          width="156px"
          minWidth="156px"
          className={`${toggle && "text-purple40 font-weight-bold"}`}
        >
          Por Autorizar
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer
          width="15px"
          minWidth="15px"
          onClick={() => setToggle(!toggle)}
        >
          <Assets.IconSelectSettlement toggle={toggle} />
        </StyledComponents.SpecialContainer>
      </StyledComponents.SpecialContainer>
      {
        /*Settlement concepts detail*/
        <StyledComponents.SpecialContainer
          padding={`${toggle && "16px 20px"} `}
          className="bg-grey10"
        >
          <Collapse isOpen={toggle} className="bg-white border-radius-collapse">
            <StyledComponents.SpecialContainer padding="30px 20px">
              <div className="d-flex justify-content-between">
                <HeaderConcepts />
              </div>
              <div>
                <StyledComponents.SpecialContainer
                  margin="30px 0 0 0"
                  padding="0 0 17px 0"
                  borderBottom="1px solid black"
                  className="d-flex font-weight-bold"
                  fontSize="14px"
                >
                  <StyledComponents.SpecialContainer width="113px">
                    Conceptos
                  </StyledComponents.SpecialContainer>
                  <StyledComponents.SpecialContainer width="358px">
                    Description
                  </StyledComponents.SpecialContainer>
                  <StyledComponents.SpecialContainer width="140px">
                    Tipo
                  </StyledComponents.SpecialContainer>
                  <StyledComponents.SpecialContainer width="130px">
                    Cantidad
                  </StyledComponents.SpecialContainer>
                  <StyledComponents.SpecialContainer width="133px">
                    Factor
                  </StyledComponents.SpecialContainer>
                  <StyledComponents.SpecialContainer width="53px">
                    Importe
                  </StyledComponents.SpecialContainer>
                </StyledComponents.SpecialContainer>
                {list_concepts.length > 0 ? (
                  list_concepts.map((settlemen_concepts, i) => {
                    return (
                      <StyledComponents.SpecialContainer
                        padding="15px 0"
                        borderBottom="1px solid black"
                        className="d-flex"
                        fontSize="14px"
                      >
                        <StyledComponents.SpecialContainer
                          width="113px"
                          margin="0"
                        >
                          {settlemen_concepts.id}
                        </StyledComponents.SpecialContainer>
                        <StyledComponents.SpecialContainer
                          width="358px"
                          className="d-flex"
                        >
                          {settlemen_concepts.description}
                          {settlemen_concepts.updated && (
                            <StyledComponents.SpecialContainer
                              width="40px"
                              padding="2px 13px 4px 13px"
                              className="bg-yellow10 ml10"
                              id="TooltipExample"
                            >
                              <Assets.IconWarning />
                              <Tooltip
                                placement="right"
                                isOpen={tooltipOpen}
                                target="TooltipExample"
                                toggle={toggleToolTip}
                                color="warning"
                              >
                                Sin calcular
                              </Tooltip>
                            </StyledComponents.SpecialContainer>
                          )}
                        </StyledComponents.SpecialContainer>
                        <StyledComponents.SpecialContainer width="140px">
                          {settlemen_concepts.type}
                        </StyledComponents.SpecialContainer>
                        <StyledComponents.SpecialContainer width="130px">
                          <InputAmountSettlement
                            value={settlemen_concepts.amount}
                            idP={i}
                          />
                        </StyledComponents.SpecialContainer>
                        <StyledComponents.SpecialContainer width="133px">
                          {/*Crear mi propip select */}
                          <Assets.IconSelectArrow
                            width={"10"}
                            height="6"
                          />{" "}
                          {settlemen_concepts.factor}
                        </StyledComponents.SpecialContainer>
                        <StyledComponents.SpecialContainer width="98px">
                          {settlemen_concepts.factor *
                            Number(settlemen_concepts.amount)}
                        </StyledComponents.SpecialContainer>
                        <StyledComponents.SpecialContainer
                          fontSize="13px"
                          className="d-flex align-items-center text-red10 font-weight-bold "
                        >
                          <span>
                            <Assets.IconErase />
                          </span>{" "}
                          <DeleteConcept idP={i}/>
                        </StyledComponents.SpecialContainer>
                      </StyledComponents.SpecialContainer>
                    );
                  })
                ) : (
                  <StyledComponents.SpecialContainer
                    padding="15px 0"
                    borderBottom="1px solid black"
                    className="d-flex justify-content-center text-danger font-weight-bold"
                    fontSize="14px"
                  >
                    No hay conceptos.
                  </StyledComponents.SpecialContainer>
                )}

                <div className="d-flex justify-content-between ">
                  <StyledComponents.SpecialContainer
                    margin="13px 0 0 0"
                    fontSize="14px"
                    className="d-flex align-items-center"
                  >
                    <Assets.IconPlus />{" "}
                    <span className="ml8 text-uppercase">Añadir Concepto</span>
                  </StyledComponents.SpecialContainer>

                  <StyledComponents.SpecialContainer
                    margin="27px 0 0 0"
                    className="d-flex text-purple40"
                  >
                    <StyledComponents.MyP margin="0 70px 0 0" fontSize="14px">
                      Percepciones:{" "}
                      <span className="font-weight-bold ml10">$26,843.40</span>
                    </StyledComponents.MyP>

                    <StyledComponents.MyP margin="0 70px 0 0" fontSize="14px">
                      deducciones:
                      <span className="font-weight-bold ml10">-14,798.36</span>
                    </StyledComponents.MyP>

                    <StyledComponents.MyP
                      margin="0 "
                      fontSize="14px"
                      className="d-flex align-items-center"
                    >
                      Total:
                      <StyledComponents.SpecialContainer
                        padding="7px 10px"
                        borderRadius="6px"
                        className="font-weight-bold ml10 bg-grey110"
                      >
                        122,045.04
                      </StyledComponents.SpecialContainer>
                    </StyledComponents.MyP>
                  </StyledComponents.SpecialContainer>
                </div>
              </div>
            </StyledComponents.SpecialContainer>
          </Collapse>
        </StyledComponents.SpecialContainer>
      }
    </>
  );
};

export const ListSettlement = ({ list_settlement }) => {
  return (
    <>
      <Container>
        {list_settlement ? (
          list_settlement.map((settlement) => {
            return <ConceptsSettlement />;
          })
        ) : (
          <div className="d-flex justify-content-center my-5">
            No hay finiquitos
          </div>
        )}
      </Container>
    </>
  );
};

// Funcion principal para pintar finiquitos
export const Settlement = () => {
  return (
    <>
      <InputsSettlement>Inputs de finiquitos</InputsSettlement>
      <TypeSettlement />
      <ListSettlement list_settlement={[0, 1, 3]} />
      <div>
        <div></div>
        <div>
          <div>Acciones de conceptos(warnings, eliminar, crear)</div>
          <div>Modal de conceptos</div>
          <div>Vista de finiquito</div>
          <div>Envios de finiquitos</div>
        </div>
      </div>
    </>
  );
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { Assets, Buttons, Layouts, StyledComponents } from "../components-config";
import { RedirectSelects } from "../components-integrity";

import HeaderTitles from "./HeaderTitles";
import { Loader } from "./Loader";
import OptionIncidenceType from "./OptionIncidenceType";
//import OptionSubType from './OptionSubType';
import OptionConcept from "./OptionConcept";
import OptionDateFrom from "./OptionDateFrom";
import OptionDateTo from "./OptionDateTo";
import OptionParameterList from "./OptionParameterList";
import { cleanAllDataOptions, cleanAllFilter } from "./actions/actions";

const ReportButtons = () => {
  const reportReducer = useSelector((state) => state.reportQueryReducer);
  const { filters } = reportReducer;
  const integrityReducer = useSelector((state) => state.integrityReducer);
  const { url, company_selected } = integrityReducer;
  const disabled = true;

  return (
    <StyledComponents.MyLayoutSection>
      <Container>
        <StyledComponents.SpecialContainer
          margin="30px 0"
          className={`d-flex justify-content-end ${disabled === false && "disabled"}`}
          fontSize="12px">
          <Buttons.ButtonSend
            padding="8px 18px"
            classButton="font-weight-bold bg-green40 text-white"
            iconColor="white"
            ml="ml5"
            margin="0 25px"
            isActive={disabled}
            description={
              <StyledComponents.MyP fontSize="18px">
                El reporte(s) seleccionado(s) se enviará a la siguiente dirección de correo:{" "}
                <span className="font-weight-bold"> {localStorage.getItem("email")}</span>
              </StyledComponents.MyP>
            }
            description2={
              <StyledComponents.MyP className="font-weight-bold">
                Ingrese la dirección de correo a la cual desea que llegue el Reporte. Si son varias,
                sepárelas con una coma.
              </StyledComponents.MyP>
            }
            uri={`${process.env.REACT_APP_ROUTE_REPORT_QUERY}/reports/`}
            modelToSend={{ ...filters, companyCode: company_selected.id }}
          />
        </StyledComponents.SpecialContainer>
      </Container>
    </StyledComponents.MyLayoutSection>
  );
};

// Report Information, name and date.
const ReportInformation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cleanAllFilter());
    dispatch(cleanAllDataOptions());
  }, []);

  return (
    <Layouts.Info>
      <StyledComponents.SpecialContainer width="34px" margin="0 36px 0 0">
        <Assets.IconSheet />
      </StyledComponents.SpecialContainer>
      <div>
        <StyledComponents.MyP fontSize="14px" className="font-weight-bold text-purple40">
          Hola, {localStorage.getItem("username")}.
        </StyledComponents.MyP>
        <StyledComponents.MyP fontSize="14px" className="text-purple40">
          Recuerda que, tanto el nombre, como los valores pre-seleccionados en la sección “Columnas”
          corresponden a la definición de cada tipo de reporte, hecha conforme a los requerimientos
          específicos del área de nóminas/RRHH.
        </StyledComponents.MyP>
      </div>
    </Layouts.Info>
  );
};

const ContentOptions = () => {
  return (
    <StyledComponents.SpecialContainer margin="0 0 4px 0">
      <Container className="d-flex">
        <OptionIncidenceType />
        <OptionConcept />
        <OptionParameterList />
        <OptionDateFrom />
        <OptionDateTo />
      </Container>
    </StyledComponents.SpecialContainer>
  );
};

export const Report = () => {
  RedirectSelects();
  return (
    <>
      <ReportInformation />
      <HeaderTitles />
      <ContentOptions />
      <Layouts.Border margin="30px 0 0 0" border="1px dashed var(--pink80)" />
      <ReportButtons />
      <Loader />
    </>
  );
};

import { DATA_OPTIONS_CLEAN_ALL, FILTER_STRUCTUREOPTION_SEARCH_CHANGE } from "../actions/actionTypeKeys";

export const initialState = null;

function reducer(state = initialState, action) {
  switch (action.type) {
    case DATA_OPTIONS_CLEAN_ALL:
      return null;
    case FILTER_STRUCTUREOPTION_SEARCH_CHANGE:
      return action.payload;
    default:
      return state;
  }
}

export default reducer;

import { CONCEPT_FETCH_SUCCESS, DATA_OPTIONS_CLEAN_ALL } from "../actions/actionTypeKeys";

export const initialState = {
  list: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case DATA_OPTIONS_CLEAN_ALL:
      return {
        ...state,
        list: [],
      };
    case CONCEPT_FETCH_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;

import { axios_api } from "../../components-api/ConfigApi";

export const DOWNLOAD_CARGANDO = "DOWNLOAD_CARGANDO";
export const DOWNLOAD_ERROR = "DOWNLOAD_ERROR";
export const DOWNLOAD_CLEAN_STATE = "DOWNLOAD_CLEAN_STATE";
export const DOWNLOAD_CHANGE_STATE = "DOWNLOAD_CHANGE_STATE";
export const DOWNLOAD_SEND = "DOWNLOAD_SEND";

export const download = async (uri, data = {}) => {
  const answer = await axios_api(`${uri}`, "company", "post", data, 10000000);
  return { res: answer, type: DOWNLOAD_SEND };
};

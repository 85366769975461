// Librerias.
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Collapse } from "reactstrap";

// Components Base.
import {
    Layouts,
    StyledComponents,
    Buttons,
    Assets,
    Utils,
    Modals,
} from "../components-config";

// Components.
import {
    CFDIsInformation,
    CfDIsSelect,
    CFDIsInput,
    CheckAllCFDIs,
    CheckCFDIs,
    Pagination,
} from "./CFDIsComponents";

// Actions.
import * as cfdisActions from "./reducer/cfdisActions";

// Integrity.
import PayrollTypes from "../utils-integrity/PayrollTypes";
import EmployeeTypes from "../utils-integrity/EmployeeTypes";
import { RedirectSelects } from "../components-integrity";

// CFDIs Filter.
export const CFDIsFilter = () => {
        const dispatch = useDispatch();
        const cfdisReducer = useSelector((state) => state.cfdisReducer);
        const {
            payroll_type,
            year,
            period,
            employee_type,
            employee_status,
            employee_numbers,
            uuids,
        } = cfdisReducer;

        // Collapse.
        const [isOpen, setIsOpen] = useState(false);
        const toggle = () => {
            setIsOpen(!isOpen);
            isOpen
                ?
                dispatch(cfdisActions.changeState("overflowY", "200px")) :
                dispatch(cfdisActions.changeState("overflowY", "400px"));
        };

        const { payrollTypes, isLoading } = PayrollTypes();
        const { employeeTypes, employeeTypesLoading } = EmployeeTypes();

        // const loadingPayrollTypes = isLoading
        //   ? "Cargando..."
        //   : "Seleccionar una opción...";

        const setEmployeeNumbers = (e) => {
            dispatch(cfdisActions.changeState("employee_numbers", e.target.value));
        };
        const setUuids = (e) => {
            dispatch(cfdisActions.changeState("uuids", e.target.value));
        };

        const disableByEmployeeNumber = employee_numbers ? true : false;
        const disableByUuid = uuids ? true : false;

        const filterCfdis = () => {
                const urlFilter = `?payroll_type=${
      payroll_type.code === undefined ? "" : payroll_type.code
    }&year=${year.name}&period=${period.id === "--Todos--" ? "" : period.id}${
      employee_type.code !== "0" && employee_type.code
        ? `&employee_type=${employee_type.code}`
        : ""
    }${
      employee_status.name === "--Todos--"
        ? ""
        : `&employee_status=${employee_status.id}`
    }${employee_numbers ? `&employees=${employee_numbers}` : ""}${
      uuids ? `&uuids=${uuids}` : ""
    }`;
    dispatch(cfdisActions.cfdisMethods(urlFilter, "GetCfdisList"));
    dispatch(cfdisActions.changeState("list_cfdis_selected", []));
  };

  return (
    <div>
      <Collapse isOpen={!isOpen}>
        <Layouts.Card
          margin="4px 0 0 0"
          padding="20px"
          height="150px"
          borderRadius="0 0 20px 20px"
          className="text-purple40 "
        >
          <div className="d-flex">
            <StyledComponents.SpecialContainer margin="0 20px 0 0">
              <StyledComponents.SpecialContainer
                margin="0 0 14px 0"
                className="font-weight-bold"
              >
                Tipo de Nómina
              </StyledComponents.SpecialContainer>
              <div>
                {isLoading ? (
                  <StyledComponents.SpecialContainer
                    margin="0"
                    className={`d-flex justify-content-between align-items-center bg-grey130`}
                    width={"180px"}
                    height="32px"
                    padding="0 10px"
                    borderRadius="40px"
                  >
                    <StyledComponents.MyP
                      fontSize="12px"
                      className=" text-black"
                      margin="0"
                    >
                      Cargando... <Modals.ModalLoading active={true} />
                    </StyledComponents.MyP>
                    <span className="d-flex align-items-center">
                      <Assets.IconArrows />
                    </span>
                  </StyledComponents.SpecialContainer>
                ) : (
                  <CfDIsSelect
                    reducerValue={payroll_type.name}
                    reducerVariable="payroll_type"
                    group={payrollTypes}
                    width="180px"
                    dropdownTop="35px"
                  />
                )}
              </div>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer margin="0 20px 0 0">
              <StyledComponents.SpecialContainer
                margin="0 0 14px 0"
                className="font-weight-bold"
              >
                Año
              </StyledComponents.SpecialContainer>
              <div>
                <CfDIsSelect
                  reducerValue={year.name}
                  reducerVariable={"year"}
                  group={Utils.Years}
                  width="80px"
                  dropdownTop="35px"
                />
              </div>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer margin="0 20px 0 0">
              <StyledComponents.SpecialContainer
                margin="0 0 14px 0"
                className="font-weight-bold"
              >
                Periodo
              </StyledComponents.SpecialContainer>
              <div>
                <CfDIsSelect
                  reducerValue={period.name}
                  reducerVariable={"period"}
                  group={Utils.Periods}
                  width="80px"
                  dropdownTop="35px"
                />
              </div>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer margin="0 20px 0 0">
              <StyledComponents.SpecialContainer
                margin="0 0 14px 0"
                className="font-weight-bold"
              >
                Tipo Empleado
              </StyledComponents.SpecialContainer>
              <div>
                {employeeTypesLoading ? (
                  <StyledComponents.SpecialContainer
                    margin="0"
                    className={`d-flex justify-content-between align-items-center bg-grey130`}
                    width={"180px"}
                    height="32px"
                    padding="0 10px"
                    borderRadius="40px"
                  >
                    <StyledComponents.MyP
                      fontSize="12px"
                      className=" text-black"
                      margin="0"
                    >
                      Cargando...
                    </StyledComponents.MyP>
                    <span className="d-flex align-items-center">
                      <Assets.IconArrows />
                    </span>
                  </StyledComponents.SpecialContainer>
                ) : (
                  <CfDIsSelect
                    reducerValue={employee_type.name}
                    reducerVariable={"employee_type"}
                    group={employeeTypes}
                    width="180px"
                    dropdownTop="35px"
                    overflow={false}
                  />
                )}
              </div>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer margin="0 20px 0 0">
              <StyledComponents.SpecialContainer
                margin="0 0 14px 0"
                className="font-weight-bold"
              >
                Estatus Empleado
              </StyledComponents.SpecialContainer>
              <div>
                <CfDIsSelect
                  reducerValue={employee_status.name}
                  reducerVariable={"employee_status"}
                  group={[
                    { id: 0, name: "--Todos--" },
                    { id: "A", name: "Alta" },
                    { id: "B", name: "Baja" },
                  ]}
                  width="120px"
                  overflow={false}
                  dropdownTop="35px"
                />
              </div>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer margin="0 20px 0 0">
              <StyledComponents.SpecialContainer
                margin="0 0 14px 0"
                className="font-weight-bold"
              >
                Número(s) Empleado
              </StyledComponents.SpecialContainer>
              <StyledComponents.SpecialContainer width="170px">
                <CFDIsInput
                  type="text"
                  disabled={disableByUuid}
                  placeholder="ej,000000,000000"
                  className={
                    disableByUuid
                      ? "input-disable"
                      : "bg-grey110 font-weight-bold"
                  }
                  value={employee_numbers}
                  onChange={(e) => setEmployeeNumbers(e)}
                />
              </StyledComponents.SpecialContainer>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer margin="0 20px 0 0">
              <StyledComponents.SpecialContainer
                margin="0 0 14px 0"
                className="font-weight-bold"
              >
                UUID
              </StyledComponents.SpecialContainer>
              <StyledComponents.SpecialContainer width="170px">
                <CFDIsInput
                  type="text"
                  disabled={disableByEmployeeNumber}
                  placeholder="ej,000000,000000"
                  className={
                    disableByEmployeeNumber
                      ? "input-disable"
                      : "bg-grey110 font-weight-bold"
                  }
                  value={uuids}
                  onChange={(e) => setUuids(e)}
                />
              </StyledComponents.SpecialContainer>
            </StyledComponents.SpecialContainer>
          </div>
          <StyledComponents.SpecialContainer
            margin="20px 0 0 0"
            className="d-flex justify-content-between"
          >
            <div>
              <StyledComponents.MyButton
                className="btn-hidden"
                onClick={toggle}
              >
                <span className="mr5">
                  <Assets.IconSelectArrow
                    color="var(--purple40)"
                    toggle={true}
                    width="12"
                  />
                </span>
                Ocultar
              </StyledComponents.MyButton>
            </div>
            <div>
              <StyledComponents.MyButton
                className="btn-search2 font-weight-bold"
                onClick={filterCfdis}
              >
                <span className="mr5">
                  <Assets.IconSearch size="12" />
                </span>{" "}
                Buscar
              </StyledComponents.MyButton>
            </div>
          </StyledComponents.SpecialContainer>
        </Layouts.Card>
      </Collapse>
      {isOpen && (
        <Container>
          <StyledComponents.SpecialContainer padding="20px" width="140px">
            <StyledComponents.MyButton className="btn-hidden" onClick={toggle}>
              <span className="mr5">
                <Assets.IconSelectArrow
                  color="var(--purple40)"
                  toggle={false}
                  width="12"
                />
              </span>
              Filtro
            </StyledComponents.MyButton>
          </StyledComponents.SpecialContainer>
        </Container>
      )}
    </div>
  );
};

// CFDIs Search.
export const CFDIsSearch = () => {
  const cfdisReducer = useSelector((state) => state.cfdisReducer);
  const dispatch = useDispatch();
  const {
    list_cfdis,
    list_cfdis_selected,
    cfdis_action_success,
    total_net,
    payroll_type,
    year,
    period,
  } = cfdisReducer;

  const transferUuids = () => {
    const model = {
      year: year.id,
      payroll: payroll_type.code,
      period: period.name,
      env: "prod",
      cfdis: list_cfdis_selected,
    };
    dispatch(cfdisActions.cfdisMethods(model, "CfdisTransfer"));
  };
  return (
    <>
      {cfdis_action_success && (
        <Modals.ModalSuccessOrError
          status={true}
          message="CFDIs tranferidos exitosamente "
        />
      )}
      <Container>
        <StyledComponents.SpecialContainer
          borderRadius="4px 4px 0 0"
          width="180px"
          className="font-weight-bold"
          fontSize="13px"
          background="var(--grey10)"
          padding="8px 22px 8px 10px"
        >
          Resultado de búsqueda:
        </StyledComponents.SpecialContainer>
      </Container>
      <Layouts.Card borderRadius="0 20px 0 0">
        <StyledComponents.SpecialContainer padding="20px 20px 8px 20px">
          <div className="d-flex justify-content-between">
            <StyledComponents.SpecialContainer
              fontSize="12px"
              fontStyle="italic"
              className="d-flex"
            >
              <div>
                Registros:{" "}
                <span className="font-weight-bold ml10">
                  {list_cfdis.length}
                </span>{" "}
              </div>
              <StyledComponents.SpecialContainer margin="0 0 0 30px">
                Seleccionados:{" "}
                <span className="font-weight-bold ml10">
                  {list_cfdis_selected.length}
                </span>
              </StyledComponents.SpecialContainer>
              <StyledComponents.SpecialContainer margin="0 0 0 30px">
                Monto Total:{" "}
                <span className="font-weight-bold ml10">
                  {"$"}
                  {total_net
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </StyledComponents.SpecialContainer>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              margin="0 40px 0 26px"
              className="d-flex align-items-center"
            >
              <div>
                {/* <Buttons.Download
                  icon={
                    <span className="mr5">
                      <Assets.IconCheckValidate />
                    </span>
                  }
                  fileName="cfdis-validados"
                  classButton={`${
                    list_cfdis_selected.length > 0 ? "" : "btn-disabled"
                  } bg-yellow20`}
                  padding="2.5px 15px"
                  borderRadius="40px"
                  uri={`/integrity/cfdis/uuid_validations/`}
                  data={{ cfdis: list_cfdis_selected }}
                  disabled={list_cfdis_selected.length > 0 ? true : false}
                  labelButton="VALIDAR"
                /> */}
              </div>
              <StyledComponents.SpecialContainer margin="0 0 0 20px">
                <StyledComponents.MyButton
                  className={`${
                    list_cfdis_selected.length > 0 ? "" : "btn-disabled"
                  } btn-sap`}
                  onClick={transferUuids}
                  disabled={list_cfdis_selected.length > 0 ? false : true}
                >
                  <span className="mr5">
                    <Assets.IconTransfer />
                  </span>{" "}
                  Transferir UUID's
                </StyledComponents.MyButton>
              </StyledComponents.SpecialContainer>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              fontSize="12px"
              className="d-flex font-weight-bold"
            >
              <Buttons.Download
                fileName="UUIDs"
                classButton={`${
                  list_cfdis_selected.length > 0 ? "" : "btn-disabled"
                } bg-green50 mr10`}
                padding="4px 11px"
                borderRadius="40px"
                uri={`/payroll/cfdis/uuids/`}
                data={{
                  year: year.id,
                  payroll: payroll_type.code,
                  period: period.name,
                  env: "prod",
                  cfdis: list_cfdis_selected,
                }}
                disabled={list_cfdis_selected.length > 0 ? true : false}
                labelButton="UUID's"
              />
              <Buttons.Download
                fileName="UUIDs"
                classButton={`${
                  list_cfdis_selected.length > 0 ? "" : "btn-disabled"
                } bg-green50 mr10`}
                padding="4px 11px"
                borderRadius="40px"
                uri={`/payroll/cfdis/uuids/status/`}
                data={{
                  year: year.id,
                  payroll: payroll_type.code,
                  period: period.name,
                  env: "prod",
                  cfdis: list_cfdis_selected,
                }}
                disabled={list_cfdis_selected.length > 0 ? true : false}
                labelButton="UUID's Timbrados y Cancelados"
              />
              <Buttons.Download
                fileName="cfdis-xmls"
                classButton={`${
                  list_cfdis_selected.length > 0 ? "" : "btn-disabled"
                } bg-green50 mr10`}
                padding="4px 11px"
                borderRadius="40px"
                uri={`/cfdi/v2/receipts/xml_packages/`}
                data={{ cfdis: list_cfdis_selected }}
                disabled={list_cfdis_selected.length > 0 ? true : false}
                labelButton="XML"
              />
              <Buttons.Download
                fileName="cfdis-pfds"
                classButton={`${
                  list_cfdis_selected.length > 0 ? "" : "btn-disabled"
                } bg-green50 mr10`}
                padding="4px 11px"
                borderRadius="40px"
                uri={`/cfdi/v2/receipts/pdf_packages/`}
                data={{ cfdis: list_cfdis_selected }}
                disabled={list_cfdis_selected.length > 0 ? true : false}
                labelButton="PDF"
              />
              <Buttons.Download
                fileName="cfdis-xmls-pdfs"
                classButton={`${
                  list_cfdis_selected.length > 0 ? "" : "btn-disabled"
                } bg-green50`}
                padding="4px 11px"
                borderRadius="40px"
                uri={`/cfdi/v2/receipts/cfdi_packages/`}
                data={{ cfdis: list_cfdis_selected }}
                disabled={list_cfdis_selected.length > 0 ? true : false}
                labelButton="XML y PDF"
              />
            </StyledComponents.SpecialContainer>
          </div>
          <StyledComponents.SpecialContainer
            className="d-flex font-weight-bold"
            margin=" 13px 0 0 0"
          >
            <StyledComponents.SpecialContainer
              width="34px"
              margin="0 2px 0 0"
            ></StyledComponents.SpecialContainer>
            <div>
              <StyledComponents.SpecialContainer
                padding="8px 21px 0 1px"
                margin="0 2px 0 0"
              >
                <CheckAllCFDIs className="checked-border" />
              </StyledComponents.SpecialContainer>
            </div>
            <StyledComponents.SpecialContainer
              width="70px"
              padding="10px 5px 2px 2px"
              margin="0 2px 0 0"
            >
              ID
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              width="70px"
              padding="10px 5px 2px 0px"
              margin="0 2px 0 0"
            >
              Periodo
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              width="260px"
              padding="10px 5px 2px 0px"
              margin="0 2px 0 0"
            >
              Nombre
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              width="130px"
              padding="10px 5px 2px 0px"
              margin="0 2px 0 0"
            >
              Fecha Timbre
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              width="280px"
              padding="10px 5px 2px 0px"
              margin="0 2px 0 0"
            >
              UUID
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              width="80px"
              padding="10px 5px 2px 0px"
              margin="0 2px 0 0"
            >
              Status
            </StyledComponents.SpecialContainer>
          </StyledComponents.SpecialContainer>
        </StyledComponents.SpecialContainer>
      </Layouts.Card>
    </>
  );
};

export const CFDIs = () => {
  const cfdisReducer = useSelector((state) => state.cfdisReducer);
  const {
    api_actions: { cargando, error },
    list_cfdis,
  } = cfdisReducer;
  const dispatch = useDispatch();
  RedirectSelects();
  useEffect(() => {
    return () => {
      dispatch(cfdisActions.changeState("cfdis_action_success", ""));
    };
  }, [dispatch]);
  return (
    <>
      {cargando && <Modals.ModalLoading />}
      {cargando}
      <CFDIsInformation />
      <CFDIsFilter />
      <Layouts.Border margin="30px 0" border="1px dashed var(--purple40)" />
      <CFDIsSearch />
      {list_cfdis.length > 0 ? (
        <>
          <CheckCFDIs />
          <Pagination />
        </>
      ) : error ? (
        <Container>
          <div className="d-flex justify-content-center mt-5 text-danger font-weight-bold">
            No se encontro ningun cfdi con el filtro establecido
          </div>
        </Container>
      ) : (
        ""
      )}
    </>
  );
};
import styled from "styled-components";

export const StyleCardGroup = styled.div`
  background: var(--grey130);
  width: 220px;
  border-radius: 10px;
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    font-size: 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 10px 8px 9px;
  }
  li:hover {
    background-color: var(--grey90);
  }
`;

export const CardConceptStyle = styled.div`
  background: var(--purple100);
  width: ${(props) => (props.width ? props.width : "220px")};
  height: ${(props) => (props.height ? props.height : "270px")};
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    font-size: 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 10px 8px 9px;
  }
  li:nth-child(even) {
    background: var(--purple90);
  }
`;

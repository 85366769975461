import React from "react";
import { Container } from "reactstrap";
import { StyledComponents, Assets } from "../components-config";
export const SettlementUser = () => {
  return (
    <>
      <StyledComponents.MyLayoutSection
        className="bg-pink60 d-flex align-items-center"
        height="50px"
      >
        <Container className="d-flex justify-content-between font-weight-bold">
          <div className="d-flex text-white align-items-center">
            <StyledComponents.SpecialContainer margin="0 30px 0 0">
              <Assets.IconBack2 to='/dashboard-settlements' width="20" height="20" />
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              margin="0 30px 0 0"
              fontSize="14px"
            >
              463211
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer fontSize="14px">
              Martínez Sílva Pedro Luís
            </StyledComponents.SpecialContainer>
          </div>
          <div className="d-flex text-white align-items-center">
            <StyledComponents.SpecialContainer
              margin="0 30px 0 0"
              fontSize="14px"
            >
              Retiro Involuntario (sin liquidación)
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              margin="0 30px 0 0"
              fontSize="14px"
            >
              A partir de: 09/04/2021
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              margin="0 30px 0 0"
              fontSize="14px"
            >
              $34,045.04
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer>
              <StyledComponents.MyButton className='btn-green3 d-flex align-items-center'>
                Enviar <span className='ml10'><Assets.IconSendEmail color="black" /></span>
              </StyledComponents.MyButton>
            </StyledComponents.SpecialContainer>
          </div>
        </Container>
      </StyledComponents.MyLayoutSection>
    </>
  );
};

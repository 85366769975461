import React, { useEffect } from "react";
import { StyledComponents } from "../components-config";
import SelectGroup from "./SelectGroup";
import { changeFilterStructureType, getStructureTypes } from "./actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectStructureTypes } from "./selectors/options";

const OptionStructureType = () => {
  const dispatch = useDispatch();
  const options = useSelector(selectStructureTypes);

  useEffect(() => {
    dispatch(getStructureTypes());
  }, []);

  function handlerChange(item) {
    dispatch(changeFilterStructureType(item));
  }

  return (
    <StyledComponents.SpecialContainer margin="0 4px 0 0" width="150px" height="32px">
      <SelectGroup
        reportLabor={""}
        group={{ name: "Tipo de Estructura", parameters: options }}
        handlerChange={handlerChange}
        dropdownTop="36px"
        idReport={""}
        pos={0}
        disabled={""}
        hasReport={""}></SelectGroup>
    </StyledComponents.SpecialContainer>
  );
};

export default OptionStructureType;

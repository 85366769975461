import React from "react";
import { Container } from "reactstrap";
import {
  StyledComponents,
} from "../components-config";

const HeaderTitles = () => {
  return (
    <StyledComponents.MyLayoutSection
      height="40px"
      className="d-flex align-items-center bg-pink70"
      margin="4px 0"
    >
      <Container className="d-flex h-100">
        <StyledComponents.SpecialContainer
          width="224px"
          minWidth="224px"
          padding="0 10px"
          className="d-flex align-items-center"
          borderRight="4px solid white"
        >
          <StyledComponents.MyP
            fontSize="14px"
            className="text-white font-weight-bold"
            margin="none"
          >
            {" "}
            Tipo
          </StyledComponents.MyP>
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer
          width="224px"
          minWidth="224px"
          padding="0 10px"
          className="d-flex align-items-center"
          borderRight="4px solid white"
        >
          <StyledComponents.MyP
            fontSize="14px"
            className="text-white font-weight-bold"
            margin="none"
          >
            Sub-tipo
          </StyledComponents.MyP>{" "}
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer
          padding="0 10px"
          className="d-flex align-items-center"
        >
          <StyledComponents.MyP
            fontSize="14px"
            className="text-white font-weight-bold"
            margin="none"
          >
            Parámetros
          </StyledComponents.MyP>{" "}
        </StyledComponents.SpecialContainer>
      </Container>
    </StyledComponents.MyLayoutSection>
  );
};

export default HeaderTitles;
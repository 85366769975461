import { combineReducers } from "redux";
import componentReducer from "./slices/component";
import uiFormReducer from "./slices/uiForm";
import uiLoadingReducer from "./slices/uiLoading";


const rootReducer = combineReducers({
  ui: combineReducers({
    form: uiFormReducer,
    loading: uiLoadingReducer,
  }),
  component: componentReducer,
});

export default rootReducer;

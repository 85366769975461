import {
  INTEGRITY_CARGANDO,
  INTEGRITY_ERROR,
  INTEGRITY_CLEAN_STATE,
  INTEGRITY_COMPANIES_LIST,
  INTEGRITY_MODULES_LIST,
  INTEGRITY_SUB_MODULES_LIST,
  INTEGRITY_CHANGE_STATE,
} from "./integrityTypes";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    error: "",
  },
  companies_list: [],
  modules_list: [],
  sub_modules_list: [],
  company_selected: {id:'0', name:'Seleccione una opción'},
  module_selected: {id:'0', name:'Elija un módulo...'},
  sub_module_selected: {id:'0', name:'Elija un sub-módulo...'},
  url: ''
};

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INTEGRITY_CARGANDO:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          error: "",
        },
      };
    case INTEGRITY_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: action.payload,
        },
      };

    case INTEGRITY_CLEAN_STATE:  
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        companies_list: [],
        modules_list: [],
        sub_modules_list: [],
        module_selected: "",
        sub_module_selected: "",
      };

    case INTEGRITY_MODULES_LIST:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        modules_list: action.payload,
      };

    case INTEGRITY_COMPANIES_LIST:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        companies_list: action.payload,
      };
    case INTEGRITY_SUB_MODULES_LIST:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        sub_modules_list: action.payload,
      };

    case INTEGRITY_CHANGE_STATE:
      return {
        ...state,
        [action.payload.variable]: action.payload.value,
      };

    default:
      return { ...state };
  }
};

export default Reducer;

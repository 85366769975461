import {
  INCIDENTS_CARGANDO,
  INCIDENTS_ERROR,
  INCIDENTS_CLEAN_STATE,
  INCIDENTS_CHANGE_STATE,
  GetIncidentsLayout,
  GetListPaychecks,
  PostSendIncidents,
  GetKronosName,
  PostTransferKronos,
  GetInfoPayroll,
} from "./incidentsTypes";

import { errorHandler } from "../../components-api/ConfigApi";

const parserJson = (obj) => {
  var jsonSerialize = "";
  for (const prop in obj) {
    jsonSerialize += `${prop}=${prop === "paychecks" ? "[" : ""}${obj[prop]}${
      prop === "paychecks" ? "]" : ""
    }&`;
  }
  jsonSerialize = jsonSerialize.slice(0, -1);
  return jsonSerialize;
};

export const reportsCleanState = () => (dispatch) => {
  dispatch({
    type: INCIDENTS_CLEAN_STATE,
  });
};

export const changeState = (variable, value) => (dispatch) => {
  dispatch({
    type: INCIDENTS_CHANGE_STATE,
    payload: {
      variable,
      value,
    },
  });
};

export const reportsMethods = (data, method) => async (dispatch) => {
  dispatch({
    type: INCIDENTS_CARGANDO,
  });
  try {
    let answer;
    switch (method) {
      case "GetIncidentsLayout":
        answer = await GetIncidentsLayout(data);
        break;
      case "GetListPaychecks":
        answer = await GetListPaychecks(data);
        break;
      case "GetInfoPayroll":
        answer = await GetInfoPayroll(data);
        break;
      case "PostSendIncidents":
        answer = await PostSendIncidents(data);
        break;
      case "GetKronosName":
        answer = await GetKronosName(data);
        break;
      case "PostTransferKronos":
        answer = await PostTransferKronos(data);
        break;
      default:
        break;
    }

    dispatch({
      type: answer.type,
      payload: answer.res.data,
    });
  } catch (error) {
    let messageError = errorHandler(error);
    dispatch({
      type: INCIDENTS_ERROR,
      payload: messageError,
    });
  }
};

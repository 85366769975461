import React from 'react'
import {Settlement, SettlementUser} from '../../components-settlement'

export const SettlementModule =() => {
    return(
        <Settlement />
    )
}

export const SettlementUserModule = () => {
    return(
        <SettlementUser/>
    )
}
import React from "react";
import {Report} from '../../components-reports'
import { ReportQuery } from '../../components-report-query'

export const ReportModule = () => {
  return <>
    <Report></Report>
  </>;
};

export const ReportQueryModule = () => {
  return <>
    <ReportQuery></ReportQuery>
  </>;
};



import { axios_api } from "../../components-api/ConfigApi";

export const CAFETERIA_CARGANDO = "CAFETERIA_CARGANDO";
export const CAFETERIA_ERROR = "CAFETERIA_ERROR";
export const CAFETERIA_CLEAN_STATE = "CAFETERIA_CLEAN_STATE";
export const CAFETERIA_MEALS_LIST = "CAFETERIA_MEALS_LIST";
export const CAFETERIA_MEALS_LIST_ADD = "CAFETERIA_MEALS_LIST_ADD";
export const CAFETERIA_GET_QR = "CAFETERIA_GET_QR";
export const CAFETERIAS_CRUD = "CAFETERIAS_CRUD";
export const CAFETERIA_CHANGE_STATE = "CAFETERIA_CHANGE_STATE";

const parserJson = (obj) => {
  var jsonSerialize = "";
  for (const prop in obj) {
    jsonSerialize += `${prop}=${prop === "paychecks" ? "[" : ""}${obj[prop]}${
      prop === "paychecks" ? "]" : ""
    }&`;
  }
  jsonSerialize = jsonSerialize.slice(0, -1);
  return jsonSerialize;
};

export const GetCafeteriaMeals = async (data) => {
  let type = CAFETERIA_MEALS_LIST;

  const answer = await axios_api(
    `${data.selected_sub_module}/?client=${data.company_selected}&page=${data.page}`,
    true,
    "get",
    {},
    600000
  );
  if (data.add) {
    type = CAFETERIA_MEALS_LIST_ADD;
  }
  return { res: answer, type: type };
};

export const GetCafeteriaMealsFilter = async (data) => {
  const parserData = parserJson(data);
  let type = CAFETERIA_MEALS_LIST;

  const answer = await axios_api(
    `${data.selected_sub_module}/?client=${data.selected_company}&page=${data.page}`,
    true,
    "post",
    parserData,
    600000
  );
  if (data.add) {
    type = CAFETERIA_MEALS_LIST_ADD;
  }
  return { res: answer, type: type };
};
export const GetQr = async () => {
  const answer = await axios_api(`/cafeteria/qr/`, true, "get", {}, 600000);
  return { res: answer, type: CAFETERIA_GET_QR };
};

import { useSelector } from "react-redux";
import { Modals } from "../components-config";
import { selectCargando } from "./selectors/module";

export const Loader = ({ message }) => {
  const cargando = useSelector(selectCargando);
  return cargando === true ? (
    <Modals.ModalLoading message={message || undefined} />
  ) : null;
};

import {
  LOGIN_CARGANDO,
  LOGIN_ERROR,
  LOGIN_HANDLE_CHANGE,
  LOGIN_CLEAN_STATE,
  LOGIN_USER,
  LOGIN_HANDLE_VALIDATION,
} from "./loginTypes";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    error: "",
  },
  data: {
    username: "",
    succes_change_password: "",
    send_email_password: ""
  },
  validations: {
    username: "",
    succes_change_password: "",
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_CARGANDO:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          error: "",
        },
      };
    case LOGIN_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: action.payload,
        },
      };
    case LOGIN_HANDLE_CHANGE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.e.name]: action.payload.e.value,
        },
        validations: {
          ...state.validations,
          [action.payload.e.name]: action.payload.isInvalid,
        },
      };
    case LOGIN_HANDLE_VALIDATION:
      return {
        ...state,
        validations: {
          ...state.validations,
          [action.payload.name]: action.payload.isInvalid,
        },
      };
    case LOGIN_CLEAN_STATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        data: {
          username: "",
          succes_change_password: "",
          send_email_password:""
        },
        validations: {
          username: "",
          succes_change_password: "",
        },
      };
    case LOGIN_USER:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        data: {
          username: action.payload.user_email,
          succes_change_password: action.payload.message === 'password changed correctly',
          send_email_password: action.payload.code === 202 ? 'ok' : ''
        },
        validations: {
          username: "",
          succes_change_password: "",
        },
      };
    default:
      return state;
  }
};

export default reducer;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Assets, Dropdown, StyledComponents } from "../components-config";

export const CardGroups = styled.div`
  width: ${(props) => (props.width ? `${props.width}` : "200px")};
  border-radius: 10px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 10px 8px 9px;
    color: black;
  }
  li:hover {
    background-color: var(--grey90);
    //border-radius: 10px;
  }
  li:first-child {
    border-radius: ${(props) =>
      props.borderRadius
        ? `${props.borderRadius}px ${props.borderRadius}px 0 0 `
        : "10px 10px 0 0"};
  }

  li:last-child {
    border: none;
    border-radius: ${(props) =>
      props.borderRadius
        ? ` 0 0 ${props.borderRadius}px ${props.borderRadius}px`
        : "0 0 10px 10px"};
  }
  li:only-child {
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px ` : "10px")};
  }
`;

export const DropDownSelectSearch = ({
  dropdownTop,
  group,
  onChange,
  width,
  overflow = true,
  disabled = false,
}) => {
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [filtered, setFiltered] = useState([]);

  const toggle = () => {
    if (!disabled) {
      setIsDropdownVisible((prevState) => !prevState);
      setFiltered(group);
    }
  };

  const handleSelect = (item) => {
    setSelected(item);
    setQuery(`${item.razonSocial} - ${item.rfc}`);
    setIsDropdownVisible(false);
    onChange(item);
  };

  useEffect(() => {
    setFiltered(group);
  }, []);

  const onChangeInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    setIsDropdownVisible(true);

    const filteredData = group.filter((item) =>
      `${item.razonSocial.toLowerCase()} - ${item.rfc.toLowerCase()}`.includes(
        inputValue.toLowerCase()
      )
    );
    setFiltered(filteredData);
  };

  const handleBlur = () => {
    if (selected) {
      setQuery(`${selected.razonSocial} - ${selected.rfc}`);
    }
  };

  return (
    <Dropdown.LayoutDropDown
      maxWidthToggle="540px"
      dropdownToggle={
        <StyledComponents.SpecialContainer
          margin="0"
          className={`d-flex justify-content-between align-items-center bg-grey130 ${
            disabled ? "disabled" : ""
          }`}
          width={width}
          height="32px"
          padding="0 10px"
          borderRadius="40px">
          <StyledComponents.MyInput
            type="text"
            borderRadius="40px"
            textAlign="left"
            className="bg-grey130"
            margin="2px 4px 2px -6px"
            placeholder="-- Elija una opción --"
            value={query}
            fontSize="12px"
            minWidth="169px"
            padding="0 10px"
            onChange={(e) => {
              onChangeInput(e);
            }}
            onBlur={handleBlur}
          />
          <span className="d-flex align-items-center">
            <Assets.IconArrows />
          </span>
        </StyledComponents.SpecialContainer>
      }
      dropdownOpen={isDropdownVisible}
      toggle={toggle}>
      <StyledComponents.DivToRelative top={dropdownTop} zIndex="100" width={width}>
        {isDropdownVisible && (
          <StyledComponents.OverFlowSelects height={overflow ? "235px" : ""} className="bg-grey130">
            <CardGroups width={width} disabled={true}>
              <ul>
                {filtered.length > 0 ? (
                  filtered.map((item) => (
                    <li
                      key={item.id}
                      className={`pointer`}
                      onMouseDown={() => handleSelect(item)}
                      style={{ fontSize: "12px" }}>
                      {item.razonSocial} - {item.rfc}
                      {selected?.id === item.id && (
                        <Assets.IconCheck color="var(--purple50)" colorCheck="white" size="15" />
                      )}
                    </li>
                  ))
                ) : (
                  <li></li>
                )}
              </ul>
            </CardGroups>
          </StyledComponents.OverFlowSelects>
        )}
      </StyledComponents.DivToRelative>
    </Dropdown.LayoutDropDown>
  );
};

import {
  LOGIN_CARGANDO,
  LOGIN_ERROR,
  LOGIN_HANDLE_CHANGE,
  LOGIN_CLEAN_STATE,
  LOGIN_HANDLE_VALIDATION,
  Login,
  SendEmailPassword,
  ChangePasswordFromEmail,
  ChangePasswordFromUser
} from "./loginTypes";

import { errorHandler, setLocalStorage } from "../../components-api/ConfigApi";

/*
 *Metodo para serializar un objeto
 */
const parserJson = (obj) => {
  var jsonSerialize = "";
  for (const prop in obj) {
    jsonSerialize += prop + "=" + obj[prop] + "&";
  }
  jsonSerialize = jsonSerialize.slice(0, -1);
  return jsonSerialize;
};

export const loginHandleChange = (e, isInvalid) => (dispatch) => {
  dispatch({
    type: LOGIN_HANDLE_CHANGE,
    payload: { e: e, isInvalid: isInvalid },
  });
};

export const loginHandleValidation = (validation) => (dispatch) => {
  dispatch({
    type: LOGIN_HANDLE_VALIDATION,
    payload: validation,
  });
};

export const loginCleanState = () => (dispatch) => {
  dispatch({
    type: LOGIN_CLEAN_STATE,
  });
};

export const loginMethods = (data, typeMethod) => async (dispatch) => {
  dispatch({
    type: LOGIN_CARGANDO,
  });
  try {
    let answer;
    switch (typeMethod) {
      case "Login":
        setLocalStorage();
        let dataObject = parserJson(data);
        answer = await Login(dataObject);
        setLocalStorage(answer.res);
        break;
      case "SendEmailPassword": 
        let dataEmailObject = parserJson(data);
        answer = await SendEmailPassword(dataEmailObject);
        break;
      case "ChangePasswordFromEmail":
        let dataEmailToChangePasswordObject = parserJson(data);
        answer = await ChangePasswordFromEmail(dataEmailToChangePasswordObject);
        break;
      case "ChangePasswordFromUser":
        let dataUserToChangePasswordObject = parserJson(data);
        answer = await ChangePasswordFromUser(dataUserToChangePasswordObject);
        break;
      default:
        break;
    }

    dispatch({
      type: answer.type,
      payload: answer.res.data,
    });
  } catch (error) {
    let messageError = errorHandler(error);
    dispatch({
      type: LOGIN_ERROR,
      payload: messageError,
    });
  }
};

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "reactstrap";
import {
  Assets,
  StyledComponents,
  Inputs,
  Buttons,
  Dropdown,
  Layouts,
} from "../components-config";
import { RedirectSelects } from "../components-integrity";

import { useInput } from "../components-config/hooks";
import styled from "styled-components";
import { CheckBoxConcepts, SelectGroups } from "./ReportComponents";
import * as reportActions from "./reducer/reportActions";

// Report Information, name and date.
const ReportInformation = () => {
  return (
    <Layouts.Info>
      <StyledComponents.SpecialContainer width="34px" margin="0 36px 0 0">
        <Assets.IconSheet />
      </StyledComponents.SpecialContainer>
      <div>
        <StyledComponents.MyP
          fontSize="14px"
          className="font-weight-bold text-purple40"
        >
          Hola, {localStorage.getItem("username")}.
        </StyledComponents.MyP>
        <StyledComponents.MyP fontSize="14px" className="text-purple40">
          Recuerda que, tanto el nombre, como los valores pre-seleccionados en
          la sección “Columnas” corresponden a la definición de cada tipo de
          reporte, hecha conforme a los requerimientos específicos del área de
          nóminas/RRHH.
        </StyledComponents.MyP>
      </div>
    </Layouts.Info>
  );
};

// Header Reports Title.
const ReportHeadersTitle = () => {
  return (
    <StyledComponents.MyLayoutSection
      height="40px"
      className="d-flex align-items-center bg-pink70"
      margin="4px 0"
    >
      <Container className="d-flex h-100">
        <StyledComponents.SpecialContainer
          padding="0 31px"
          width="84px"
          borderRight="4px solid white"
          className="d-flex h-100"
        >
          <div className="d-flex align-items-center">
            <Assets.IconChecks />
          </div>
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer
          width="224px"
          minWidth="224px"
          padding="0 10px"
          className="d-flex align-items-center"
          borderRight="4px solid white"
        >
          <StyledComponents.MyP
            fontSize="14px"
            className="text-white font-weight-bold"
            margin="none"
          >
            {" "}
            Tipo de Reporte
          </StyledComponents.MyP>
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer
          width="224px"
          minWidth="224px"
          padding="0 10px"
          className="d-flex align-items-center"
          borderRight="4px solid white"
        >
          <StyledComponents.MyP
            fontSize="14px"
            className="text-white font-weight-bold"
            margin="none"
          >
            Colúmnas
          </StyledComponents.MyP>{" "}
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer
          padding="0 10px"
          className="d-flex align-items-center"
        >
          <StyledComponents.MyP
            fontSize="14px"
            className="text-white font-weight-bold"
            margin="none"
          >
            Parámetros
          </StyledComponents.MyP>{" "}
        </StyledComponents.SpecialContainer>
      </Container>
    </StyledComponents.MyLayoutSection>
  );
};

const CardConcepts = styled.div`
  background: var(--purple90);
  width: 220px;
  height: 270px;
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    font-size: 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 10px 8px 9px;
  }
  li:nth-child(even) {
    background: var(--purple100);
  }
`;

// Search concepts.
const SelectedConcepts = ({ isCheck, concepts, idReport }) => {
  const reportReducer = useSelector((state) => state.reportReducer);

  return (
    <>
      <Dropdown.SelectConcepts
        dropdownTop="42px"
        dropdownToggle={
          <StyledComponents.SpecialContainer
            margin="0 4px 0 0"
            className={`d-flex align-items-center justify-content-between bg-purple90 ${
              !isCheck ? "disabled" : ""
            }`}
            width="220px"
            minWidth="220px"
            height="32px"
            padding="0 10px"
            borderRadius="4px"
          >
            <StyledComponents.MyP
              fontSize="12px"
              className="font-weight-bold"
              margin="0"
            >
              Catálogo de conceptos
            </StyledComponents.MyP>
            <span className="d-flex align-items-center">
              <Assets.IconArrows />
            </span>
          </StyledComponents.SpecialContainer>
        }
      >
        <StyledComponents.OverFlowSelects
          borderRadius="0"
          height={"270px"}
          bgColor="var(--purple90)"
        >
          <CardConcepts>
            <ul>
              {concepts.map((item) => {
                return (
                  <li key={item.id}>
                    {item.name}
                    <div>
                      <CheckBoxConcepts
                        idReport={idReport}
                        value={item.is_predefined}
                        idConcept={item.id}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </CardConcepts>
        </StyledComponents.OverFlowSelects>
        <StyledComponents.SpecialContainer
          className="bg-purple90 d-flex align-items-center"
          height="42px"
          padding="9px"
        >
          <StyledComponents.MyInput
            placeholder="Buscar conceptos"
            className="input-search"
            type="search"
            margin="0 9px 0 0"
          ></StyledComponents.MyInput>
          <span className="d-flex align-items-center">
            <Assets.IconChecks colorCheck="white" />
          </span>
        </StyledComponents.SpecialContainer>
      </Dropdown.SelectConcepts>
    </>
  );
};

// Report labort type.
const ReportType = ({ report, id }) => {
  const reportReducer = useSelector((state) => state.reportReducer);
  const { send_report_list } = reportReducer;
  const dispatch = useDispatch();
  const [reportLabor, setReportLabel] = useState(false);
  const [editNameReport, setEditNameReport] = useState(false);

  const reportName = useInput.InputValue("", "", "", report.name);
  const employees = useInput.InputValue("", "", "", "");

  const updateRepprtModel = (update) => {
    if (update) {
      let concepts = [];

      report.concepts.forEach((concept) => {
        concepts.push(concept.id);
      });

      const model = {
        id: id,
        name: reportName.inputValue,
        concepts: concepts,
        params: [0, 0],
        employees: "",
      };

      send_report_list.push(model);
      dispatch(reportActions.changeState("send_report_list", send_report_list));
    } else {
      const isEqual = (item) => item.id === id;
      const pos = send_report_list.findIndex(isEqual);
      send_report_list.splice(pos, 1);
      dispatch(reportActions.changeState("send_report_list", send_report_list));
    }

    setReportLabel(update);
  };

  const updateName = (e) => {
    reportName.inputHandleChange(e);
    const isEqual = (item) => item.id === id;
    const pos = send_report_list.findIndex(isEqual);
    send_report_list[pos].name = e.target.value;
    dispatch(reportActions.changeState("send_report_list", send_report_list));
  };

  const updateEmployees = (e) => {
    employees.inputHandleChange(e);
    // let addEmployee = employees.inputValue;
    // let employee = "";
    // if (addEmployee.length > 6) {
    //   debugger
    //   addEmployee = employees.inputValue.match(/.{1,6}/g);
    //   employee = addEmployee.map((emp) => {
    //     return `${emp},`;
    //   });
    //   employees.setInputValue(employee);
    // }

    const isEqual = (item) => item.id === id;
    const pos = send_report_list.findIndex(isEqual);
    send_report_list[pos].employees = e.target.value;
    dispatch(reportActions.changeState("send_report_list", send_report_list));
  };

  return (
    <StyledComponents.SpecialContainer margin="0 0 4px 0">
      <Container className="d-flex">
        <StyledComponents.SpecialContainer
          margin="0 4px 0 0"
          borderRadius="4px"
          width="80px"
          minWidth="80px"
          height="32px"
          className="bg-grey130 d-flex justify-content-center align-items-center"
        >
          <Inputs.CheckBoxReport
            isCheck={reportLabor}
            setIsCheck={() => updateRepprtModel(!reportLabor)}
          />
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer
          margin="0 4px 0 0"
          className={`d-flex justify-content-between align-items-center bg-grey130 ${
            !reportLabor ? "disabled" : ""
          }`}
          width="220px"
          minWidth="220px"
          height="32px"
          padding={`${!editNameReport ? "0 10px" : "0 10px 0 0"}`}
          borderRadius="4px"
        >
          {!editNameReport ? (
            <StyledComponents.MyP
              fontSize="12px"
              className="font-weight-bold"
              margin="0"
            >
              {reportName.inputValue}
            </StyledComponents.MyP>
          ) : (
            <StyledComponents.SpecialContainer
              margin="0 0 0 4px"
              width="178px"
              maxWidth="184px"
              minWidth="184px"
            >
              <StyledComponents.MyInput
                maxWidth="184px"
                minWidth="184px"
                width="184px"
                placeholder="TMMGT_LABOR TYPE"
                className="input-reports-employee m-0"
                disabled={!reportLabor}
                type="text"
                value={reportName.inputValue}
                onChange={(e) => updateName(e)}
              ></StyledComponents.MyInput>
            </StyledComponents.SpecialContainer>
          )}
          <StyledComponents.SpecialContainer
            className="d-flex align-items-center"
            onClick={() => setEditNameReport(!editNameReport)}
            margin={"0"}
            width={"12px"}
            maxWidth="12px"
            minWidth="12px"
          >
            <Assets.IconEdit />
          </StyledComponents.SpecialContainer>
        </StyledComponents.SpecialContainer>
        <SelectedConcepts
          idReport={id}
          isCheck={reportLabor}
          concepts={report.concepts}
        />

        <SelectGroups
          reportLabor={reportLabor}
          group={report.parameterGroups[0]}
          dropdownTop="36px"
          idReport={id}
          pos={0}
          disabled={employees.inputValue}
          hasReport={reportLabor}
        ></SelectGroups>
        <SelectGroups
          reportLabor={reportLabor}
          group={report.parameterGroups[1]}
          dropdownTop="36px"
          idReport={id}
          pos={1}
          disabled={employees.inputValue}
          hasReport={reportLabor}
        ></SelectGroups>
        <StyledComponents.SpecialContainer
          margin="0 4px 0 0"
          className={`bg-grey130 ${"disabled"}`}
          width="200px"
          height="32px"
          padding="4px"
          borderRadius="4px"
        >
          <StyledComponents.MyInput
            placeholder="No. Empleado(s)..."
            className="input-reports-employee"
            disabled={!reportLabor}
            type="text"
            value={employees.inputValue}
            onChange={(e) => updateEmployees(e)}
          ></StyledComponents.MyInput>
        </StyledComponents.SpecialContainer>
      </Container>
    </StyledComponents.SpecialContainer>
  );
};

const ReportButtons = () => {
  const reportReducer = useSelector((state) => state.reportReducer);
  const { send_report_list } = reportReducer;
  const integrityReducer = useSelector((state) => state.integrityReducer);
  const { url, company_selected } = integrityReducer;
  const disabled = send_report_list.length > 0;

  return (
    <StyledComponents.MyLayoutSection>
      <Container>
        <StyledComponents.SpecialContainer
          margin="30px 0"
          className={`d-flex justify-content-end ${
            disabled === false && "disabled"
          }`}
          fontSize="12px"
        >
          <Buttons.Download
            fileName={`${company_selected.id}-reporte`}
            uri={`${url}/download/?client=${company_selected.id}`}
            data={send_report_list}
            disabled={disabled}
          />
          <Buttons.ButtonSend
            padding="8px 18px"
            classButton="font-weight-bold bg-green40 text-white"
            iconColor="white"
            ml="ml5"
            margin="0 25px"
            isActive={disabled}
            description={
              <StyledComponents.MyP fontSize="18px">
                El reporte(s) seleccionado(s) se enviará a la siguiente
                dirección de correo:{" "}
                <span className="font-weight-bold">
                  {" "}
                  {localStorage.getItem("email")}
                </span>
              </StyledComponents.MyP>
            }
            description2={
              <StyledComponents.MyP className="font-weight-bold">
                Ingrese la dirección de correo a la cual desea que llegue el
                Reporte. Si son varias, sepárelas con una coma.
              </StyledComponents.MyP>
            }
            uri={`${url}/send/?client=${company_selected.id}`}
            modelToSend={{data: send_report_list}}
          />
        </StyledComponents.SpecialContainer>
      </Container>
    </StyledComponents.MyLayoutSection>
  );
};

export const Report = () => {
  const reportReducer = useSelector((state) => state.reportReducer);
  const { report_list } = reportReducer;

  const integrityReducer = useSelector((state) => state.integrityReducer);
  const { url, company_selected } = integrityReducer;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      reportActions.reportsMethods(
        { url: url, company_selected: company_selected.id },
        "GetReportsFilters"
      )
    );
    return () => {
      dispatch(reportActions.reportsCleanState());
    };
  }, [dispatch, url, company_selected]);

  RedirectSelects();

  return (
    <>
      {/* Modulo de información */}
      <ReportInformation />
      <ReportHeadersTitle />
      {report_list.map((report) => {
        return <ReportType report={report} id={report.id} />;
      })}
      <Layouts.Border margin="30px 0 0 0" border="1px dashed var(--pink80)" />
      <ReportButtons />
    </>
  );
};

import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { Assets, Dropdown, StyledComponents } from "../components-config";
import * as reportActions from "./reducer/reportActions";

const findPosition = (id, list) => {
  const isEqual = (item) => item.id === id;
  const pos = list.findIndex(isEqual);
  return pos;
};

// Check for concepts.
export const CheckBoxConcepts = ({ idReport, value, idConcept }) => {
  const [toggle, setToggle] = useState(value);
  const reportReducer = useSelector((state) => state.reportReducer);
  const { send_report_list, report_list } = reportReducer;
  const dispatch = useDispatch();
  console.log("check", value);
  const click = () => {
    if (!toggle) {
      const pos = findPosition(idReport, send_report_list);
      send_report_list[pos].concepts.push(idConcept);
      dispatch(reportActions.changeState("send_report_list", send_report_list));
      //Añadir concepto en lista send
      const pos2 = findPosition(idReport, report_list);
      const isEqual = (num) => num === idConcept;
      const posConcept = send_report_list[pos].concepts.findIndex(isEqual);
      console.log(idConcept, posConcept);

      report_list[pos2].concepts[posConcept].is_predefined = true;
      dispatch(reportActions.reportsMethods("report_list", report_list));
      //Actualizar concepto en lista
    } else {
      //Eliminar concepto
      const pos = findPosition(idReport, send_report_list);
      const isEqual = (num) => num === idConcept;
      const posConcept = send_report_list[pos].concepts.findIndex(isEqual);
      send_report_list[pos].concepts.splice(posConcept, 1);
      dispatch(reportActions.changeState("send_report_list", send_report_list));
      //Actualizar lista de conepto
      const pos2 = findPosition(idReport, report_list);

      const pos3 = findPosition(idConcept, report_list[pos2].concepts);
      report_list[pos2].concepts[pos3].is_predefined = false;
      console.log(idConcept, posConcept);

      dispatch(reportActions.reportsMethods("report_list", report_list));
    }
    //dispatch()
    setToggle(!toggle);
  };
  return (
    <Assets.CheckBoxIcon
      toggle={toggle}
      onClick={() => click()}
    ></Assets.CheckBoxIcon>
  );
};

// Select for Reports
export const CardGroups = styled.div`
  background: var(--grey130);
  width: 220px;
  border-radius: 10px;
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    font-size: 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 10px 8px 9px;
  }
  li:hover {
    background-color: var(--grey90);
  }
`;

export const SelectGroups = ({
  reportLabor,
  dropdownTop,
  group,
  idReport,
  pos,
  disabled,
  hasReport
}) => {
  const reportReducer = useSelector((state) => state.reportReducer);
  const { send_report_list } = reportReducer;
  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [value, setValue] = useState(group.name);
  const changeStateGroup = (item) => {
    setValue(item.name);
    toggle();
    const posList = findPosition(idReport, send_report_list);
    send_report_list[posList].params[pos] = item.id;
    dispatch(
      reportActions.reportsMethods("send_report_list", send_report_list)
    );
    //set reducer
  };
  return (
    <>
      <Dropdown.LayoutDropDown
        maxWidthToggle="224px"
        dropdownToggle={
          <StyledComponents.SpecialContainer
            margin="0 4px 0 0"
            className={`d-flex justify-content-between align-items-center bg-grey130 ${
              !reportLabor || disabled ? "disabled" : ""
            }`}
            width="220px"
            height="32px"
            padding="0 10px"
            borderRadius="40px"
          >
            <StyledComponents.MyP
              fontSize="12px"
              className="font-weight-bold"
              margin="0"
            >
              {value}
            </StyledComponents.MyP>
            <span className="d-flex align-items-center">
              <Assets.IconArrows />
            </span>
          </StyledComponents.SpecialContainer>
        }
        dropdownOpen={dropdownOpen}
        toggle={toggle}
        margin={""}
      >
        <StyledComponents.DivToRelative top={dropdownTop} zIndex="100">
          {(!disabled && hasReport ) && (
            <CardGroups>
              <ul>
                {group.parameters.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className="pointer"
                      onClick={() => changeStateGroup(item)}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </CardGroups>
          )}
        </StyledComponents.DivToRelative>
      </Dropdown.LayoutDropDown>
    </>
  );
};

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Layouts, StyledComponents } from "../../components-config";
import { Info } from "../../components-config/Layouts";
import { ToastContainer } from "./config/components";
import FormContainer from "./features/form/components/FormContainer";
import TransferContainer from "./features/form/components/TransferContainer";
import "./index.css";
import { fetchSettingForm } from "./store/slices/component";

function Cms() {
  const dispatch = useDispatch();

  const [setting, setSetting] = useState({});

  const { type, title } = setting

  let component = null;

  if (type === "FORM") {
    component = <FormContainer setting={setting}/>;
  } else if (type === "TRANSFER") {
    component = <TransferContainer setting={setting}/>;
  } else if (type === "UPLOAD") {
    component = <TransferContainer setting={setting}/>;
  }

  useEffect(() => {
    dispatch(fetchSettingForm())
      .unwrap()
      .then((data) => {
        setSetting(data);
      });
  }, []);

  return (
    <>
      <ToastContainer />
      <Info>
        <div>
          <StyledComponents.MyP fontSize="14px" className="font-weight-bold text-purple40">
            Proceso Serie i
          </StyledComponents.MyP>
          <StyledComponents.MyP fontSize="14px" className="text-purple40">
            {title}
          </StyledComponents.MyP>
        </div>
      </Info>
      <Layouts.Card
        margin="4px 0 0 0"
        padding="20px"
        borderRadius="0 0 20px 20px"
        className="text-purple40">
        <StyledComponents.SpecialContainer className="d-flex flex-column">
          <StyledComponents.SpecialContainer width="300px">
            {component}
          </StyledComponents.SpecialContainer>
        </StyledComponents.SpecialContainer>
      </Layouts.Card>
    </>
  );
}

export default Cms;

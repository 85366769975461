import React, { useState } from "react";
import {useHistory} from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { StyledComponents, Modals } from "../components-config";
import { useInput } from "../components-config/hooks";
import * as loginActions from "./reducer/loginActions";
export const Login = ({redirect='/'}) => {
    const history = useHistory();
  // seteamos valores del formulario
  const [errorForm, setErrorForm] = useState(false);
  const email = useInput.InputValue(
    "",
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
  );
  const password = useInput.InputValue();

  // reducer
  const loginReducer = useSelector((state) => state.loginReducer);
  const {
    api_actions: { error, cargando },
    data: {username}
  } = loginReducer;
  const dispatch = useDispatch();

  const Action = (e) => {
    e.preventDefault();
    let validate = useInput.ValidateForm([email, password]);
    if (validate) {
      dispatch(
        loginActions.loginMethods({
          username: email.inputValue,
          password: password.inputValue,
        }, 'Login')
      );
    } else {
      setErrorForm(true);
    }
  };

  if(username){
      history.push(redirect)
  }

  return (
    <>
    {cargando && <Modals.ModalLoading ></Modals.ModalLoading>}
      <form action="">
        <StyledComponents.MyInput
          margin="30px auto"
          maxWidth="260px"
          type="text"
          placeholder="Correo Electrónico"
          value={email.inputValue}
          onChange={(e) => email.inputHandleChange(e)}
          className={`${
            email.validate
              ? "input-success"
              : email.validate === false
              ? "input-error"
              : ""
          }`}
        ></StyledComponents.MyInput>
        <StyledComponents.MyInput
          margin="30px auto"
          maxWidth="260px"
          placeholder="Contraseña"
          type="password"
          value={password.inputValue}
          onChange={(e) => password.inputHandleChange(e)}
          className={`${
            password.validate
              ? "input-success"
              : password.validate === false
              ? "input-error"
              : ""
          }`}
        ></StyledComponents.MyInput>
        <StyledComponents.MyButton
          as="input"
          type="submit"
          maxWidth="260px"
          className="btn-green font-weight-bold"
          value="Acceder"
          onClick={(e) => Action(e)}
        />
      </form>
      {errorForm && (
        <StyledComponents.MyP
          margin="30px auto"
          className="text-danger font-weight-bold text-center"
        >
          Error, revisa los campos del formulario{" "}
        </StyledComponents.MyP>
      )}
      {error && (
        <StyledComponents.MyP
          margin="30px auto"
          className="text-danger font-weight-bold text-center"
        >
          {error}
        </StyledComponents.MyP>
      )}
    </>
  );
};

//Falta conectar Reducer
import {
  CFDI_STAMP_BALANCE_CHARGING,
  CFDI_STAMP_BALANCE_ERROR,
  CFDI_STAMP_BALANCE_CLEAN_STATE,
  CFDI_STAMP_BALANCE_CHANGE_STATE,
  CFDI_STAMP_BALANCE_GET
} from "./CFDIStampBalanceTypes";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    message_loading: "",
    error: "",
    success: false,
  },
  show_in: 10,
  list_page: [],
  list_data: [],
  list_selected: [],
  // Inputs
  action_success: '',
  overflowY: '200px',
};

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CFDI_STAMP_BALANCE_CHARGING:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          error: "",
        },
      };
    case CFDI_STAMP_BALANCE_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: action.payload,
        },
        list_page: [],
        list_data: [],
        list_selected: [],
      };

    case CFDI_STAMP_BALANCE_CLEAN_STATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        show_in: 10,
        list_page: [],
        list_data: [],
        list_selected: [],
        // Inputs
        action_success: '',
      };
    case CFDI_STAMP_BALANCE_CHANGE_STATE:
      return {
        ...state,
        [action.payload.variable]: action.payload.value,
      };
    case CFDI_STAMP_BALANCE_GET:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        list_data: action.payload.data,
        list_page: action.payload.data.slice(0, 500),
      }
      
    default:
      return { ...state };
  }
};

export default Reducer;

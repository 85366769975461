import React from "react";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";

// Components.
import { LogoIntegrity } from "./Assets";
import {
  MyHeader,
  MySecondHeader,
  ProfilePicture,
  MyP,
  BorderLinear,
  SpecialContainer,
} from "./StyledComponents";
import { DropdownUser } from "./Dropdowns";
import { useWindowSize } from "./hooks";
import { ModalLoading } from "./Modals";
import { Assets } from ".";

import {
  SelectCompanies,
  SelectModules,
  SelectSubModules,
} from "../components-integrity";

/*
 * Navbar de dashboard.
 */
// Navbar dashboard for desktop.
const NavbarDesktop = () => {

  const integrityReducer = useSelector((state) => state.integrityReducer);

  const {
    api_actions: { cargando },
  } = integrityReducer;

  return (
    <>
      <a href="/dashboard" target='_self'>
        <LogoIntegrity width="170px" />
      </a>
      {integrityReducer.company_selected.name !== "Seleccione una opción" && (
        <>
          {cargando && <ModalLoading />} <SelectCompanies />
          <div className={"d-flex"}>
            <SpecialContainer margin="0 4px 0 0">
              <SelectModules />
            </SpecialContainer>
            <SelectSubModules />
          </div>
        </>
      )}
      <div className="d-flex align-items-center ml-2">
        <div className="d-flex align-items-center">
          <SpecialContainer margin="0 8px 0 0">
            <ProfilePicture
              url={localStorage.getItem("photo")}
            ></ProfilePicture>
          </SpecialContainer>
          <DropdownUser></DropdownUser>
        </div>
      </div>
    </>
  );
};

// Navbar del dahsboard responsivo.
export const Dashboard = () => {
  return (
    <>
      <MyHeader paddingEnd="21px" paddingStart="21px">
        <Container>
          <NavbarDesktop></NavbarDesktop>
        </Container>
      </MyHeader>
      <BorderLinear />
    </>
  );
};

/*
 * Navbar de landing .
 */
export const Home = () => {
  return (
    <>
      <MyHeader paddingEnd="60px">
        <Container>
          <LogoIntegrity className="logo-integrity" />
        </Container>
      </MyHeader>
    </>
  );
};

export const CurrentModule = ({
  margin = "0",
  title,
  send,
  to = "/dashboard",
  borderTop,
  bgColor = 'bg-purple50',
  colorIcon,
  colorText = 'text-white'
}) => {
  const windowSize = useWindowSize();
  const fontSize = windowSize.width > 767 ? "24px" : "18px";
  return (
    <>
      <MySecondHeader
        borderTop={borderTop}
        margin={margin}
        className={bgColor }
        colorText={colorText}
      >
        <Container className="d-flex justify-content-between align-items-center">
          <div className="d-flex ">
            <Assets.IconBack2 color={colorIcon} to={to}></Assets.IconBack2>
          </div>
          <div className={colorText}>
            <MyP fontSize={fontSize}>{title}</MyP>
          </div>
          <div>{send}</div>
        </Container>
      </MySecondHeader>
    </>
  );
};

// export const SecondNavbar = () => {
//   return(
//     <MySecondHeader>

//     </MySecondHeader>
//   )
// }

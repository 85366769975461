// Imports de react
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, DropdownToggle } from "reactstrap";
import styled from "styled-components";
import { MyButton, DivToRelative, OverFlowSelects } from "./StyledComponents";
import { IconSelectArrow } from "./Assets";
import { CardExit, DropDownSelect, CardUser } from "./Cards";

const MyDropDown = styled.div`
  max-width: ${(props) =>
    props.maxWidthToggle ? props.maxWidthToggle : "inherit"};
  width: ${(props) => (props.width ? props.width : "100%")};
  margin: ${(props) => (props.margin ? props.margin : "none")};
  .dropdown-menu {
    background-color: transparent;
    text-align: center;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "230px")};
    min-width: ${(props) => (props.minWidth ? props.minWidth : "none")};
    width: ${(props) => (props.width ? props.width : "100%")};
    position: absolute !important;
    right: 0px !important;
    left: ${(props) => (props.left ? props.left : "none")}!important;
    top: ${(props) => (props.top ? props.top : "-5px")}!important;
    border: none;
  }
`;

export const LayoutDropDown = ({
  children,
  dropdownToggle,
  left,
  dropdownOpen,
  toggle,
  maxWidthToggle,
  maxWidth,
  width,
  minWidth,
  top,
  justifyContent,
  margin,
}) => {
  return (
    <MyDropDown
      className={`d-flex ${justifyContent}`}
      left={left}
      maxWidthToggle={maxWidthToggle}
      maxWidth={maxWidth}
      width={width}
      top={top}
      minWidth={minWidth}
      margin={margin}
    >
      <Dropdown
        className={`d-flex w-100 ${justifyContent}`}
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle
          tag="div"
          className={`pointer d-flex align-items-center w-100 ${justifyContent}`}
          // style={{ color: "red" }}
        >
          {dropdownToggle}
        </DropdownToggle>
        {dropdownOpen && children}
      </Dropdown>
    </MyDropDown>
  );
};

export const DropdownUser = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <LayoutDropDown
      typeCard="card-purple"
      dropdownToggle={
        <IconSelectArrow
          toggle={dropdownOpen}
          color="var(--purple40)"
        ></IconSelectArrow>
      }
      dropdownOpen={dropdownOpen}
      toggle={toggle}
      maxWidthToggle="14px"
      width="230px"
      top="0px"
    >
      {" "}
      <DivToRelative right="0" top="35px" width="230px" zIndex="100">
        <CardUser toggle={toggle}></CardUser>{" "}
      </DivToRelative>
    </LayoutDropDown>
  );
};

export const DropdownExit = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <LayoutDropDown
      typeCard="card-yellow"
      dropdownToggle={
        <MyButton maxWidth="100px" className="btn-exit font-weight-bold">
          Salir
        </MyButton>
      }
      dropdownOpen={dropdownOpen}
      toggle={toggle}
      width="230px"
      maxWidthToggle="100px"
      top="0px"
    >
      {" "}
      <DivToRelative right="0" top="60px" width="230px" zIndex="100">
        <CardExit toggle={toggle}></CardExit>{" "}
      </DivToRelative>
    </LayoutDropDown>
  );
};

export const Select = ({
  maxWidthToggle,
  paddingToggle = "13px 20px",
  margin,
  setValue,
  items,
  colorArrow,
  widthArrow,
  heightArrow,
  reducer,
  reducerValue,
  changeState,
  selectClass = "select-company",
  overflow,
  overflowHeight,
  dropdownTop = "44px",
  bgColorHover = "var(--grey90)",
  bgColor = "white",
  colorLi = "var(--purple40)",
  paddingLi,
  borderLi,
  boxShadow,
}) => {
  const reducerAux = useSelector((state) => state[reducer]);
  const dispatch = useDispatch();
  //const [selected, setSelected] = useState(placeHolderToggle);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const choose = (item) => {
    setValue && setValue(item);
    // setSelected(item.name);
    dispatch(changeState(reducerValue, item));
    toggle();
  };

  let rItems = items
    ? items
    : [
        { id: "1", name: " nomina 1" },
        { id: "2", name: " nomina 2" },
        { id: "3", name: " nomina 3" },
        { id: "4", name: " nomina 4" },
        { id: "5", name: " nomina 5" },
        { id: "6", name: " nomina 6" },
      ];

  return (
    <LayoutDropDown
      maxWidthToggle={maxWidthToggle}
      dropdownToggle={
        <MyButton
          className={`${selectClass} text-purple40 d-flex justify-content-between`}
          maxWidth={maxWidthToggle}
          padding={paddingToggle ? paddingToggle : "13px 20px"}
        >
          <div>
            {reducerAux[reducerValue]?.name.length > 30
              ? reducerAux[reducerValue].name.slice(0, 25) + "..."
              : reducerAux[reducerValue]?.name}
          </div>
          <div>
            <IconSelectArrow
              toggle={dropdownOpen}
              color={colorArrow}
              width={widthArrow}
              height={heightArrow}
            ></IconSelectArrow>
          </div>
        </MyButton>
      }
      dropdownOpen={dropdownOpen}
      toggle={toggle}
      margin={margin}
    >
      <DivToRelative top={dropdownTop} zIndex="100">
        {overflow ? (
          <OverFlowSelects height={overflowHeight} boxShadow={boxShadow}>
            <DropDownSelect
              bgColorHover={"var(--grey90)"}
              bgColor={"white"}
              colorLi={"var(--purple40)"}
              borderLi="none"
              //borderRadius="10px"
              //boxShadow={boxShadow}
              paddingLi={paddingLi}
              className="pointer"
            >
              {rItems.map((item) => {
                return (
                  <li key={item.id} onClick={() => choose(item)}>
                    {item.name}
                  </li>
                );
              })}
            </DropDownSelect>
          </OverFlowSelects>
        ) : (
          <DropDownSelect
            bgColorHover={bgColorHover}
            bgColor={bgColor}
            colorLi={colorLi}
            borderLi={borderLi}
            boxShadow={boxShadow}
            paddingLi={paddingLi}
            className="pointer"
          >
            {rItems.map((item) => {
              return (
                <li key={item.id} onClick={() => choose(item)}>
                  {item.name}
                </li>
              );
            })}
          </DropDownSelect>
        )}
      </DivToRelative>
    </LayoutDropDown>
  );
};

export const SelectConcepts = ({ dropdownToggle, dropdownTop, children }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <LayoutDropDown
        maxWidthToggle="224px"
        dropdownToggle={dropdownToggle}
        dropdownOpen={dropdownOpen}
        toggle={toggle}
        margin={""}
      >
        <DivToRelative top={dropdownTop} zIndex="100">
          {children}
        </DivToRelative>
      </LayoutDropDown>
    </>
  );
};

import React from "react";
import { Collapse, Container } from "reactstrap";
import { SendEmail } from "../components-email";
import { MyButton, SpecialContainer, MyP } from "./StyledComponents";
import { IconSendEmail, IconDownload, IconSearch } from "./Assets";
import { useWindowSize } from "./hooks";
import { DownloadFile } from "../components-download";

export const ButtonSearch = ({
  maxWidth = "127px",
  margin = "none",
  onClick,
  classButton = "btn-green",
}) => {
  const windowSize = useWindowSize();
  const padding = windowSize.width < 768 ? "5px 0" : "";
  return (
    <MyButton
      margin={margin}
      maxWidth={maxWidth}
      className={`${classButton} d-flex align-items-center justify-content-center`}
      padding={padding}
      onClick={onClick}
    >
      <div className="mr15">Buscar </div>
      <IconSearch />
    </MyButton>
  );
};

export const CollapseFilter = ({ children, toggle, padding = "25px 0" }) => {
  return (
    <Collapse isOpen={toggle}>
      <section className={"bg-pink40"}>
        <Container>
          <SpecialContainer padding={padding}>{children}</SpecialContainer>
        </Container>
      </section>
    </Collapse>
  );
};

export const ButtonSend = ({
  description,
  description2,
  uri,
  modelToSend,
  margin = "40px auto",
  padding,
  classButton = "bg-blue20",
  ml = "ml10",
  iconColor = "black",
  isActive,
  maxWidth = "127px",
}) => {
  return (
    <>
      <SendEmail
        description={description}
        description2={description2}
        uri={uri}
        modelToSend={modelToSend}
        isActive={isActive}
      >
        <MyButton
          padding={padding}
          maxWidth={maxWidth}
          margin={margin}
          className={classButton}
        >
          Enviar{" "}
          <span className={ml}>
            <IconSendEmail color={iconColor} />
          </span>
        </MyButton>
      </SendEmail>
    </>
  );
};

export const Download = ({
  padding,
  classButton,
  labelButton='Descargar',
  fileName,
  uri,
  data,
  disabled,
  borderRadius,
  icon
}) => {
  return (
    <DownloadFile
      className="d-flex pointer"
      fileName={fileName}
      uri={uri}
      data={data}
      isActive={disabled}
    >
      <SpecialContainer
        padding={padding}
        className={`d-flex ${classButton}`}
        borderRadius={borderRadius}
      >
        <SpecialContainer margin="0 5px 0 0">
          {icon ? icon : <IconDownload size="12" />}{" "}
        </SpecialContainer>
        <MyP fontSize="12px" className="font-weight-bold">
          {labelButton}
        </MyP>
      </SpecialContainer>
    </DownloadFile>
  );
};

export function attributeRename(objects, oldName, newName) {
  for (let i = 0; i < objects.length; i++) {
    objects[i][newName] = objects[i][oldName];
    delete objects[i][oldName];
  }
  return objects;
}

export function mergeList(listA, listB, identifierA, identifierB) {
  return listA.map((itemA) => {
    
    const matchingItem = listB.find((itemB) => itemB[identifierB] === itemA[identifierA]);

    console.log('Items',itemA[identifierA], 'matchingItem:', matchingItem);
    
    return { ...itemA, ...matchingItem };
  });
}

export function sortBy(array, field_name) {
  return array.slice().sort((a, b) => a[field_name] - b[field_name]);
}

import React from "react";
import { Table as RSTable } from "reactstrap";
import styled from "styled-components";

const MyTable = styled.div`
  thead {
    color: ${(props) => (props.colorTitle ? props.colorTitle : "#4B2192")};
    th {
      background-color: ${(props) =>
        props.colorHeader ? props.colorHeader : "#FAFAFA"};
      border-bottom: unset;
      border: none;
      font-size: 11px;
    }
    tr {
      background-color: ${(props) =>
        props.colorHeader ? props.colorHeader : "#FAFAFA"};
    }
  }
  tr {
    background-color: ${(props) =>
      props.colorHeader ? props.colorHeader : "#FAFAFA"};
  }
  th {
    color: ${(props) => (props.colorTitle ? props.colorTitle : "#4B2192")};
    background-color: ${(props) =>
      props.colorHeader ? props.colorHeader : "#FAFAFA"};
    border-top: 2px solid white;
    border-right: 2px solid white;
    padding: 0.25rem;
  }
  td {
    color: #4b2192;
    background-color: #eef1f9;
    border-top: ${(props) =>
      props.borderTop ? props.borderTop : "2px solid white"};
    border-right:  ${(props) =>
      props.borderRight ? props.borderRight : "2px solid white"};
  }
  .td-pk {
    color: #4b2192;
    background-color: #d3d7e3;
    font-size: 10px;
    text-align: center;
    vertical-align: middle;
  }

  .td-default {
    font-size: 13px;
  }

  .td-name {
    min-width: 184px;
  }
  .td-fecha {
    min-width: 119px;
  }
`;

const Table = ({
  ths,
  items,
  keys,
  containerThead,
  colorTitle,
  colorHeader,
  borderRight,
  borderTop
}) => {
  
  return (
    <MyTable colorTitle={colorTitle} colorHeader={colorHeader} borderTop={borderTop}
    borderRight={borderRight}>
      {containerThead}
      <RSTable responsive>
        <thead>
          <tr>
            <th></th>
            {ths.map((item) => {
              return (
                <th
                  className={
                    item === "Nombre"
                      ? "td-name"
                      : (item === "Fecha" || item === "Cafetería") && "td-fecha"
                  }
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {items.map((item, i) => {
            return (
              <tr>
                <td className="td-pk">{item.pk ? item.pk : i+1}</td>
                {keys.map((value) => {
                  return <td className="td-default">{item[value]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </RSTable>
    </MyTable>
  );
};

export default Table;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as settlementActions from "./reducer/settlementActions";
import { StyledComponents, Dropdown, Assets } from "../components-config";
import { useInput } from "../components-config/hooks";

// Encabezados de conceptos, fechas y acciones, ir a documentos,
// autorizar finiquito y recalcular.
export const HeaderConcepts = () => {
  const settlementReducer = useSelector((state) => state.settlementReducer);
  const { authorize_settlement } = settlementReducer;
  const select_authorize = [
    { id: 0, name: "Por autorizar" },
    { id: 1, name: "Autorizar" },
  ];
  return (
    <>
      <div>
        <div>
          <StyledComponents.SpecialContainer className="d-flex">
            <StyledComponents.MyP
              fontSize="12px"
              className="text-purple40 d-flex justify-content-between"
              margin="0 20px 0 0"
              width="170px"
            >
              <span>Fecha de Ingreso:</span>
              <span className="font-weight-bold">11/02/2021</span>
            </StyledComponents.MyP>
            <StyledComponents.MyP
              fontSize="12px"
              className="text-purple40 d-flex justify-content-between"
              margin="0"
              width="199px"
            >
              <span>Salario Diario:</span>
              <span className="font-weight-bold">$1,666,66</span>
            </StyledComponents.MyP>
          </StyledComponents.SpecialContainer>
          <div className="d-flex">
            <StyledComponents.MyP
              fontSize="12px"
              margin="0 20px 0 0"
              className="text-purple40 d-flex justify-content-between"
              width="170px"
            >
              Fecha de Baja:
              <span className="font-weight-bold">09/04/2021</span>
            </StyledComponents.MyP>
            <StyledComponents.MyP
              fontSize="12px"
              className="text-purple40 d-flex justify-content-between"
              margin="0"
              width="199px"
            >
              Salario Diario Integrado:
              <span className="font-weight-bold">$1,666.66</span>
            </StyledComponents.MyP>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <StyledComponents.SpecialContainer margin="0 20px 0 0">
          <StyledComponents.MyButton
            as="a"
            href="./dashboard-settlements/:idSettlement"
            className="btn-open-documents"
          >
            Abrir documentos{" "}
            <span className="ml10">
              <Assets.IconClip />
            </span>
          </StyledComponents.MyButton>
        </StyledComponents.SpecialContainer>

        <StyledComponents.SpecialContainer
          width="180px"
          margin="0 20px 0 0"
          
        >
          <Dropdown.Select
            maxWidthToggle={"180px"}
            reducer={"settlementReducer"}
            reducerValue="authorize_settlement"
            items={select_authorize}
            // setValue={setValue}
            selectClass={`${
              authorize_settlement.id !== 0
                ? "btn-authorize-active"
                : "btn-authorize"
            } `}
            colorArrow="var(--purple40)"
            changeState={settlementActions.changeState}
            widthArrow="10"
            heightArrow="6"
            colorLi="var(--grey80)"
            borderLi="none"
            boxShadow={"0 3px 6px 0 rgba(0, 0, 0, 0.16)"}
            dropdownTop="38px"
            paddingLi={"7.5px 15px"}
          />
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer>
          <StyledComponents.MyButton
            width="131px"
            className="btn-re-settlement"
          >
            Re-Calcular
            <span className="ml10">
              <Assets.IconUpdate color="var(--purple50)" />
            </span>
          </StyledComponents.MyButton>
        </StyledComponents.SpecialContainer>
      </div>
    </>
  );
};

// Input para actualizar la cantidad en conceptos
export const InputAmountSettlement = ({ idP, value }) => {
  const settlementReducer = useSelector((state) => state.settlementReducer);
  const { list_concepts } = settlementReducer;
  const dispatch = useDispatch();
  const amount = useInput.InputValue("", "", "", value);
  const inputChange = (e) => {
    list_concepts[idP].amount = e.target.value;
    list_concepts[idP].updated = "true";
    dispatch(settlementActions.changeState(`list_concepts`, list_concepts));
    //settlementReducer.list_concepts[idP].amount = e.target.value
    amount.inputHandleChange(e);
  };
  return (
    <StyledComponents.MyInput
      type="number"
      placeholder="0"
      width="60px"
      margin="0"
      className="input-settlement-amount"
      value={amount.inputValue}
      onChange={(e) => inputChange(e)}
    />
  );
};

export const DeleteConcept = ({ idP }) => {
  const settlementReducer = useSelector((state) => state.settlementReducer);
  const { list_concepts } = settlementReducer;
  const dispatch = useDispatch();
  const deleteConcept = () => {
    list_concepts.splice(idP, 1);
    dispatch(settlementActions.changeState(`list_concepts`, list_concepts));
  };
  return (
    <>
      <StyledComponents.SpecialContainer
        margin="0 0 0 5px"
        padding="2px 0 0 0"
        className="d-flex align-items-center pointer"
        onClick={deleteConcept}
      >
        Eliminar
      </StyledComponents.SpecialContainer>
    </>
  );
};

export const AddConcepts = () => {
  return <>Lista de conceptos</>;
};

import { combineReducers } from "redux";

import ui from "./ui";
import options from "./options";
import filters from "./filters";

export default combineReducers({
  ui: ui,
  options: options,
  filters: filters,
});

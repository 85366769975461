import React from "react";
import { CFDIs, CFDIFile } from "../../components-cfdis";
import { CFDIStampBalance } from "../../components-stamp-balances";


export const CFDIsModule = () => {
  return (
    <>
      <CFDIs></CFDIs>
    </>
  );
};

export const CFDIsFileModule = () => {
  return <CFDIFile />;
};


export const CFDIStampBalanceModule = () => {
  return (
    <>
      <CFDIStampBalance></CFDIStampBalance>
    </>
  );
};

// Librerias.
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Modal } from "reactstrap";

// Components
import {
  StyledComponents,
  Dropdown,
  Assets,
  Table,
  Modals,
} from "../components-config";
import * as incidentsActions from "./reducer/incidentsActions";

export const CardGroups = styled.div`
  background: var(--grey130);
  width: 200px;
  border-radius: 10px;
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 10px 8px 9px;
    color: black;
  }
  li:hover {
    background-color: var(--grey90);
    //border-radius: 10px;
  }
  li:first-child {
    border-radius: ${(props) =>
    props.borderRadius ? `${props.borderRadius}px ${props.borderRadius}px 0 0 ` : "10px 10px 0 0"};
  }
  

  li:last-child {
    border: none;
    border-radius: ${(props) =>
    props.borderRadius ? ` 0 0 ${props.borderRadius}px ${props.borderRadius}px` : "0 0 10px 10px"};
  }
  li:only-child {
    border-radius: ${(props) =>
    props.borderRadius ? `${props.borderRadius}px ` : "10px"};
  }
`;

export const SelectTypePayroll = ({ dropdownTop, group }) => {
  const integrityReducer = useSelector((state) => state.integrityReducer);
  const { company_selected } = integrityReducer;
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [value, setValue] = useState("Selecciona una opción");
  const changeStateSelect = (item) => {
    setValue(item.name);
    toggle();
    dispatch(incidentsActions.changeState("type_payroll", item));
    dispatch(incidentsActions.changeState("payment_date", ""));
    dispatch(
      incidentsActions.reportsMethods(
        { company_selected: company_selected.id, code_payroll: item.code, periodicity: item.periodicity },
        "GetInfoPayroll"
      )
    );
  };
  return (
    <>
      <Dropdown.LayoutDropDown
        maxWidthToggle="224px"
        dropdownToggle={
          <StyledComponents.SpecialContainer
            margin="0 4px 0 0"
            className={`d-flex justify-content-between align-items-center bg-grey130`}
            width="220px"
            height="32px"
            padding="0 10px"
            borderRadius="40px"
          >
            <StyledComponents.MyP
              fontSize="12px"
              className=" text-black"
              margin="0"
            >
              {value}
            </StyledComponents.MyP>
            <span className="d-flex align-items-center">
              <Assets.IconArrows />
            </span>
          </StyledComponents.SpecialContainer>
        }
        dropdownOpen={dropdownOpen}
        toggle={toggle}
        margin={""}
      >
        <StyledComponents.DivToRelative top={dropdownTop} zIndex="100">
          <StyledComponents.OverFlowSelects height={"245px"}>
            <CardGroups>
              <ul>
                {group.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className="pointer"
                      onClick={() => changeStateSelect(item)}
                    >
                      {item.code} - {item.name}
                    </li>
                  );
                })}
              </ul>
            </CardGroups>
          </StyledComponents.OverFlowSelects>
        </StyledComponents.DivToRelative>
      </Dropdown.LayoutDropDown>
    </>
  );
};

export const ModalErrorIncidents = ({ className, active = true, items }) => {
  const [modal, setModal] = useState(active);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const toggleClean = () => {
    setModal(!modal);
    dispatch(incidentsActions.changeState("success_incidents", ""));
  };
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        backdrop="static"
        keyboard={modal}
      >
        <>
          <StyledComponents.SpecialContainer
            width="822px"
            margin="100px 0 25px 0"
            className="d-flex justify-content-center align-items-center "
          >
            <StyledComponents.MyP
              margin="0 20px 0 0"
              fontSize="60px"
              className="mr2 text-white"
            >
              ¡ERROR!
            </StyledComponents.MyP>
            <Assets.IconError
              color="var(--green30)"
              size="60"
            ></Assets.IconError>
          </StyledComponents.SpecialContainer>
          <StyledComponents.SpecialContainer
            width="822px"
            className="text-center"
          >
            <StyledComponents.SpecialContainer
              width="822px"
              margin="0 0 100px 0"
            >
              <StyledComponents.OverFlowSelects height="450px" bgColor="red">
                <Table
                  colorTitle="white"
                  colorHeader="transparent"
                  borderRight={"2px solid  rgba(75, 33, 146, 0.4)"}
                  borderTop={"2px solid  rgba(75, 33, 146, 0.4)"}
                  ths={["Hoja", "Celda", "Valor", "Error"]}
                  keys={["sheet", "cell", "value", "message"]}
                  items={items}
                />
              </StyledComponents.OverFlowSelects>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              margin="100px auto 0 auto"
              className="d-flex justify-content-center"
              onClick={toggleClean}
            >
              <Assets.IconBack
                colorArrow="#fff"
                colorText="text-white"
              ></Assets.IconBack>
            </StyledComponents.SpecialContainer>
          </StyledComponents.SpecialContainer>
        </>
      </Modal>
    </>
  );
};

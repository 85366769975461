import {
    SETTLEMENT_CARGANDO,
    SETTLEMENT_ERROR,
    SETTLEMENT_CLEAN_STATE,
    SETTLEMENT_CHANGE_STATE,
    GetSettlementUser,
    GetSettlementPeriod,
    GetSettlementList,
  } from "./settlementTypes";
  
  import { errorHandler } from "../../components-api/ConfigApi";
  
  export const settlementCleanState = () => (dispatch) => {
    dispatch({
      type: SETTLEMENT_CLEAN_STATE,
    });
  };
  
  export const changeState = (variable, value) => (dispatch) => {
    dispatch({
      type: SETTLEMENT_CHANGE_STATE,
      payload: {
        variable,
        value
      }
    })
  } 
  
  export const settlementMethods = (data, method) => async (dispatch) => {
    dispatch({
      type: SETTLEMENT_CARGANDO,
    });
    try {
      let answer;
      switch (method) {
        case "GetSettlementUser":
          answer = await GetSettlementUser(data);
          break;
        case "GetSettlementPeriod":
          answer = await GetSettlementPeriod(data);
          break;
        case "GetSettlementList":
          answer = await GetSettlementList();
          break;
        default:
          break;
      }
  
      dispatch({
        type: answer.type,
        payload: answer.res.data,
      });
    } catch (error) {
      let messageError = errorHandler(error);
      dispatch({
        type: SETTLEMENT_ERROR,
        payload: messageError,
      });
    }
  };
  
import {
  FILTER_CLEAN_ALL,
  FILTER_CONCEPT_ADDED,
  FILTER_CONCEPT_CLEANED,
  FILTER_CONCEPT_REMOVED,
  FILTER_DATE_FROM_CHANGED,
  FILTER_DATE_TO_CHANGED,
  FILTER_PAYGROUP_ADDED,
  FILTER_PAYGROUP_REMOVED,
  FILTER_STRUCTUREOPTION_ADDED,
  FILTER_STRUCTUREOPTION_CLEANED,
  FILTER_STRUCTUREOPTION_EMPLOYEE_CHANGE,
  FILTER_STRUCTUREOPTION_REMOVED,
  FILTER_STRUCTURETYPE_CHANGE,
  INCIDENCE_TYPE_FILTER_CHANGE,
} from "../actions/actionTypeKeys";

export const initialState = {
  incidenceType: null,
  payGroups: [],
  structureType: null,
  concepts: [],
  dateFrom: null,
  dateTo: null,
  structureTypeOptions: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {

    case FILTER_CLEAN_ALL:
      return initialState;

    case INCIDENCE_TYPE_FILTER_CHANGE:
      return {
        ...state,
        incidenceType: action.payload,
      };

    case FILTER_PAYGROUP_ADDED:
      return {
        ...state,
        payGroups: [...state.payGroups, action.payload],
      };

    case FILTER_PAYGROUP_REMOVED:
      return {
        ...state,
        payGroups: state.payGroups.filter((item) => item !== action.payload),
      };

    case FILTER_STRUCTURETYPE_CHANGE:
      return {
        ...state,
        structureType: action.payload,
      };

    case FILTER_CONCEPT_CLEANED:
      return {
        ...state,
        concepts: [],
      };

    case FILTER_CONCEPT_ADDED:
      return {
        ...state,
        concepts: [...state.concepts, action.payload],
      };

    case FILTER_CONCEPT_REMOVED:
      return {
        ...state,
        concepts: state.concepts.filter((item) => item !== action.payload),
      };

    // case FILTER_CONCEPT_BY_GROUP_ADDED:
    //   const ids = action.payload.map(obj => obj.id);
    //   return {
    //     ...state,
    //     concepts: ids,
    //   };

    // case FILTER_CONCEPT_BY_GROUP_REMOVED:
    //   return {
    //     ...state,
    //     concepts: state.concepts.filter((item) => item !== action.payload),
    //   };

    case FILTER_STRUCTUREOPTION_CLEANED:
      return {
        ...state,
        structureTypeOptions: [],
      };

    case FILTER_STRUCTUREOPTION_ADDED:
      return {
        ...state,
        structureTypeOptions: [...state.structureTypeOptions, action.payload],
      };

    case FILTER_STRUCTUREOPTION_REMOVED:
      return {
        ...state,
        structureTypeOptions: state.structureTypeOptions.filter((item) => item !== action.payload),
      };

    case FILTER_STRUCTUREOPTION_EMPLOYEE_CHANGE:
      const values = (action.payload === "") ? [] : String(action.payload).split(",").map(Number);
      return {
        ...state,
        structureTypeOptions: values,
      };

    case FILTER_DATE_FROM_CHANGED:
      return {
        ...state,
        dateFrom: action.payload,
      };

    case FILTER_DATE_TO_CHANGED:
      return {
        ...state,
        dateTo: action.payload,
      };

    default:
      return state;
  }
}

export default reducer;

//Falta conectar Reducer
import {
  INCIDENTS_CARGANDO,
  INCIDENTS_ERROR,
  INCIDENTS_CLEAN_STATE,
  INCIDENTS_CHANGE_STATE,
  INCIDENTS_LAYOUT,
  INCIDENTS_SEND,
  INCIDENTS_TRANSFER,
  INCIDENTS_KRONOS_NAME,
  INCIDENTS_PAYROLL_LIST,
  INCIDENTS_PAYROLL_PERIOD,
} from "./incidentsTypes";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    message_loading: "",
    cargando_payrolls: false,
    error: "",
    success: false,
  },
  file: {},
  type_payroll: {},
  list_payrolls: [],
  payment_date: "",
  period: {
    period: "00",
    date_period: "",
    date_period_until: "",
    date_period_payment: "",
  },
  kronos_name: "",
  is_transfer_kronos_file: "",
  success_incidents: false,
};

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INCIDENTS_CARGANDO:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          cargando_payrolls: true,
          error: "",
          success: false,
        },
      };
    case INCIDENTS_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          message_loading: "",
          cargando_payrolls: false,
          error: action.payload,
          success: false,
        },
      };

    case INCIDENTS_CLEAN_STATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          cargando_payrolls: false,
          error: "",
          message_loading: "",
          success: false,
        },
        file: {},
        type_payroll: {},
        list_payrolls: [],
        payment_date: "",
        period: {
          period: "00",
          date_period: "",
          date_period_until: "",
          date_period_payment: "",
        },
        kronos_name: "",
        is_transfer_kronos_file: "",
        success_incidents:false
      };

    case INCIDENTS_SEND:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          cargando_payrolls: false,

          error: "",
          success: false,
        },
        success_incidents: action.payload,
      };

    case INCIDENTS_LAYOUT:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          cargando_payrolls: false,

          error: "",
          success: false,
        },
      };
    case INCIDENTS_PAYROLL_LIST:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          cargando_payrolls: false,
          error: "",
          success: false,
        },
        list_payrolls: action.payload,
      };
    case INCIDENTS_TRANSFER:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          message_loading: "",
          cargando_payrolls: false,
          error: "",
          success: true,
        },
      };

    case INCIDENTS_KRONOS_NAME:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          message_loading: "",
          error: "",
          success: false,
        },
        kronos_name: action.payload.filename,
        is_transfer_kronos_file: action.payload.is_exist,
      };
    case INCIDENTS_PAYROLL_PERIOD:
      let period;
      if (action.payload.period) {
        period = action.payload;
        if (state.type_payroll.periodicity === 0) {
          period.date_period_payment = "";
          period.period =
            period.period >= 9
              ? Number(period.period) + 1
              : "0" + (Number(period.period) + 1);
        }
      } else {
        period = {
          period: "01",
          date_period: "",
          date_period_until: "",
          date_period_payment: "",
        };
      }
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          cargando_payrolls: false,
          error: "",
        },
        period: period,
        payment_date: period.date_period_payment,
      };
    case INCIDENTS_CHANGE_STATE:
      return {
        ...state,
        [action.payload.variable]: action.payload.value,
      };

    default:
      return { ...state };
  }
};

export default Reducer;

import {
  DOWNLOAD_CARGANDO,
  DOWNLOAD_ERROR,
  DOWNLOAD_CLEAN_STATE,
  DOWNLOAD_CHANGE_STATE,
  download,
} from "./downloadTypes";

import { errorHandler } from "../../components-api/ConfigApi";

export const downloadCleanState = () => (dispatch) => {
  dispatch({
    type: DOWNLOAD_CLEAN_STATE,
  });
};

export const changeState = (variable, value) => (dispatch) => {
  dispatch({
    type: DOWNLOAD_CHANGE_STATE,
    payload: {
      variable,
      value,
    },
  });
};

export const donwloadMethods = (data) => async (dispatch) => {
  dispatch({
    type: DOWNLOAD_CARGANDO,
  });
  try {
    //   let dataDonwload = parserJson(data.data);

    let answer = await download(data.uri, data.data);
    dispatch({
      type: answer.type,
      payload: answer.res.data,
    });
  } catch (error) {
    let messageError = errorHandler(error);
    dispatch({
      type: DOWNLOAD_ERROR,
      payload: messageError,
    });
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Assets, Dropdown, StyledComponents } from "../components-config";
import { changeFilterConcept, changeFilterConceptByGroup } from "./actions/actions";
import CheckBox from "./CheckBox";
import {
  selectFilterConceptCount, selectFilterConcepts,
  selectFilterConceptsNotEmpty
} from "./selectors/filters";
import { selectConceptFilteredByGroup, selectConceptList } from "./selectors/options";
import { CardConceptStyle } from "./styleComponets";

/**
 *
 * @param {number} lavel Lavel concept
 * @returns object style
 */
function getLavelStyle(lavel) {
  const padding = !lavel ? 10 : 10 + lavel * 10;
  const style = { paddingLeft: `${padding}px` };
  return style;
}

const ItemWithouCheckbox = ({ id, class_concept, group, subgroup, description, lavel }) => {
  const dispatch = useDispatch();

  const groupKey = {
    class_concept:class_concept,
    group: group,
    subgroup: subgroup,
  }

  const optionsChecked = useSelector(selectFilterConcepts);
  const filtered = useSelector((state) => selectConceptFilteredByGroup(state, groupKey));
  const filteredIds = filtered.map(obj => obj.id);
  const isCheck = isCheckedAll()
  const liStyle = getLavelStyle(lavel);

  function isCheckedAll() {
    return optionsChecked.filter(function(item) {
      return filteredIds.includes(item);
    }).length;
  }

  function handlerChange(checked) {
    dispatch(changeFilterConceptByGroup(checked, groupKey));
  }

  return (
    <li key={id} style={liStyle}>
      {description}
      <StyledComponents.SpecialContainer margin="0 0 0 3px">
        <CheckBox onChange={handlerChange} status={isCheck}></CheckBox>
      </StyledComponents.SpecialContainer>
    </li>
  );
};

const ItemWithCheckbox = ({ code, description, lavel, isCheck}) => {
  const dispatch = useDispatch();

  const liStyle = getLavelStyle(lavel);

  function handlerChange() {
    dispatch(changeFilterConcept(code));
  }

  return (
    <li key={`${code}-${isCheck}`} style={liStyle}>
      {description} ({code})
      <StyledComponents.SpecialContainer margin="0 0 0 3px">
        <CheckBox onChange={handlerChange} status={isCheck}></CheckBox>
      </StyledComponents.SpecialContainer>
    </li>
  );
};

const Item = ({ item }) => {

  const [isCheck, setIsCheck] = useState(false);
  const optionsChecked = useSelector(selectFilterConcepts);

  useEffect(() => {
    setIsCheck(optionsChecked.some((code) => code === item.code));
  }, [optionsChecked]);


  if (item.code == null) {
    return <ItemWithouCheckbox {...item} />;
  } else {
    return <ItemWithCheckbox {...item} isCheck={isCheck} />;
  }
};

const List = ({ options }) => {
  const data = options.sort((a, b) => a.description.localeCompare(b.description));

  return (
    <ul>
      {data.map((item) => (
        <Item key={`${item.lavel}-${item.id}`} item={item} />
      ))}
    </ul>
  );
};

const OptionConcept = (isCheck) => {
  const options = useSelector(selectConceptList);
  const selectedGreaterThatOne = useSelector(selectFilterConceptsNotEmpty);
  const selectedCount = useSelector(selectFilterConceptCount);

  return (
    <>
      <Dropdown.SelectConcepts
        dropdownTop="32px"
        dropdownToggle={
          <StyledComponents.SpecialContainer
            margin="0 4px 0 0"
            className={`d-flex align-items-center justify-content-between bg-purple90 ${
              !isCheck ? "disabled" : ""
            }`}
            width="220px"
            minWidth="220px"
            height="32px"
            padding="0 12px"
            borderRadius="4px">
            <StyledComponents.MyP fontSize="12px" className="font-weight-bold" margin="0">
              {selectedGreaterThatOne ? `Seleccionados (${selectedCount})` : "Elija una opción..."}
            </StyledComponents.MyP>
            <span className="d-flex align-items-center">
              <Assets.IconArrows />
            </span>
          </StyledComponents.SpecialContainer>
        }>
        <StyledComponents.SpecialContainer padding="0 4px 0 0">
          <StyledComponents.OverFlowSelects
            borderRadius="5px"
            height={"270px"}
            bgColor="var(--purple100)">
            <CardConceptStyle>
              <List options={options}></List>
            </CardConceptStyle>
          </StyledComponents.OverFlowSelects>
        </StyledComponents.SpecialContainer>
      </Dropdown.SelectConcepts>
    </>
  );
};

export default OptionConcept;

import axios from 'axios';

const instance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_ROUTE_REPORT_QUERY;

if (process.env.NODE_ENV === 'development') {
  // Config...
}

export default instance;

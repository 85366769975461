import React from "react";

import { Assets, StyledComponents } from "../../../components-config";
import { Label, Input } from "../components/base";

const HelperText = ({ text }) => {
  if(!text) return '';
  return (
    <>
      <StyledComponents.SpecialContainer className="d-flex">
        <div className="d-flex align-items-center">
          <span className="mr5">
            <Assets.IconInfo />
          </span>
          <StyledComponents.MyP margin="none" fontSize={"14px"}>
            {text}
          </StyledComponents.MyP>
        </div>
      </StyledComponents.SpecialContainer>
    </>
  );
};

const ErrorText = ({ text }) => {
  return (
    <>
      <p className="font-weight-light text-danger">{text}</p>
    </>
  );
};

const TextField = ({ label, type, name, placeholder, defaultValue, disabled, helperText, error, onChange }) => {

  return (
    <>
      <StyledComponents.SpecialContainer margin="8px 0 14px 0" className="font-weight-bold">
        <Label name={name} text={label}>
          {label}
        </Label>
      </StyledComponents.SpecialContainer>
      <Input
        onChange={onChange}
        type={type}
        name={name}
        value={defaultValue}
        isDisabled={disabled}
        placeholder={placeholder}
        fontSize="12px"
        borderRadius="4px"
        height={"32px"}
        className={"bg-grey130 font-weight-bold " + (error ? "input-error" : "")}
      />
      <ErrorText text={error} />
      <HelperText text={helperText} />
    </>
  );
};

const NumberField = TextField;

export { TextField, NumberField };

import {
  EMAIL_CARGANDO,
  EMAIL_ERROR,
  EMAIL_CLEAN_STATE,
  SendEmail,
} from "./emailTypes";

import { errorHandler } from "../../components-api/ConfigApi";

const parserJson = (obj) => {
  var jsonSerialize = "";
  for (const prop in obj) {
    jsonSerialize += `${prop}=${prop === "paychecks" ? "[" : ""}${obj[prop]}${
      prop === "paychecks" ? "]" : ""
    }&`;
  }
  jsonSerialize = jsonSerialize.slice(0, -1);
  return jsonSerialize;
};

export const emailCleanState = () => (dispatch) => {
  dispatch({
    type: EMAIL_CLEAN_STATE,
  });
};

export const emailMethods = (data) => async (dispatch) => {
  dispatch({
    type: EMAIL_CARGANDO,
  });
  try {
    
    let answer = await SendEmail(data.uri, data);

    dispatch({
      type: answer.type,
      payload: answer.res.data,
    });
  } catch (error) {
    let messageError = errorHandler(error);
    dispatch({
      type: EMAIL_ERROR,
      payload: messageError,
    });
  }
};

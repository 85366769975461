import React from "react";
import { Container } from "reactstrap";
import { SelectCompaniesUser } from "../../components-integrity";
import { StyledComponents } from "../../components-config";

export const Dashboard = () => {
  return (
    <>
      <SelectCompaniesUser />
    </>
  );
};

import { axios_api } from "../../components-api/ConfigApi";

export const CFDIS_CARGANDO = "CFDIS_CARGANDO";
export const CFDIS_ERROR = "CFDIS_ERROR";
export const CFDIS_CLEAN_STATE = "CFDIS_CLEAN_STATE";
export const CFDIS_CHANGE_STATE = "CFDIS_CHANGE_STATE";
export const CFDIS_LIST = "CFDIS_LIST";
export const CFDIS_TRANSFER = "CFDIS_TRANSFER";

export const GetCfdisList = async (data) => {
  const answer = await axios_api(`/payroll/receipts/${data}`, "company", "get");
  return { res: answer, type: CFDIS_LIST };
};

export const CfdisTransfer = async (data) => {
  const answer = await axios_api(
    `/integrity/cfdis/transfer_sap/`,
    "company",
    "post",
    data,
    10000000
  );
  return { res: answer, type: CFDIS_TRANSFER };
};

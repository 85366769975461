// Librerias.
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Collapse } from "reactstrap";

// Components Base.
import {
    Layouts,
    StyledComponents,
    Assets,
    Modals,
} from "../components-config";

// Components.
import {
    CFDIsInformation,
    CheckAllCFDIs,
    CheckCFDIs,
    Pagination,
} from "./CFDIStampBalanceComponents";

// Actions.
import * as cfdiStampBalanceActions from "./reducer/CFDIStampBalanceActions";

// Integrity.
import { RedirectSelects } from "../components-integrity";

// Filter.
export const CFDIStampBalanceFilter = () => {
  const dispatch = useDispatch();
  // Collapse.
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
    isOpen
    ?
    dispatch(cfdiStampBalanceActions.changeState("overflowY", "200px")) :
    dispatch(cfdiStampBalanceActions.changeState("overflowY", "400px"));
  };

  const filterData = () => {
    const urlFilter = ``;
    dispatch(cfdiStampBalanceActions.cfdiStampBalanceMethods(urlFilter, "GetStampBalances"));
    dispatch(cfdiStampBalanceActions.changeState("list_selected", []));
  };

  return (
    <div>
      <Collapse isOpen={!isOpen}>
        <Layouts.Card
          margin="4px 0 0 0"
          padding="20px"
          height="100px"
          borderRadius="0 0 20px 20px"
          className="text-purple40 "
        >
          <StyledComponents.SpecialContainer
            margin="20px 0 0 0"
            className="d-flex justify-content-between"
          >
            <div>
              <StyledComponents.MyButton
                className="btn-hidden"
                onClick={toggle}
              >
                <span className="mr5">
                  <Assets.IconSelectArrow
                    color="var(--purple40)"
                    toggle={true}
                    width="12"
                  />
                </span>
                Ocultar
              </StyledComponents.MyButton>
            </div>
            <div>
              <StyledComponents.MyButton
                className="btn-search2 font-weight-bold"
                onClick={filterData}
              >
                <span className="mr5">
                  <Assets.IconSearch size="12" />
                </span>{" "}
                Buscar
              </StyledComponents.MyButton>
            </div>
          </StyledComponents.SpecialContainer>
        </Layouts.Card>
      </Collapse>
      {isOpen && (
        <Container>
          <StyledComponents.SpecialContainer padding="20px" width="140px">
            <StyledComponents.MyButton className="btn-hidden" onClick={toggle}>
              <span className="mr5">
                <Assets.IconSelectArrow
                  color="var(--purple40)"
                  toggle={false}
                  width="12"
                />
              </span>
              Filtro
            </StyledComponents.MyButton>
          </StyledComponents.SpecialContainer>
        </Container>
      )}
    </div>
  );
};

// CFDIs Search.
export const CFDIStampBalanceSearch = () => {
  const balanceReducer = useSelector((state) => state.cfdiStampBalanceReducer);
  const {
    list_data,
    list_selected,
  } = balanceReducer;

  return (
    <>
      <Container>
        <StyledComponents.SpecialContainer
          borderRadius="4px 4px 0 0"
          width="180px"
          className="font-weight-bold"
          fontSize="13px"
          background="var(--grey10)"
          padding="8px 22px 8px 10px"
        >
          Resultado de búsqueda:
        </StyledComponents.SpecialContainer>
      </Container>
      <Layouts.Card borderRadius="0 20px 0 0">
        <StyledComponents.SpecialContainer padding="20px 20px 8px 20px">
          <div className="d-flex justify-content-between">
            <StyledComponents.SpecialContainer
              fontSize="12px"
              fontStyle="italic"
              className="d-flex"
            >
              <div>
                Registros:{" "}
                <span className="font-weight-bold ml10">
                  {list_data.length}
                </span>{" "}
              </div>
              <StyledComponents.SpecialContainer margin="0 0 0 30px">
                Seleccionados:{" "}
                <span className="font-weight-bold ml10">
                  {list_selected.length}
                </span>
              </StyledComponents.SpecialContainer>

            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              margin="0 40px 0 26px"
              className="d-flex align-items-center"
            >
            </StyledComponents.SpecialContainer>
          </div>
          <StyledComponents.SpecialContainer
            className="d-flex font-weight-bold"
            margin=" 13px 0 0 0"
          >
            <StyledComponents.SpecialContainer
              width="34px"
              margin="0 2px 0 0"
            ></StyledComponents.SpecialContainer>
            <div>
              <StyledComponents.SpecialContainer
                padding="8px 21px 0 1px"
                margin="0 2px 0 0"
              >
                <CheckAllCFDIs className="checked-border" />
              </StyledComponents.SpecialContainer>
            </div>
            <StyledComponents.SpecialContainer
              width="280px"
              padding="10px 5px 2px 0px"
              margin="0 2px 0 0"
            >
              Plan
            </StyledComponents.SpecialContainer>
            
            <StyledComponents.SpecialContainer
              width="190px"
              padding="10px 5px 2px 0px"
              margin="0 2px 0 0"
            >
              Créditos Asignados
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              width="190px"
              padding="10px 5px 2px 0px"
              margin="0 2px 0 0"
            >
              Créditos Usados
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              width="190px"
              padding="10px 5px 2px 0px"
              margin="0 2px 0 0"
            >
              Créditos Restantes
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              width="190px"
              padding="10px 5px 2px 0px"
              margin="0 2px 0 0"
            >
              Fecha Inicio Plan
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              width="180px"
              padding="10px 5px 2px 0px"
              margin="0 2px 0 0"
            >
              Fecha Fin Plan
            </StyledComponents.SpecialContainer>
          </StyledComponents.SpecialContainer>
        </StyledComponents.SpecialContainer>
      </Layouts.Card>
    </>
  );
};

export const CFDIStampBalance = () => {
  const balanceReducer = useSelector((state) => state.cfdiStampBalanceReducer);
  const {
    api_actions: { cargando, error },
    list_data
  } = balanceReducer;
  return (
    <>
      {cargando && <Modals.ModalLoading />}
      {cargando}
      <CFDIsInformation />
      <CFDIStampBalanceFilter />
      <Layouts.Border margin="30px 0" border="1px dashed var(--purple40)" />
      <CFDIStampBalanceSearch />
      
      {list_data.length > 0 ? (
        <>
          <CheckCFDIs />
        </>
      ) : error ? (
        <Container>
          <div className="d-flex justify-content-center mt-5 text-danger font-weight-bold">
            No se encontro ningun registro con el filtro establecido
          </div>
        </Container>
      ) : (
        ""
      )}
    </>
  );
};
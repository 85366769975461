//Falta conectar Reducer
import {
  CFDIS_CARGANDO,
  CFDIS_ERROR,
  CFDIS_CLEAN_STATE,
  CFDIS_CHANGE_STATE,
  CFDIS_LIST,
  CFDIS_TRANSFER
} from "./cfdisTypes";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    message_loading: "",
    cargando_payrolls: false,
    error: "",
    success: false,
  },
  show_in: 10,
  list_page_cfdis: [],
  list_cfdis: [],
  list_cfdis_selected: [],
  // Inputs
  payroll_type: { id: 0, name: "Seleccionar una opción..." },
  year: { id: 2021, name: 2021 },
  period: { id: '', name: '--Todos--' },
  employee_type: { id: 0, name: "--Todos--" },
  employee_status: { id: 0, name: "--Todos--" },
  employee_numbers: '',
  uuids: '',
  cfdis_action_success: '',
  overflowY: '200px',
  total_net: 0
};

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CFDIS_CARGANDO:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          error: "",
        },
      };
    case CFDIS_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: action.payload,
        },
        list_page_cfdis: [],
        list_cfdis: [],
        list_cfdis_selected: [],
      };

    case CFDIS_CLEAN_STATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        show_in: 10,
        list_page_cfdis: [],
        list_cfdis: [],
        list_cfdis_selected: [],
        // Inputs
        payroll_type: { id: 0, name: "Seleccionar una opción..." },
        year: { id: 2021, name: 2021 },
        period: { id: '', name: '--Todos--' },
        employee_type: { id: 0, name: "--Todos--" },
        employee_status: { id: 0, name: "--Todos--" },
        employee_numbers: '',
        uuids: '',
        cfdis_action_success: '',
        total_net: 0
      };
    case CFDIS_CHANGE_STATE:
      return {
        ...state,
        [action.payload.variable]: action.payload.value,
      };
    case CFDIS_LIST:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        list_cfdis: action.payload.data,
        total_net: action.payload.totalNet,
        list_page_cfdis: action.payload.data.slice(0, 500),
      };
    case CFDIS_TRANSFER:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        cfdis_action_success: 'Transferencia de UUIDs Exitosa.'
      }
    default:
      return { ...state };
  }
};

export default Reducer;

import { axios_api, parserJson } from "../../components-api/ConfigApi";

export const INTEGRITY_CARGANDO = "INTEGRITY_CARGANDO";
export const INTEGRITY_ERROR = "INTEGRITY_ERROR";
export const INTEGRITY_CLEAN_STATE = "INTEGRITY_CLEAN_STATE";
export const INTEGRITY_COMPANIES_LIST = "INTEGRITY_COMPANIES_LIST";
export const INTEGRITY_MODULES_LIST = "INTEGRITY_MODULES_LIST";
export const INTEGRITY_SUB_MODULES_LIST = "INTEGRITY_SUB_MODULES_LIST";
export const INTEGRITY_CHANGE_STATE = "INTEGRITY_CHANGE_STATE";


export const GetCompanies = async () => {
  const answer = await axios_api(
    `/integrity/user_companies/`,
    true,
    "get"
  );
  return { res: answer, type: INTEGRITY_COMPANIES_LIST };
};
export const GetModules = async (data) => {
  const answer = await axios_api(
    `/integrity/user_modules/?client=${data.company}`,
    true,
    "get"
  );
  return { res: answer, type: INTEGRITY_MODULES_LIST };
};
export const GetSubModules = async (data) => {
  const answer = await axios_api(
    `/integrity/user_submodules/?module=${data.module}`,
    true,
    "get",
  );
  return { res: answer, type: INTEGRITY_SUB_MODULES_LIST };
};



import { createSlice } from "@reduxjs/toolkit";

const ACTION_DOMAIN = "form/request";

const slice = createSlice({
  name: `${ACTION_DOMAIN}`,
  initialState: {
    formEdit: {
      selectedId: null,
    },
  },
  reducers: {},
});

export default slice.reducer;

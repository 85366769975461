import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { Assets, Cards, Modals, StyledComponents } from "../components-config";
import { useInput } from "../components-config/hooks";
import * as emailActions from "./reducer/emailActions";
export const SendEmail = ({
  children,
  description,
  uri,
  description2,
  modelToSend = {},
  cleanReducer,
  isActive,
}) => {
  const anotherEmail = useInput.InputValue();

  const emailReducer = useSelector((state) => state.emailReducer);
  const {
    send_email,
    api_actions: { cargando, error },
  } = emailReducer;

  const dispatch = useDispatch();

  let [chooseModal, setChooseModal] = useState(1);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    modal && cleanReducer && cleanReducer();
    setModal(!modal && isActive);
    setChooseModal(1);
  };

  const sendMySelf = (email) => {
    modelToSend.to_email = email ? email : localStorage.getItem("email");
    modelToSend.to_emails = email ? email : localStorage.getItem("email");
    dispatch(emailActions.emailMethods({ uri, model: modelToSend }));
    //setiar correo a succesfull
  };

  useEffect(() => {
    if (send_email && error === "") {
      dispatch(emailActions.emailCleanState());
      setChooseModal(3);
    } else if (error !== "") {
      console.log("Error de envio: ", error);
      setChooseModal(4);
    }
  }, [send_email, error, dispatch]);

  const sendToAnother = () => {
    setChooseModal(2);
  };

  const showModal = (chooseModal) => {
    switch (chooseModal) {
      case 1:
        return (
          <>
            <StyledComponents.SpecialContainer
              margin="200px auto 0 auto"
              className="d-flex justify-content-center"
              padding="0 15px">
              <Cards.MainCardLayout description={description} typeCard="card-yellow">
                <StyledComponents.MyButton
                  maxWidth="260px"
                  margin="0 auto 30px auto"
                  className="btn-green2 font-weight-bold text-white"
                  onClick={() => sendMySelf("", uri)}>
                  Enviar Correo
                </StyledComponents.MyButton>
                <StyledComponents.MyButton
                  maxWidth="260px"
                  className="bg-purple60 text-white font-weight-bold"
                  onClick={sendToAnother}>
                  Enviar a otra dirección
                </StyledComponents.MyButton>
              </Cards.MainCardLayout>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              margin="200px auto 0 auto"
              className="d-flex justify-content-center"
              onClick={toggle}>
              <Assets.IconBack colorArrow="#fff" colorText="text-white"></Assets.IconBack>
            </StyledComponents.SpecialContainer>
          </>
        );
      case 2:
        return (
          <>
            <StyledComponents.SpecialContainer
              margin="200px auto 0 auto"
              className="d-flex justify-content-center">
              <Cards.MainCardLayout description={description2} typeCard="card-yellow">
                <StyledComponents.MyInput
                  maxWidth="260px"
                  placeholder="ejemplo@correo.com"
                  value={anotherEmail.inputValue}
                  onChange={(e) => anotherEmail.inputHandleChange(e)}
                  className={`${
                    anotherEmail.validate
                      ? "input-success"
                      : anotherEmail.validate === false
                      ? "input-error"
                      : ""
                  }`}></StyledComponents.MyInput>
                <StyledComponents.MyButton
                  maxWidth="260px"
                  margin="30px auto 0 auto"
                  className="btn-green2 font-weight-bold text-white"
                  onClick={() => sendMySelf(anotherEmail.inputValue, uri)}>
                  Enviar Correo
                </StyledComponents.MyButton>
              </Cards.MainCardLayout>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              margin="200px auto 0 auto"
              className="d-flex justify-content-center"
              onClick={() => setChooseModal(1)}>
              <Assets.IconBack colorArrow="#fff" colorText="text-white"></Assets.IconBack>
            </StyledComponents.SpecialContainer>
          </>
        );
      case 3:
        return (
          <>
            <StyledComponents.SpecialContainer
              margin="100px 0 25px 0"
              className="d-flex justify-content-center align-items-center ">
              <StyledComponents.MyP margin="0 20px 0 0" fontSize="60px" className="mr2 text-white">
                ¡Listo!
              </StyledComponents.MyP>
              <Assets.IconCheck color="var(--green30)" size="60"></Assets.IconCheck>
            </StyledComponents.SpecialContainer>
            <div className="text-center">
              <StyledComponents.SpecialContainer margin="0 0 100px 0">
                <StyledComponents.MyP fontSize={"20px "} className="text-white">
                  El correo se envio con éxito
                </StyledComponents.MyP>
              </StyledComponents.SpecialContainer>
              <StyledComponents.SpecialContainer
                margin="200px auto 0 auto"
                className="d-flex justify-content-center"
                onClick={toggle}>
                <Assets.IconBack colorArrow="#fff" colorText="text-white"></Assets.IconBack>
              </StyledComponents.SpecialContainer>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <StyledComponents.SpecialContainer
              margin="100px 0 25px 0"
              className="d-flex justify-content-center align-items-center ">
              <StyledComponents.MyP margin="0 20px 0 0" fontSize="60px" className="mr2 text-white">
                ¡Notificación!
              </StyledComponents.MyP>
              <Assets.IconInfo color="var(--yellow10)" width="60" height="60"></Assets.IconInfo>
            </StyledComponents.SpecialContainer>
            <div className="text-center">
              <StyledComponents.SpecialContainer margin="0 0 100px 0">
                <StyledComponents.MyP fontSize={"20px "} className="text-white">
                  {error}
                </StyledComponents.MyP>
              </StyledComponents.SpecialContainer>
              <StyledComponents.SpecialContainer
                margin="200px auto 0 auto"
                className="d-flex justify-content-center"
                onClick={toggle}>
                <Assets.IconBack colorArrow="#fff" colorText="text-white"></Assets.IconBack>
              </StyledComponents.SpecialContainer>
            </div>
          </>
        );
      default:
        return (
          <>
            <Cards.MainCardLayout description={description} typeCard="card-yellow">
              <StyledComponents.MyButton
                maxWidth="260px"
                margin="0 auto 30px auto"
                className="btn-green2 font-weight-bold text-white">
                Enviar Correo
              </StyledComponents.MyButton>
              <StyledComponents.MyButton
                maxWidth="260px"
                className="bg-purple60 text-white font-weight-bold">
                Enviar a otra dirección
              </StyledComponents.MyButton>
            </Cards.MainCardLayout>
            <StyledComponents.SpecialContainer
              margin="200px auto 0 auto"
              className="d-flex justify-content-center">
              <Assets.IconBack colorArrow="#fff" colorText="text-white"></Assets.IconBack>
            </StyledComponents.SpecialContainer>
          </>
        );
    }
  };

  if (cargando) {
    return <Modals.ModalLoading />;
  }

  return (
    <>
      <div onClick={toggle} className="pointer">
        {children}
      </div>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={modal}>
        {showModal(chooseModal)}
      </Modal>
    </>
  );
};

import axios from "axios";

const instance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_ROUTE;

const autorization = `Token ${localStorage.getItem("token")}`;
const companycode = localStorage.getItem('x-app-cocia');
instance.defaults.headers.common["Authorization"] = autorization;
instance.defaults.headers.common["x-app-cocia"] = companycode;

export default instance;
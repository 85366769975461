import { useState } from "react";

export const InputValue = (maxLenght = "", re, mandatory,value='') => {
  const [inputValue, setInputValue] = useState(value);
  const [validate, setValidate] = useState("");
  const inputHandleChange = (e) => {
    if (maxLenght) {
      if (e.target.value.length < maxLenght) {
        setInputValue(e.target.value);
      }
    } else {
      setInputValue(e.target.value);
    }
    validateInput(e.target.value, re);
  };



  const validateInput = (value, re) => {

    let isValidate;
    if (re) {
      isValidate = value.match(re) ? true : false;
    } else {
      isValidate = true;
    }

    setValidate(isValidate);
  };

  return { inputValue, inputHandleChange, validate, mandatory, setInputValue };
};

export const ValidateForm = (inputs) => {
  const isAllValidate = inputs.filter((value) => value.validate === false || (value.inputValue === '' && value.mandatory ));
  return isAllValidate.length > 0 ? false : true;
};

import React from "react";
import { Link } from "react-router-dom";
import { MainLayout } from "../";
import { Assets, StyledComponents, Cards } from "../../components-config";
import { useWindowSize } from "../../components-config/hooks";

import {
  Login,
  ForgotPassword,
  SendEmailPassword,
  ChangePasswordFromEmail,
  SuccessChangePasswordFromEmail,
} from "../../components-session";

export const LoginPage = () => {
  return (
    <Cards.MainCardLayout
      description={
        <p className="font-weight-bold">
          Integre y manipule fácilmente la información del cálculo de sus
          nóminas en un mismo lugar.
        </p>
      }
      typeCard="card-purple"
    >
      <p className="mb-0">
        Si tiene algún problema para acceder o no recuerda su contraseña,
        <Link to="/forgot-password" className="font-weight-bold">
          <u>Presione Aquí</u>
        </Link>{" "}
      </p>
      <Login redirect={"/dashboard"}></Login>
    </Cards.MainCardLayout>
  );
};

export const ForgotPasswordPage = () => {
  return (
    <MainLayout classContainer="">
      <StyledComponents.SpecialContainer margin="70px 0 130px 0">
        <Cards.MainCardLayout
          description={
            <p className="font-weight-bold">
              Solicite el envío de un enlace a su correo registrado como usuario
              para recuperar su contraseña…
            </p>
          }
          typeCard="card-yellow"
        >
          <ForgotPassword redirect="/send-email-password" />
        </Cards.MainCardLayout>
      </StyledComponents.SpecialContainer>

      <Link to="/">
        <Assets.IconBack></Assets.IconBack>
      </Link>
    </MainLayout>
  );
};

export const SendEmailPasswordPage = () => {
  const windowSize = useWindowSize();

  return (
    <>
      <MainLayout>
        <StyledComponents.SpecialContainer margin="100px 0 150px 0">
          <SendEmailPassword></SendEmailPassword>
        </StyledComponents.SpecialContainer>
        <Link to="/">
          <Assets.IconBack></Assets.IconBack>
        </Link>
      </MainLayout>
    </>
  );
};

export const ChangePasswordFromEmailPage = () => {
  const windowSize = useWindowSize();

  return (
    <>
      <MainLayout>
        <StyledComponents.SpecialContainer margin='100px 0 0 0' className="d-flex ">
          <ChangePasswordFromEmail
            redirect={"/success-change-password-from-email"}
          ></ChangePasswordFromEmail>
        </StyledComponents.SpecialContainer>
      </MainLayout>
    </>
  );
};

export const SuccessChangePasswordFromEmailPage = () => {
  const windowSize = useWindowSize();
  return (
    <MainLayout>
     
      
      <StyledComponents.SpecialContainer margin='100px 0 0 0' className="d-flex ">
        <SuccessChangePasswordFromEmail></SuccessChangePasswordFromEmail>
      </StyledComponents.SpecialContainer>
      <Link to="/">
          <Assets.IconBack text='Iniciar Sesión'></Assets.IconBack>
        </Link>
    </MainLayout>
  );
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Assets, StyledComponents, Dropdown } from "../components-config";
import { CardConceptStyle } from "./styleComponets";
import CheckBox from "./CheckBox";
import { changeFilterStructureOption, changeFilterStructureOptionSearch } from "./actions/actions";
import { selectStructureOptions, selectStructureOptionsFilterSearch } from "./selectors/options";
import {
  selectFilterStrcutureOptionsIsCheck,
  selectFilterStructureOptionCount,
  selectFilterStructureOptionNotEmpty,
} from "./selectors/filters";

const ItemWithCheckbox = ({ id, code, description }) => {
  const dispatch = useDispatch();

  const isCheck = useSelector((state) => selectFilterStrcutureOptionsIsCheck(state, id));

  function handlerChange() {
    dispatch(changeFilterStructureOption(id));
  }

  return (
    <li key={id}>
      {code} | {description}
      <StyledComponents.SpecialContainer margin="0 0 0 3px">
        <CheckBox onChange={handlerChange} status={isCheck}></CheckBox>
      </StyledComponents.SpecialContainer>
    </li>
  );
};

const Item = ({ item }) => {
  return <ItemWithCheckbox {...item} />;
};

const List = ({ options }) => {
  return (
    <ul>
      {options.map((item) => (
        <Item key={item.id} item={item} />
      ))}
    </ul>
  );
};

const StructureTypeValue = (isCheck) => {
  const dispatch = useDispatch();

  const options = useSelector(selectStructureOptionsFilterSearch);
  const selectedGreaterThatOne = useSelector(selectFilterStructureOptionNotEmpty);
  const selectedCount = useSelector(selectFilterStructureOptionCount);

  function onChangeSearchInput(e) {
    dispatch(changeFilterStructureOptionSearch(e.target.value));
  }

  return (
    <>
      <Dropdown.SelectConcepts
        dropdownTop="32px"
        dropdownToggle={
          <StyledComponents.SpecialContainer
            margin="0 4px 0 0"
            className={`d-flex align-items-center justify-content-between bg-purple90 ${
              !isCheck ? "disabled" : ""
            }`}
            width="220px"
            minWidth="220px"
            height="32px"
            padding="0 8px"
            borderRadius="40px">
            <StyledComponents.MyInput
              type={"text"}
              borderRadius="40px"
              height="28px"
              textAlign="left"
              className={"font-weight-bold"}
              margin="2px 4px 2px -6px"
              placeholder={`Buscar... ${
                selectedGreaterThatOne ? `| Seleccionados (${selectedCount})` : ""
              }`}
              fontSize="12px"
              minWidth="169px"
              padding="10px"
              disabled={false}
              onChange={(e) => onChangeSearchInput(e)}></StyledComponents.MyInput>
            <span className="d-flex align-items-center">
              <Assets.IconArrows />
            </span>
          </StyledComponents.SpecialContainer>
        }>
        <StyledComponents.SpecialContainer padding="0 4px 0 0">
          <StyledComponents.OverFlowSelects
            borderRadius="5px"
            height={"270px"}
            bgColor="var(--purple100)">
            <CardConceptStyle>
              <List options={options}></List>
            </CardConceptStyle>
          </StyledComponents.OverFlowSelects>
        </StyledComponents.SpecialContainer>
      </Dropdown.SelectConcepts>
    </>
  );
};

export default StructureTypeValue;

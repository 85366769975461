import { toast } from "react-toastify";
import { submitFormData } from "../../cms/store/slices/component";
import { Assets, StyledComponents } from "../../../components-config";
import { onLoading, offLoading } from "../../cms/store/slices/uiLoading";
import { Modal } from "reactstrap";

import { useDispatch } from "react-redux";
import { useState } from "react";

function TransferFile({ setting }) {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [notificationType, setNotificationType] = useState(null);
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  async function onClickSendFile() {
    if (selectedFile == null) {
      toast.warning("Ningún archivo seleccionado", { theme: "colored" });
      return;
    }

    const formData = new FormData();
    formData.append("filename", hasFilename("input-file"));
    formData.append("file", selectedFile);
    formData.append("email", localStorage.getItem("email"));

    dispatch(onLoading());

    dispatch(submitFormData({ formType: setting.type, data: formData }))
      .unwrap()
      .then((data) => {
        setNotificationType("success");
        setNotificationMessage(data.message);
        setModal(true);
      })
      .catch((data) => {
        setNotificationType("error");
        setNotificationMessage(data.error);
        setModal(true);
      })
      .finally(() => {
        dispatch(offLoading());
      });
  }

  function hasExtension(inputID, exts) {
    var fileNameExt = document.getElementById(inputID).value;
    return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(fileNameExt);
  }

  function hasFilename(inputID) {
    var fileName = document.getElementById(inputID).value;
    // a. Get only the file name of a `.ts` path
    return fileName.split("\\").pop();
  }

  function onChangeFile(e) {
    const file = e.target.files[0];
    const isValidExtensionFile = hasExtension("input-file", [setting.fields[0].extension]);

    if (isValidExtensionFile) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }

    // this.setState({
    //   selectedFileName: e.target.files[0].name
    // });
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={modal}>
        <div>
          <StyledComponents.SpecialContainer
            margin="100px 0 25px 0"
            className="d-flex justify-content-center align-items-center ">
            <StyledComponents.MyP margin="0 20px 0 0" fontSize="60px" className="mr2 text-white">
              ¡Notificación!
            </StyledComponents.MyP>

            {notificationType == "sucess" ? (
              <Assets.IconCheck color="var(--green30)" width="60" height="60"></Assets.IconCheck>
            ) : (
              <Assets.IconInfo color="var(--yellow10)" width="60" height="60"></Assets.IconInfo>
            )}
          </StyledComponents.SpecialContainer>
          <div className="text-center">
            <StyledComponents.SpecialContainer margin="0 0 100px 0">
              <StyledComponents.MyP fontSize={"20px "} className="text-white">
                {notificationMessage}
              </StyledComponents.MyP>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              margin="200px auto 0 auto"
              className="d-flex justify-content-center"
              onClick={toggle}>
              <Assets.IconBack colorArrow="#fff" colorText="text-white"></Assets.IconBack>
            </StyledComponents.SpecialContainer>
          </div>
        </div>
      </Modal>

      <div className="flex flex-col items-center justify-center h-screen">
        <StyledComponents.SpecialContainer
          border="1px dashed var(--purple40)"
          borderRadius="13px"
          className=""
          padding="10px">
          <div className="d-flex align-items-center">
            <Assets.IconSheet width="21" height="26" />
            <StyledComponents.MyP className="font-weight-bold text-purple40" fontSize="12px">
              <StyledComponents.MyLabel
                type="text"
                width="125px"
                height={"32px"}
                padding="0 20px"
                margin="0 0 0 0"
                borderRadius="4px"
                fontSize="12px"
                className={`d-flex align-items-center bg-blue50 text-white font-weight-bold`}
                htmlFor="input-file">
                {setting.fields[0].label}
              </StyledComponents.MyLabel>
              <StyledComponents.MyInput
                type="file"
                className="input-file"
                id="input-file"
                disabled={false}
                onChange={(e) => onChangeFile(e)}></StyledComponents.MyInput>
            </StyledComponents.MyP>
            <form>
              <div className="flex justify-center items-center w-full">
                <StyledComponents.SpecialContainer fontSize="12px" className="d-flex">
                  <StyledComponents.MyButton
                    type="button"
                    width="100px"
                    height={"32px"}
                    padding="0 20px"
                    margin="0"
                    className={`bg-green50 text-grey80 font-weight-bold`}
                    disabled={false}
                    onClick={onClickSendFile}>
                    <Assets.IconUpload />
                    <span className="ml5"> Cargar</span>
                  </StyledComponents.MyButton>
                </StyledComponents.SpecialContainer>
              </div>
            </form>
          </div>
          <StyledComponents.SpecialContainer padding="10px 0 0 0">
            <p>
              {selectedFile == null ? "Ningún archivo seleccionado." : hasFilename("input-file")}
            </p>
          </StyledComponents.SpecialContainer>
        </StyledComponents.SpecialContainer>
      </div>
    </>
  );
}

export default TransferFile;

// Librerias.
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

// Components.
import { axios_api } from "../components-api/ConfigApi";
import { ReadPdf } from "../components-config";
import { CurrentModule } from "../components-config/Navbars";
import { StyledComponents, Assets } from "../components-config";
import { SendEmail } from "../components-email";

const CFDIFile = () => {
  const [cfdi, setCdfi] = useState({});
  const { fileId } = useParams();
  useEffect(() => {
    const params = new URLSearchParams();
    params.append("uuid", fileId);
    const response = async () => {
      const respuesta = await axios_api(
        `/payroll/receipts/`,
        `company-form-data`,
        `post`,
        params
      );
      setCdfi(respuesta.data);
    };
    response();
  }, [fileId]);
  return (
    <>
      <CurrentModule
        to="/dashboard-cfdis"
        title={cfdi.filename}
        send={
          <>
            <SendEmail
              isActive={true}
              description={
                <p>
                  El CFDI será enviado en formato XML y PDF a la siguiente
                  dirección:
                  <span className="font-weight-bold">
                    {" "}
                    {localStorage.getItem("email")}
                  </span>
                </p>
              }
              description2={
                <StyledComponents.MyP
                  fontSize="18px"
                  className={"font-weight-bold "}
                >
                  Ingrese la dirección de correo a la cual desea que le llegue
                  el CFDI:
                </StyledComponents.MyP>
              }
              uri={`/integrity/cfdis/${fileId}/send-receipt/`}
              //modelToSend={}
            >
              <div className="d-flex align-items-center">
                <Assets.IconSendEmail color="#4B2192" />
                <StyledComponents.MyP
                  fontSize="18px"
                  className={"font-weight-bold text-purple40 ml10"}
                >
                  Enviar
                </StyledComponents.MyP>
              </div>
            </SendEmail>
          </>
        }
        bgColor="bg-grey50"
        colorIcon="var(--purple50)"
        colorText="text-purple40"
      />
      <ReadPdf file={`https://api.allorigins.win/raw?url=${cfdi.file}`} />
    </>
  );
};

export default CFDIFile;

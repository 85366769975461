import { axios_api } from "../../components-api/ConfigApi";

export const REPORTS_CARGANDO = "REPORTS_CARGANDO";
export const REPORTS_ERROR = "REPORTS_ERROR";
export const REPORTS_CLEAN_STATE = "REPORTS_CLEAN_STATE";
export const REPORTS_CHANGE_STATE = "REPORTS_CHANGE_STATE";
export const REPORTS_FILTERS_LIST = "REPORTS_FILTERS_LIST";
export const REPORTS_DOWNLOAD_LIST = "REPORTS_DOWNLOAD_LIST";
export const REPORTS_SEND_LIST = "REPORTS_SEND_LIST";



export const GetReportsFilters = async (data) => {
  const answer = await axios_api(
    `${data.url}/concepts/?client=${data.company_selected}`,
    true,
    "get"
  );
  return { res: answer, type: REPORTS_FILTERS_LIST };
};

export const PostSendReports = async (data) => {
  const answer = await axios_api(
    `/REPORTS/user_modules/`,
    true,
    "post",
        data
  );
  return { res: answer, type: REPORTS_DOWNLOAD_LIST };
};

export const PostDownloadReports = async (data) => {
  const answer = await axios_api(
    `/REPORTS/user_submodules/`,
    true,
    "post",
    data
  );
  return { res: answer, type: REPORTS_SEND_LIST };
};




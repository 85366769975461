import { useState, useEffect } from "react";
import axios from "axios";
const EmployeeTypes = () => {
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [employeeTypesLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const tokenSave = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Token ${tokenSave}`,
        "Content-Type": "application/x-www-form-urlencoded",
        "x-app-cocia": localStorage.getItem("x-app-cocia"),
      },
    };
    const response = async () => {
      setIsLoading(true);
      try {
        const respuesta = await axios.get(
          `${process.env.REACT_APP_ROUTE}/rh/employees/type/`,
          config
        );
        setEmployeeTypes(respuesta.data);
      } catch {
      } finally {
        setIsLoading(false);
      }
      setIsLoading(false);
    };
    response();
  }, []);

  return { employeeTypes, employeeTypesLoading };
};

export default EmployeeTypes;

import { axios_api } from "../../components-api/ConfigApi";

export const PAYROLL_CARGANDO = "PAYROLL_CARGANDO";
export const PAYROLL_ERROR = "PAYROLL_ERROR";
export const PAYROLL_CLEAN_STATE = "PAYROLL_CLEAN_STATE";
export const PAYROLL_LIST = "PAYROLL_LIST";
export const PAYROLL_LIST_PAYCHECKS = "PAYROLL_LIST_PAYCHECKS";
export const PAYROLL_HANDLE_CHANGE = "PAYROLL_HANDLE_CHANGE";

export const GetPayrolls = async () => {
  const answer = await axios_api(`/paycheck/paychecks/`, true, "get");
  return { res: answer, type: PAYROLL_LIST };
};

export const GetTypePaychecks = async () => {
  const answer = await axios_api(`/paycheck/filters/`, true, "get");
  return { res: answer, type: PAYROLL_LIST_PAYCHECKS };
};

export const GetPayrollsFilter = async (data) => {
  const answer = await axios_api(`/paycheck/paychecks/filter/`, true, "post",data);
  return { res: answer, type: PAYROLL_LIST };
};

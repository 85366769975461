import { createSelector } from "reselect";
import { selectModule } from "./module";

export const selectFilter = createSelector(selectModule, (module) => module.filters);

export const selectFilterIncidenceType = createSelector(
  [selectFilter],
  (filter) => filter.incidenceType
);

export const selectFilterStructureType = createSelector(
  [selectFilter],
  (filter) => filter.structureType
);

export const selectFilterConcepts = createSelector(selectFilter, (filter) => filter.concepts);
export const selectFilterPayGroups = createSelector(selectFilter, (filter) => filter.payGroups);


export const selectFilterConceptCount = createSelector(selectFilterConcepts, (concepts) => {
  return concepts.length;
});

export const selectFilterConceptsNotEmpty = createSelector(selectFilterConcepts, (concepts) => {
  return concepts.length > 0;
});

export const selectFilterConceptIsCheck = createSelector(
  [selectFilterConcepts, (state, id) => id],
  (concepts, id) => {
    return concepts.includes(id);
  }
);

export const selectFilterPayGroupIsCheck = createSelector(
  [selectFilterPayGroups, (state, id) => id],
  (items, id) => {
    return items.includes(id);
  }
);

export const selectFilterPayGroupNotEmpty = createSelector(selectFilterPayGroups, (items) => {
  return items.length > 0;
});

export const selectFilterPayGroupCount = createSelector(selectFilterPayGroups, (items) => {
  return items.length;
});

// Strcuture Option
export const selectFilterStructureOptions = createSelector(selectFilter, (filter) => filter.structureTypeOptions);

export const selectFilterStructureOptionCount = createSelector(selectFilterStructureOptions, (options) => {
  return options.length;
});

export const selectFilterStructureOptionNotEmpty = createSelector(selectFilterStructureOptions, (options) => {
  return options.length > 0;
});

export const selectFilterStrcutureOptionsIsCheck = createSelector(
  [selectFilterStructureOptions, (state, id) => id],
  (option, id) => {
    return option.includes(id);
  }
);

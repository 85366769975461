import {
  PAYROLL_CARGANDO,
  PAYROLL_ERROR,
  PAYROLL_CLEAN_STATE,
  PAYROLL_LIST,
  PAYROLL_HANDLE_CHANGE,
  PAYROLL_LIST_PAYCHECKS,
} from "./payrollTypes";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    error: "",
  },
  paycheck_type: "",
  month: "Todos los meses",
  year: "Todos los años",
  payroll_file: "",
  send_list: [],
  list_payroll: [],
  list_paychecks: [],
};

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAYROLL_CARGANDO:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          error: "",
        },
      };
    case PAYROLL_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: action.payload,
        },
      };

    case PAYROLL_CLEAN_STATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        data: {},
        send_list: [],
        list_payroll: [],
      };
    case PAYROLL_LIST:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        list_payroll: action.payload.data,
      };
    case PAYROLL_HANDLE_CHANGE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        [action.payload.stateValue]: action.payload.data,
      };
    case PAYROLL_LIST_PAYCHECKS:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        list_paychecks: action.payload.filters,
      };
    default:
      return { ...state };
  }
};

export default Reducer;

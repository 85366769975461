// Librerias.
import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Base Components.
import { Modals } from "../components-config";

// Actions.
import * as downloadActions from "./reducer/downloadActions";

// Metodos para hacer descarga de archivos.
export const getFile = (uri, name) => {
  let downloadElement = document.createElement("a");
  downloadElement.href = uri;
  downloadElement.download = name;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
};

// Componente para gestionar las descargas de archivos.
export const DownloadFile = ({
  children,
  uri,
  data,
  fileName = "download",
  className = "",
  isActive,
}) => {
  const downloadReducer = useSelector((state) => state.downloadReducer);
  const {
    api_actions: { cargando },
    file,
    typeFile,
    file_name,
  } = downloadReducer;
  const dispatch = useDispatch();

  const downloadFile = () => {
    if (isActive) {
      const model = {
        uri: uri,
        data: data,
      };
      dispatch(downloadActions.donwloadMethods(model));
      dispatch(downloadActions.changeState("file_name", fileName));
    }
  };

  if (file) {
    switch (typeFile) {
      case ".xls":
        //window.open(`data:application/vnd.ms-excel;base64,${file}`, "_blank");
        getFile(
          `data:application/vnd.ms-excel;base64,${file}`,
          `${file_name}.xls`
        );
        downloadReducer.file = "";
        break;
      case ".zip":
        //window.open(`data:application/zip;base64,${file}`, "_blank");
        getFile(`data:application/zip;base64,${file}`, `${file_name}.zip`);
        downloadReducer.file = "";
        break;
      case ".pdf":
        getFile(`data:application/pdf;base64,${file}`, `${file_name}.pdf`);
        downloadReducer.file = "";
        break;
      case ".xml":
        getFile(
          "data:application/octet-stream;base64," + file,
          `${file_name}.xml`
        );
        downloadReducer.file = "";
        break;
      case ".csv":
        getFile("data:application/csv;base64," + file, `${file_name}.csv`);
        downloadReducer.file = "";
        break;
      case ".txt":
        getFile("data:text/plain;base64," + file, `${file_name}.txt`);
        downloadReducer.file = "";
        break;
      default:
        break;
    }
    dispatch(downloadActions.downloadCleanState());
  }

  return (
    <>
      {cargando && <Modals.ModalLoading />}
      <div onClick={downloadFile} className={className}>
        {children}
      </div>
    </>
  );
};

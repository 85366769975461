import { combineReducers } from "redux";

import incidenceTypeReducer from "./incidenceType";
import conceptReducer from "./concept";
import payGroupReducer from "./payGroup";
import structureTypeReducer from "./structureType";
import structureOptionReducer from "./structureOption";
import structureOptionSearchReducer from "./structureOptionSearch";

export default combineReducers({
  incidenceTypes: incidenceTypeReducer,
  concepts: conceptReducer,
  payGroups: payGroupReducer,
  structureTypes: structureTypeReducer,
  structureOptions: structureOptionReducer,
  structureOptionSearch: structureOptionSearchReducer
});

import { useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { CheckBoxIcon } from "./Assets";
import {payrollVaribleStateChange} from '../componentes-payroll/reducer/payrollActions'

export const CheckBox = ({pk}) => {
  const [toggle, setToggle] = useState(false);
  const payrollReducer = useSelector(state => state.payrollReducer)
  const dispatch = useDispatch()

  const click = (pk) => {
    if(!toggle){
      payrollReducer.send_list.push(`${pk}`);
    }else{
      payrollReducer.send_list.splice(payrollReducer.send_list.indexOf(`${pk}`), 1);
    }
    dispatch(payrollVaribleStateChange('send_list', payrollReducer.send_list))
    setToggle(!toggle)
  }
  return (
    <CheckBoxIcon
      toggle={toggle}
      onClick={() => click(pk)}
    ></CheckBoxIcon>
  );
};



export const CheckBoxReport = ({isCheck, setIsCheck}) => {
 
  return (
    <CheckBoxIcon
      toggle={isCheck}
      onClick={setIsCheck}
    ></CheckBoxIcon>
  );
};

import { DATA_OPTIONS_CLEAN_ALL, PAY_GROUP_FETCH_SUCCESS } from "../actions/actionTypeKeys";

export const initialState = [];

function reducer(state = initialState, action) {
  switch (action.type) {
    case DATA_OPTIONS_CLEAN_ALL:
      return [];
    case PAY_GROUP_FETCH_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
}

export default reducer;

import axios from "../utils/axios";

const BASE_URL = "/cms/forms";

export const fetchForm = async (form_code) => {
  const url = `${BASE_URL}/${form_code}/setting/`;
  return await axios.get(url);
};

export const submit = async (form_code, form_type, data) => {
  const url = `${BASE_URL}/${form_code}/process/?type=${form_type}`;
  return await axios.post(url, { data: JSON.stringify(data) });
};

export const submitFormData = async (form_code, form_type, data) => {
  const url = `${BASE_URL}/${form_code}/process/?type=${form_type}`;
  return await axios.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

import React from "react";
import {
  TabContent as TabContentReactstrap,
  TabPane,
} from "reactstrap";
import {MyTabItems} from './StyledComponents'

export const Titles = ({ tabsTitle, toggle, activeTab, classTabActive, classTab }) => {
  return (
    <>
      {tabsTitle.map((tab, i) => {
        return (
          <MyTabItems
            className={`pointer ${activeTab === `${i+1}` ? classTabActive : classTab}`}
            onClick={() => {
              toggle(`${i+1}`);
            }}
          >
            {tab}
          </MyTabItems>
        );
      })}
    </>
  );
};
export const Contents = ({ activeTab, tabsContent }) => {
  return (
    <>
      <TabContentReactstrap activeTab={activeTab}>
        {tabsContent.map((tabContent, i) => {
          return <TabPane tabId={`${i + 1}`}>{tabContent}</TabPane>;
        })}
      </TabContentReactstrap>
    </>
  );
};

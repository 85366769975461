import { axios_api } from "../../components-api/ConfigApi";

export const DIGITALIZATION_CARGANDO = "DIGITALIZATION_CARGANDO";
export const DIGITALIZATION_ERROR = "DIGITALIZATION_ERROR";
export const DIGITALIZATION_CLEAN_STATE = "DIGITALIZATION_CLEAN_STATE";
export const DIGITALIZATION_CHANGE_STATE = "DIGITALIZATION_CHANGE_STATE";
export const DIGITALIZATION_LIST = "DIGITALIZATION_LIST";
export const DIGITALIZATION_TRANSFER = "DIGITALIZATION_TRANSFER";

export const GetDigitalizationSupliers = async () => {
  const answer = await axios_api(`/company/suppliers/`, "company", "get");
  return { res: answer, type: DIGITALIZATION_LIST };
};

export const DigitalizationTransfer = async (dataJson) => {
  const formData = new FormData();

  for (const key in dataJson) {
    if (Array.isArray(dataJson[key])) {
      dataJson[key].forEach((value, index) => {
        formData.append(`${key}[${index}]`, value);
      });
    } else {
      formData.append(key, dataJson[key]);
    }
  }

  const answer = await axios_api(
    `/integrity/documents/textract/`,
    "company",
    "post",
    formData,
    10000000
  );
  return { res: answer, type: DIGITALIZATION_TRANSFER };
};

import styled from "styled-components";

// Manejo de imagenes.
export const MyImage = styled.img`
  width: ${(props) => (props.width ? props.width : "inherit")};
  height: ${(props) => (props.height ? props.height : "inherit")};
`;

export const ProfilePicture = styled.div`
  background-image: url(${(props) => (props.url ? props.url : "")});
  background-size: cover;
  background-position: center center;
  border: ${(props) =>
    props.border ? props.border : " 2px solid var(--purple40)"};
  border-radius: 10rem;
  width: ${(props) => (props.width ? props.width : "50px")};
  height: ${(props) => (props.width ? props.width : "50px")};
`;

// Layouts especiales.
export const MyHeader = styled.header`
  position: relative;
  padding-block-start: ${(props) =>
    props.paddingStart ? props.paddingStart : "60px"};
  padding-block-end: ${(props) =>
    props.paddingEnd ? props.paddingEnd : "none"};
  background-color: var(--white);
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const MySecondHeader = styled.header`
  display: flex;
  border-top: ${(props) =>
    props.borderTop ? props.borderTop : "0 solid white"};
  border-bottom: 4px solid white;
  height: 90px;
  margin: ${(props) => (props.margin ? props.margin : "0")};
`;

export const DashboardInfoPhone = styled.div`
  background: #511797;
  margin-block-start: 4px;
  margin-block-end: 4px;
  p {
    font-size: 18px;
    padding: 9px;
    margin: 0;
  }
`;

export const MyLayout = styled.div`
  position: ${(props) => (props.position ? props.position : "none")};
  height: ${(props) => (props.height ? props.height : "auto")};
`;

export const MyLayoutSection = styled.section`
  height: ${(props) => (props.height ? props.height : "auto")};
  width: ${(props) => (props.width ? props.width : "100%")};
  margin: ${(props) => (props.margin ? props.margin : "none")};
`;

export const SpecialContainer = styled.div`
  display: ${(props) => (props.display ? props.display : "block")};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  height: ${(props) => (props.height ? props.height : "auto")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "inherit")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "inherit")};
  width: ${(props) => (props.width ? props.width : "inherit")};
  font-style: ${(props) => (props.fontStyle ? props.fontStyle : "inherit")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "inherit")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "inherit")};
  color: ${(props) => (props.color ? props.color : "inherit")};
  background: ${(props) => (props.background ? props.background : "none")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "none"};
  border-bottom: ${(props) =>
    props.borderBottom ? props.borderBottom : "none"};
  border-right: ${(props) => (props.borderRight ? props.borderRight : "none")};
  border: ${(props) => (props.border ? props.border : "none")};
  grid-gap: ${(props) => (props.gridGap ? props.gridGap : "none")};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : "none")}; ;
  overflow: ${(props) => (props.overFlow ? props.overFlow : "none")};
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const OverFlowX = styled.div`
  overflow-x: ${(props) => (props.overFlow ? props.overFlow : "auto")};
`;
export const OverFlowY = styled.div`
  width: 270px;
  min-width: 270px;
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 120px);
`;

export const OverFlowYY = styled.div`
  width: 100%;
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - ${(props) => (props.height ? props.height : "210px")});
`;
export const OverFlowCfdis = styled.div`
  width: 100%;
  overflow-y: scroll;
  position: relative;
  height: ${(props) => (props.height ? props.height : "210px")};
`;

export const OverFlowSelects = styled.div`
  overflow-y: scroll;
  height: ${(props) => (props.height ? props.height : "auto")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "10px"};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "white")};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "0 3px 6px #eef1f"};

  ::-webkit-scrollbar {
    width: 0px;
    height: 7px;
  }
`;

export const BorderLinear = styled.div`
  background: linear-gradient(90deg, #8307fe 0%, #d807bb 82.69%, #ea07ad 100%);
  width: 100%;
  height: 4px;
`;

// Terminan Layouts especiales

export const CardService = styled.div`
  width: 100%;
  max-width: 378px;
  min-width: 378px;
  height: 80px;
  padding: 10px 0 10px 26px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  background: #eef1f9;

  p {
    display: inline;
    font-size: 18px;
    margin-inline-start: 20px;
  }

  &.tablet-service {
    width: 260px;
    max-width: 260px;
    min-width: 260px;
    height: 180px;
    padding: 40px 0;
    p {
      font-size: 18px;
      margin: 0;
    }
  }
  &.phone-service {
    width: 100%;
    max-width: none;
    min-width: none !important;
    height: 80px;
    padding: 0 15px;
    margin-right: auto;
    margin-left: auto;
    p {
      font-size: 18px;
    }

    @media (max-width: 375px) {
      p {
        font-size: 16px;
      }
    }
  }
`;

export const Opacity = styled.div`
  background: #eef1f9;
  opacity: ${(props) => (props.opacity ? props.opacity : "100%")};
`;

// Tablas
export const MyTable = styled.div`
  display: flex;
  justify-content: center;
  font-size: 29px;
  div.table-container {
    box-shadow: 0 3px 6px #f0f0f0;
    border-radius: 4px 4px 0 0;
  }
  div.title-table {
    margin: auto;
    max-width: 637px;
    border-radius: 4px 4px 0 0;
    background: var(--purple20);
    color: var(--white);
    font-weight: bold;
    text-align: center;
    border-inline: 5px solid var(--white);
    border-block-start: 5px solid var(--white);
  }
  table {
    margin: auto;
    width: 637px;
  }
  tr {
    height: 42px;
    text-align: center;
  }
  th {
    width: 87px;
    background: var(--grey30);
    border: 5px solid var(--white);
  }

  @media (max-width: 601px) {
    font-size: 14px;
    div.title-table {
      margin: auto;
      max-width: 314px;
      border-radius: 4px 4px 0 0;
      background: var(--purple20);
      color: var(--white);
      font-weight: bold;
      text-align: center;
      border-inline: 3px solid var(--white);
      border-block-start: 3px solid var(--white);
    }
    table {
      margin: auto;
      width: 314px;
    }
    tr {
      height: 20px;
      text-align: center;
    }
    th {
      width: 43px;
      background: var(--grey30);
      border: 3px solid var(--white);
    }
  }
`;
// Termina Tablas

// Posiciones absolutas
export const DivToRelative = styled.div`
  position: absolute;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  top: ${(props) => (props.top ? props.top : "none")};
  left: ${(props) => (props.left ? props.left : "none")};
  right: ${(props) => (props.right ? props.right : "none")};
  bottom: ${(props) => (props.bottom ? props.bottom : "none")};
  z-index: ${(props) => (props.zIndex ? props.zIndex : "none")};
`;

// Terminan posiciones absolutas

// Titulos, parrafos
export const Title = styled.h1`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "38px")};
  @media (max-width: 500px) {
    font-size: ${(props) =>
      props.fontSizePhone ? props.fontSizePhone : "24px"};
  }
`;

export const MyP = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
  margin: ${(props) => (props.margin ? props.margin : "auto")};
  width: ${(props) => (props.width ? props.width : "none")};

  /* @media (max-width: 500px) {
    font-size: ${(props) =>
    props.fontSizePhone ? props.fontSizePhone : "16px"};
  } */
`;

// Terminan titulos

// Botones

export const MyButton = styled.button`
  display: ${(props) => (props.display ? props.display : "block")};
  text-align: center;
  padding: ${(props) => (props.padding ? props.padding : "13px 0")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "inherit")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : props.maxWidth)};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  border: ${(props) => (props.border ? props.border : "none")};
  color: ${(props) => (props.color ? props.color : "black")};
  margin: ${(props) => (props.margin ? props.margin : "auto")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "40px"};

  &.btn-green {
    background-color: #b8ffa1;
  }
  &.btn-green3 {
    background-color: #b8ffa1;
    font-size: 14px;
    font-weight: bold;
    padding: 7px 27px;
  }

  &.btn-exit {
    background-color: #d3d9e8;
    color: #4b2192;
  }

  &.btn-green2 {
    background-color: #4dd200;
  }

  &.btn-red {
    background-color: #ff0000;
    font-size: 14px;
  }
  &.btn-red2 {
    background-color: #ff0000;
    color: white;
    font-size: 14px;
  }

  &.btn-yellow {
    background-color: #fff500;
    color: black;
    font-size: 14px;
  }

  &.btn-validate {
    font-size: 12px;
    background: var(--yellow20);
    font-weight: bold;
    padding: 4px 15px;
  }
  &.btn-sap {
    font-size: 12px;
    background: rgb(1, 179, 238);
    background: linear-gradient(
      180deg,
      rgba(1, 179, 238, 1) 0%,
      rgba(28, 101, 190, 1) 100%
    );
    font-weight: bold;
    padding: 4px 14.5px;
    color: white;
  }

  &.btn-purple {
    background-color: var(--purple10);
  }
  &.btn-pink {
    background-color: var(--pink20);
  }

  &.btn-search {
    background: var(--pink50);
    color: white;
    font-size: 8px;
    font-weight: bold;
    padding: 8px 20px;
    span {
      margin-right: 4px;
    }
  }

  &.btn-hidden {
    font-weight: bold;
    font-size: 12px;
    color: var(--purple40);
    background: var(--grey120);
    padding: 5px 20px;
  }

  &.btn-search2 {
    background: var(--blue40);
    font-size: 12px;
    padding: 5px 21px;
  }

  &.btn-send {
    background: var(--green40);
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 8px 18px;
    span {
      margin-left: 4px;
    }
  }

  &.select-company {
    background-color: white;
    border: 1px solid var(--purple40);
    color: var(--purple40);
    font-size: 14px;
    padding: 5px 15px 4px 15px;
  }
  &.select-module {
    background-color: var(--purple40);
    border: 1px solid var(--purple40);
    color: white;
    font-size: 14px;
    padding: 5px 15px 4px 15px;
    border-radius: 40px 0 0 40px;
  }
  &.select-sub-module {
    background-color: var(--grey50);
    border: 1px solid var(--grey50);
    color: var(--purple40);
    font-size: 14px;
    padding: 5px 15px 4px 15px;
    border-radius: 0 40px 40px 0;
  }
  &.filter-cafeteria {
    background-color: white;
    border: 1px solid white;
    color: var(--purple40);
    font-weight: bold;
    font-size: 13px;
    padding: 3px 10px;
    border-radius: 40px;
  }

  &.btn-settlement {
    color: var(--purple80);
    font-weight: bold;
    background-color: var(--blue40);
    padding: 7px 19px;
    font-size: 14px;
    line-height: 17px;
  }
  &.btn-open-documents {
    padding: 7px 19px;
    color: var(--white);
    font-weight: bold;
    background-color: var(--pink60);
    font-size: 14px;
    border-radius: 6px;
    line-height: 17px;
  }

  &.btn-authorize {
    background-color: white;
    border: 1px solid var(--purple40);
    color: var(--purple40);
    font-size: 14px;
    padding: 7px 10px;
    width: 180px;
    text-align: left;
    line-height: 15px;
  }

  &.btn-authorize-active {
    background-color: var(--green50);
    color: var(--purple40);
    font-size: 14px;
    padding: 7px 10px;
    width: 180px;
    text-align: left;
    line-height: 15px;
  }

  &.btn-re-settlement {
    color: var(--purple80);
    font-weight: bold;
    background-color: var(--blue40);
    padding: 7px 14px;
    font-size: 14px;
    line-height: 17px;
  }

  &.filter-settlement {
    color: var(--grey80);
    font-size: 14px;
    background: var(--white);
    padding: 7px 10px;
    line-height: 17px;
  }

  &.btn-period {
    color: black;
    padding: 0;
    font-weight: bold;
    font-size: 14px;
    background: white;
  }

  &.btn-disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

// Terminan botones.
// Empiezan los Tabs.
export const MyTabItems = styled.div`
  border-radius: 4px 4px 0 0;
  margin-inline-end: 4px;
  padding-block-start: 6px;
  padding-block-end: 6px;

  &.tab-cafeteria {
    background: var(--grey100);
    color: var(--purple70);
    font-size: 13px;
    font-weight: normal;
    text-align: center;
    width: 160px;
  }
  &.tab-cafeteria-active {
    background: var(--grey100);
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    width: 160px;
  }
`;
// Terminan los Tabs.
// Inputs

// Terminan los inputs.
export const MyInput = styled.input`
  display: block;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "40px"};
  border: ${(props) => (props.border ? props.border : "none")};
  margin: ${(props) => (props.margin ? props.margin : "auto")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "inherit")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "inherit")};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "none")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
  padding: ${(props) => (props.padding ? props.padding : "11px 0")};

  &.input-success {
    border: 1px solid green;
    box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 25%);
  }

  &.input-error {
    border: 1px solid #ff0000;
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
    ::placeholder {
      color: #ff0000;
    }
  }

  &.input-settlement {
    font-size: 14px;
    padding: 7px 10px;
    text-align: left;
    max-width: 180px;
    min-width: 180px;
    line-height: 17px;
  }

  &.input-settlement-amount {
    border: 1px solid black;
    line-height: 38px;
    padding: 5px 10px;
    border-radius: 2px;
    height: 26px;
    text-align: left;
    font-size: 12px;
  }

  &.input-reports-employee {
    border-radius: 2px;
    font-weight: bold;
    font-size: 12px;
    height: 24px;
    text-align: left;
    padding: 0 5px;
  }

  &.input-search {
    height: 24px;
    width: 174px;
    text-align: left;
    font-size: 10px;
    padding: 0 10px;
  }

  &.input-disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.input-file {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
`;

export const MyLabel = styled.label`
  display: block;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "40px"};
  border: ${(props) => (props.border ? props.border : "none")};
  margin: ${(props) => (props.margin ? props.margin : "auto")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "inherit")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "inherit")};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "none")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
  padding: ${(props) => (props.padding ? props.padding : "11px 0")};
  &.label-disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
// Bordes
export const MyBorderBottom = styled.div`
  margin: ${(props) => (props.margin ? props.margin : "none")};

  border-bottom: ${(props) =>
    props.border ? props.border : "1px solid #CECECE"};
`;

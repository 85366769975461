// Librerias.
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Container } from "reactstrap";

// Components
import {
  Layouts,
  StyledComponents,
  Dropdown,
  Assets,
} from "../components-config";
import { SendEmail } from "../components-email";

// Actions.
import * as cfdiStampBalaceActions from "./reducer/CFDIStampBalanceActions";

// Hooks.
import { usePagination } from "../components-config/hooks";
import { useHistory } from "react-router-dom";

// CFDIs information.
export const CFDIsInformation = () => {
  return (
    <>
      <Layouts.Info>
        <div>
          <StyledComponents.MyP
            fontSize="14px"
            className="font-weight-bold text-purple40"
          >
            Hola, {localStorage.getItem("username")}.
          </StyledComponents.MyP>
          <StyledComponents.MyP fontSize="14px" className="text-purple40">
          Utilice el siguiente botón de búsqueda para acceder a los saldos de créditos de TIMBOX,
          necesarios para el timbrado de recibos CFDI.
          </StyledComponents.MyP>
        </div>
      </Layouts.Info>
    </>
  );
};

export const CardGroups = styled.div`
  background: var(--grey130);
  width: ${(props) => (props.width ? `${props.width}` : "200px")};
  border-radius: 10px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 10px 8px 9px;
    color: black;
  }
  li:hover {
    background-color: var(--grey90);
    //border-radius: 10px;
  }
  li:first-child {
    border-radius: ${(props) =>
      props.borderRadius
        ? `${props.borderRadius}px ${props.borderRadius}px 0 0 `
        : "10px 10px 0 0"};
  }

  li:last-child {
    border: none;
    border-radius: ${(props) =>
      props.borderRadius
        ? ` 0 0 ${props.borderRadius}px ${props.borderRadius}px`
        : "0 0 10px 10px"};
  }
  li:only-child {
    border-radius: ${(props) =>
      props.borderRadius ? `${props.borderRadius}px ` : "10px"};
  }
`;

export const CfDIsSelect = ({
  dropdownTop,
  group,
  reducerValue,
  reducerVariable,
  width,
  overflow = true,
}) => {
  //const integrityReducer = useSelector((state) => state.integrityReducer);
  //const { company_selected } = integrityReducer;
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [value, setValue] = useState(reducerValue);
  const changeStateSelect = (item) => {
    setValue(item.name);
    toggle();
    dispatch(cfdiStampBalaceActions.changeState(reducerVariable, item));
  };
  return (
    <>
      <Dropdown.LayoutDropDown
        maxWidthToggle="224px"
        dropdownToggle={
          <StyledComponents.SpecialContainer
            margin="0"
            className={`d-flex justify-content-between align-items-center bg-grey130`}
            width={width}
            height="32px"
            padding="0 10px"
            borderRadius="40px"
          >
            <StyledComponents.MyP
              fontSize="12px"
              className=" text-black"
              margin="0"
            >
              {value}
            </StyledComponents.MyP>
            <span className="d-flex align-items-center">
              <Assets.IconArrows />
            </span>
          </StyledComponents.SpecialContainer>
        }
        dropdownOpen={dropdownOpen}
        toggle={toggle}
        margin={""}
      >
        <StyledComponents.DivToRelative top={dropdownTop} zIndex="100">
          {overflow ? (
            <StyledComponents.OverFlowSelects height={"235px"}>
              <CardGroups width={width}>
                <ul>
                  {group.map((item) => {
                    return (
                      <li
                        key={item.id}
                        className="pointer"
                        onClick={() => changeStateSelect(item)}
                      >
                        {item?.code ? `${item.code} -` : ""} {item.name}
                      </li>
                    );
                  })}
                </ul>
              </CardGroups>
            </StyledComponents.OverFlowSelects>
          ) : (
            <CardGroups width={width}>
              <ul>
                {group.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className="pointer"
                      onClick={() => changeStateSelect(item)}
                    >
                      {item?.code ? `${item.code} -` : ""} {item.name}
                    </li>
                  );
                })}
              </ul>
            </CardGroups>
          )}
        </StyledComponents.DivToRelative>
      </Dropdown.LayoutDropDown>
    </>
  );
};

// Input global para pintar los inputs en el filtro de CFDIs.
export const CFDIsInput = ({
  disabled,
  placeholder,
  className,
  type = "text",
  onChange,
}) => {
  return (
    <StyledComponents.MyInput
      type={type}
      borderRadius="4px"
      height="32px"
      textAlign="left"
      className={className}
      placeholder={placeholder}
      fontSize="12px"
      padding="10px"
      disabled={disabled}
      onChange={(e) => onChange(e)}
    ></StyledComponents.MyInput>
  );
};

export const CheckAllCFDIs = ({ className }) => {
  const dispatch = useDispatch();
  const balanceReducer = useSelector((state) => state.cfdiStampBalanceReducer);
  const { list_data } = balanceReducer;
  const [check, setCheck] = useState(false);
  const checkAll = () => {
    setCheck(!check);
    list_data.forEach((element) => {
      element.is_check = !check;
    });
    dispatch(cfdiStampBalaceActions.changeState("list_data", list_data));
    dispatch(
      cfdiStampBalaceActions.changeState(
        "list_selected",
        !check === false ? [] : list_data
      )
    );
  };
  return (
    <>
      <Assets.CheckBoxIcon
        toggle={check}
        onClick={checkAll}
        className={className}
      />
    </>
  );
};

export const CheckCFDIs = ({ CFDIs }) => {
  const history = useHistory();
  const balanceReducer = useSelector((state) => state.cfdiStampBalanceReducer);
  let { list_page, list_data, list_selected, overflowY } = balanceReducer;
  const dispatch = useDispatch();

  //const CFDIsList = list_data;
  const updateCheck = (id) => {
    let isCheck = list_data.findIndex((x) => x.id === id);
    list_data[isCheck].is_check = !list_data[isCheck].is_check;
    if (list_data[isCheck].is_check) {
      list_selected.push(list_data[isCheck]);
    } else {
      list_selected.splice(list_selected.indexOf(isCheck), 1);
      //payrollReducer.send_list.splice(payrollReducer.send_list.indexOf(`${pk}`), 1);
    }
    dispatch(cfdiStampBalaceActions.changeState("list_data", list_data));
    //dispatch(cfdiStampBalaceActions.changeState("list_page", list_data));
    dispatch(
      cfdiStampBalaceActions.changeState("list_selected", list_selected)
    );
  };


  return (
    <Container>
      <StyledComponents.OverFlowCfdis height={overflowY}>
        {list_page.map((cfdi) => {
          return (
            <div key={cfdi.count}>
              <StyledComponents.SpecialContainer
                fontSize="13px"
                color="var(--purple40)"
                className="d-flex"
              >
                <StyledComponents.SpecialContainer
                  padding="10px 0"
                  margin="2px 2px 0 0"
                  background="var(--purple40)"
                  className="text-white d-flex justify-content-center"
                  fontSize="13px"
                  lineHeight="14px"
                  width="38px"
                >
                  {cfdi.count}
                </StyledComponents.SpecialContainer>

                <div>
                  <StyledComponents.SpecialContainer
                    padding="8px 21px"
                    margin="2px 2px 0 0"
                    background="var(--grey170)"
                    onClick={() => updateCheck(cfdi.id)}
                  >
                    <Assets.CheckBoxIcon
                      toggle={cfdi.is_check}
                      onClick={""}
                    ></Assets.CheckBoxIcon>
                  </StyledComponents.SpecialContainer>
                </div>
                <StyledComponents.SpecialContainer
                  width="300px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--grey50)"
                  lineHeight="14px"
                >
                  {cfdi.planActual}
                </StyledComponents.SpecialContainer>
                <StyledComponents.SpecialContainer
                  width="200px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--grey50)"
                  lineHeight="14px"
                >
                  {cfdi.creditosAsignados}
                </StyledComponents.SpecialContainer>
                <StyledComponents.SpecialContainer
                  width="200px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--grey50)"
                  lineHeight="14px"
                  className="crop-text"
                >
                  {cfdi.creditosUsados}
                </StyledComponents.SpecialContainer>
                <StyledComponents.SpecialContainer
                  width="200px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--grey50)"
                  lineHeight="14px"
                >
                  {cfdi.creditosRestantes}
                </StyledComponents.SpecialContainer>
                <StyledComponents.SpecialContainer
                  width="200px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--grey50)"
                  lineHeight="14px"
                >
                  {cfdi.inicioPlan}
                </StyledComponents.SpecialContainer>
                <StyledComponents.SpecialContainer
                  width="210px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--grey50)"
                  lineHeight="14px"
                >
                  {cfdi.finPlan}
                </StyledComponents.SpecialContainer>

              </StyledComponents.SpecialContainer>
            </div>
          );
        })}
      </StyledComponents.OverFlowCfdis>
    </Container>
  );
};

export const Pagination = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [value, setValue] = useState(500);

  const balanceReducer = useSelector((state) => state.cfdiStampBalanceReducer);
  const { list_data, list_page } = balanceReducer;
  const dispatch = useDispatch();

  const pagination = usePagination(500, balanceReducer.list_data.length);
  let totalPages = Array.apply(null, {
    length: Math.ceil(list_data.length / value),
  });
  console.log(totalPages);
  // useEffect(() => {
  //   dispatch(cfdiStampBalaceActions.cfdisMethods("?year=2021", "GetCfdisList"));
  // }, [dispatch, cfdiStampBalaceActions]);

  useEffect(() => {
    let listForPage = list_data.slice(
      pagination.rangeItems.posI,
      pagination.rangeItems.posF
    );

    dispatch(cfdiStampBalaceActions.changeState("list_page", listForPage));
  }, [pagination.rangeItems]);

  // useEffect(() => {
  //   let listForPage = list_data.slice(
  //     pagination.rangeItems.posI,
  //     pagination.rangeItems.posF
  //   );

  //   dispatch(cfdiStampBalaceActions.changeState("list_page", listForPage));
  //   let auxPages = Math.ceil(list_data.length / value)
  //   console.log(auxPages);
  //   //setTotalPages(Array.apply(null, { auxPages }));
  // }, [list_page])

  useEffect(() => {
    console.log(pagination.pages);
    totalPages = Array.apply(null, { length: pagination.pages });
  }, [pagination.pages]);

  const updatePage = (page) => {
    pagination.updateCurrentPage(page);
  };

  const updateShowIn = (val) => {
    setValue(val);
    toggle();
    pagination.updateShow(val);
    pagination.calculatePages(list_data.length, val);
    pagination.updateRangeItems(1, val);
    //pagination.updateCurrentPage(1, val);
  };

  let group = [
    { id: 1, name: 500 },
    { id: 2, name: 1000 },
  ];

  // Actualización de la lista del reducer
  // Hook de showIn
  // Calcular el número de páginas
  // regresar una página
  // avanzar una página
  return (
    <>
      <Layouts.Card borderRadius="0 0 20px 20px">
        <StyledComponents.SpecialContainer
          fontSize="12px"
          className="d-flex"
          padding="20px"
        >
          <StyledComponents.SpecialContainer
            fontStyle="italic"
            className="d-flex"
            width="50%"
          >
            <span className="mr10">Mostrar: </span>
            <div>
              <Dropdown.LayoutDropDown
                maxWidthToggle="224px"
                dropdownToggle={
                  <StyledComponents.SpecialContainer
                    margin="0"
                    className={`d-flex justify-content-between align-items-center bg-grey130`}
                    width="80px"
                    height="32px"
                    padding="0 10px"
                    borderRadius="40px"
                  >
                    <StyledComponents.MyP
                      fontSize="12px"
                      className=" text-black"
                      margin="0"
                    >
                      {value}
                    </StyledComponents.MyP>
                    <span className="d-flex align-items-center">
                      <Assets.IconArrows />
                    </span>
                  </StyledComponents.SpecialContainer>
                }
                dropdownOpen={dropdownOpen}
                toggle={toggle}
                margin={""}
              >
                <StyledComponents.DivToRelative top="35px" zIndex="100">
                  <StyledComponents.OverFlowSelects height={"245px"}>
                    <CardGroups>
                      <ul>
                        {group.map((item) => {
                          return (
                            <li
                              key={item.id}
                              className="pointer"
                              onClick={() => updateShowIn(item.name)}
                            >
                              {item.name}
                            </li>
                          );
                        })}
                      </ul>
                    </CardGroups>
                  </StyledComponents.OverFlowSelects>
                </StyledComponents.DivToRelative>
              </Dropdown.LayoutDropDown>
            </div>
          </StyledComponents.SpecialContainer>
          <div className="d-flex w-100 justify-content-end">
            <div>
              <StyledComponents.SpecialContainer
                fontSize="14px"
                className="d-flex flex-wrap"
                gridGap="2px"
                color="var(--purple40)"
              >
                <StyledComponents.SpecialContainer
                  background={"var(--purple110)"}
                  padding="5px 12px 4px 9px"
                  borderRadius=" 4px 0 0 4px "
                >
                  <Assets.IconArrowRight />
                </StyledComponents.SpecialContainer>
                {totalPages?.map((item, i) => {
                  let page = i + 1;
                  return (
                    <StyledComponents.SpecialContainer
                      padding="7px 11px"
                      background={
                        pagination.currentPage === page && "var(--grey180)"
                      }
                      lineHeight="16px"
                      className={`pointer ${
                        pagination.currentPage === page && "font-weight-bold"
                      }`}
                      onClick={() => updatePage(page)}
                    >
                      {page}
                    </StyledComponents.SpecialContainer>
                  );
                })}
                <StyledComponents.SpecialContainer
                  background={"var(--purple110)"}
                  padding="5px 9px 4px 12px"
                  borderRadius="0 4px 4px 0"
                >
                  <Assets.IconArrowLeft />
                </StyledComponents.SpecialContainer>
              </StyledComponents.SpecialContainer>
            </div>
          </div>
        </StyledComponents.SpecialContainer>
      </Layouts.Card>
    </>
  );
};

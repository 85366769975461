import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { StyledComponents, Modals, Assets } from "../components-config";
import { useInput, useWindowSize } from "../components-config/hooks";
import * as loginActions from "./reducer/loginActions";

// Formulario para envio de recuperación de contraseña.
export const ForgotPassword = ({ redirect = "/" }) => {
  const history = useHistory();
  // seteamos valores del formulario
  const [errorForm, setErrorForm] = useState(false);
  const email = useInput.InputValue(
    "",
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
    "*"
  );

  // reducer
  const loginReducer = useSelector((state) => state.loginReducer);
  const {
    api_actions: { error, cargando },
    data: { send_email_password },
  } = loginReducer;
  const dispatch = useDispatch();

  const Action = (e) => {
    e.preventDefault();
    setErrorForm(false);
    let validate = useInput.ValidateForm([email]);

    if (validate) {
      localStorage.setItem("email", email.inputValue);

      dispatch(
        loginActions.loginMethods(
          {
            username: email.inputValue,
          },
          "SendEmailPassword"
        )
      );
    } else {
      setErrorForm(true);
    }
  };

  if (send_email_password) {
    history.push(redirect);
  }

  return (
    <>
      {cargando && <Modals.ModalLoading></Modals.ModalLoading>}
      <form action="">
        <StyledComponents.MyInput
          margin="0 auto 30px auto"
          maxWidth="260px"
          type="text"
          placeholder="Correo Electrónico"
          value={email.inputValue}
          onChange={(e) => email.inputHandleChange(e)}
          className={`${
            email.validate
              ? "input-success"
              : email.validate === false
              ? "input-error"
              : ""
          }`}
        ></StyledComponents.MyInput>

        <StyledComponents.MyButton
          as="input"
          type="submit"
          maxWidth="260px"
          className="btn-green font-weight-bold"
          value="Solicitar Contraseña"
          onClick={(e) => Action(e)}
        />
      </form>
      {errorForm && (
        <StyledComponents.MyP
          margin="30px auto"
          className="text-danger font-weight-bold text-center"
        >
          Error, No ha introducido ningun email.{" "}
        </StyledComponents.MyP>
      )}
      {error && (
        <StyledComponents.MyP
          margin="30px auto"
          className="text-danger font-weight-bold text-center"
        >
          {error}
        </StyledComponents.MyP>
      )}
    </>
  );
};

// Mensaje de envio exitoso.
export const SendEmailPassword = () => {
  // const dispatch = useDispatch();
  // const [seconds, setSeconds] = useState(5);
  // const history = useHistory();
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setSeconds((seconds) => seconds - 1);
  //   }, 1000);
  //   if (seconds === 0) {
  //     localStorage.removeItem("email");
  //     dispatch(loginActions.loginCleanState());
  //     history.push("/");
  //   }
  //   return () => clearInterval(interval);
  // }, [history, seconds, dispatch]);

  const windowSize = useWindowSize();

  const fontSize = windowSize.width > 767 ? "30px" : "18px";
  return (
    <div className="text-purple40">
      <StyledComponents.SpecialContainer
        margin="0 0 50px 0"
        className="d-flex  align-items-center "
      >
        <StyledComponents.MyP
          margin="0 20px 0 0"
          fontSize="60px"
          className="mr20"
        >
          ¡Listo!
        </StyledComponents.MyP>
        <Assets.IconCheck color="var(--green30)" size="60"></Assets.IconCheck>
      </StyledComponents.SpecialContainer>
      <div className="">
        <StyledComponents.SpecialContainer margin="0 0 50px 0">
          <StyledComponents.MyP fontSize={fontSize}>
            El enlace de recuperación de contraseña fue enviado a la <br />{" "}
            siguiente dirección:
          </StyledComponents.MyP>
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer margin="0 0 50px 0">
          <StyledComponents.MyP
            fontSize={fontSize}
            className="font-weight-bold"
          >
            {localStorage.getItem("email")}
          </StyledComponents.MyP>
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer margin="0 0 50px 0">
          <StyledComponents.MyP
            fontSize={fontSize}
            className="font-weight-bold"
          >
            ¡Muchas gracias! Vuelva pronto.
          </StyledComponents.MyP>
        </StyledComponents.SpecialContainer>
        <StyledComponents.SpecialContainer margin="0 0 50px 0">
          {/* <StyledComponents.MyP fontSize="18px" className="text-blue10">
            Cerrando en {seconds}…
          </StyledComponents.MyP> */}
        </StyledComponents.SpecialContainer>
      </div>
    </div>
  );
};

// Formulario para cambio de contraseña por token.
export const ChangePasswordFromEmail = ({ redirect }) => {
  const history = useHistory();
  const { email, token } = useParams();

  // seteamos valores del formulario
  const [errorForm, setErrorForm] = useState(false);
  const emailDefault = useInput.InputValue("", "", "*");
  emailDefault.inputValue = email;
  const password = useInput.InputValue("", "", "*");
  const confirmPassword = useInput.InputValue("", "", "*");

  // reducer
  const loginReducer = useSelector((state) => state.loginReducer);
  const {
    api_actions: { error, cargando },
    data: { succes_change_password },
  } = loginReducer;
  const dispatch = useDispatch();

  const Action = (e) => {
    e.preventDefault();
    setErrorForm(false);
    let validate = useInput.ValidateForm([email, password, confirmPassword]);

    if (validate && password.inputValue === confirmPassword.inputValue) {
      localStorage.setItem("email", email.inputValue);

      dispatch(
        loginActions.loginMethods(
          {
            username: email,
            password_new: password.inputValue,
            password_new_confirm: confirmPassword.inputValue,
            token: token,
          },
          "ChangePasswordFromEmail"
        )
      );
    } else {
      setErrorForm(true);
    }
  };

  if (succes_change_password) {
    history.push(redirect);
  }

  return (
    <div>
      {cargando && <Modals.ModalLoading></Modals.ModalLoading>}
      <StyledComponents.SpecialContainer maxWidth="300px" margin="auto">
        <form action="">
          <StyledComponents.SpecialContainer padding="0 0 0 18px">
            <label htmlFor="idEmail">Usuario</label>
          </StyledComponents.SpecialContainer>
          <StyledComponents.MyInput
            id="idEmail"
            margin="0 auto 30px auto"
            maxWidth="260px"
            type="text"
            placeholder="Correo Electrónico"
            value={emailDefault.inputValue}
            onChange={(e) => emailDefault.inputHandleChange(e)}
            className={`${
              emailDefault.validate
                ? "input-success"
                : emailDefault.validate === false
                ? "input-error"
                : ""
            }`}
            border={"1px solid black"}
            disabled
          ></StyledComponents.MyInput>
          <StyledComponents.SpecialContainer padding="0 0 0 18px">
            <label htmlFor="idPassword">Contraseña Nueva</label>
          </StyledComponents.SpecialContainer>
          <StyledComponents.MyInput
            id="idPassword"
            margin="0 auto 30px auto"
            maxWidth="260px"
            type="password"
            placeholder="8 caracteres mínimo"
            value={password.inputValue}
            onChange={(e) => password.inputHandleChange(e)}
            className={`${
              password.validate
                ? "input-success"
                : password.validate === false
                ? "input-error"
                : ""
            }`}
            border={"1px solid black"}
          ></StyledComponents.MyInput>
          <StyledComponents.SpecialContainer padding="0 0 0 18px">
            <label htmlFor="idConfirmPassword">
              Confirmar Nueva Contraseña
            </label>
          </StyledComponents.SpecialContainer>
          <StyledComponents.MyInput
            id="idConfirmPassword"
            margin="0 auto 30px auto"
            maxWidth="260px"
            type="password"
            placeholder="8 caracteres mínimo"
            value={confirmPassword.inputValue}
            onChange={(e) => confirmPassword.inputHandleChange(e)}
            className={`${
              confirmPassword.validate
                ? "input-success"
                : confirmPassword.validate === false
                ? "input-error"
                : ""
            }`}
            border={"1px solid black"}
          ></StyledComponents.MyInput>
          <StyledComponents.MyButton
            as="input"
            type="submit"
            maxWidth="260px"
            className="btn-green font-weight-bold"
            value="Confirmar"
            onClick={(e) => Action(e)}
          />
        </form>
      </StyledComponents.SpecialContainer>
      <StyledComponents.SpecialContainer maxWidth={"300px"}>
        {errorForm && (
          <StyledComponents.MyP
            margin="30px auto"
            className="text-danger font-weight-bold text-center"
          >
            {password.inputValue !== confirmPassword.inputValue
              ? "Las contraseñas no coinciden"
              : ""}
          </StyledComponents.MyP>
        )}
        {error && (
          <StyledComponents.MyP
            margin="30px auto"
            className="text-danger font-weight-bold text-center"
          >
            {error}
          </StyledComponents.MyP>
        )}
      </StyledComponents.SpecialContainer>
    </div>
  );
};

// Mensaje externo de cambio de contraseña exitoso.
export const SuccessChangePasswordFromEmail = () => {
  const windowSize = useWindowSize();
  const fontSize = windowSize.width > 767 ? "30px" : "18px";

  return (
    <>
      <div className="text-purple40">
        <StyledComponents.SpecialContainer
          margin="0 0 50px 0"
          className="d-flex align-items-center "
        >
          <StyledComponents.MyP
            margin="0 20px 0 0"
            fontSize="60px"
            className="mr20"
          >
            ¡Listo!
          </StyledComponents.MyP>
          <Assets.IconCheck color="var(--green30)" size="60"></Assets.IconCheck>
        </StyledComponents.SpecialContainer>
        <div className="text-center">
          <StyledComponents.SpecialContainer margin="0 0 50px 0">
            <StyledComponents.MyP fontSize={fontSize}>
              Su contraseña fue actualizada con éxito.
            </StyledComponents.MyP>
          </StyledComponents.SpecialContainer>
        </div>
      </div>
    </>
  );
};

// Cambiar contraseña por usuario.
export const ChangePasswordFromUser = ({ redirect }) => {
  const history = useHistory();

  // seteamos valores del formulario
  const [errorForm, setErrorForm] = useState(false);
  const currentPassword = useInput.InputValue("", "", "*");
  const password = useInput.InputValue("", "", "*");
  const confirmPassword = useInput.InputValue("", "", "*");

  // reducer
  const loginReducer = useSelector((state) => state.loginReducer);
  const {
    api_actions: { error, cargando },
    data: { succes_change_password },
  } = loginReducer;
  const dispatch = useDispatch();

  const Action = (e) => {
    e.preventDefault();
    setErrorForm(false);
    let validate = useInput.ValidateForm([
      currentPassword,
      password,
      confirmPassword,
    ]);

    if (validate && password.inputValue === confirmPassword.inputValue) {
      dispatch(
        loginActions.loginMethods(
          {
            password: currentPassword.inputValue,
            password_new: password.inputValue,
            password_new_confirm: confirmPassword.inputValue,
          },
          "ChangePasswordFromUser"
        )
      );
    } else {
      setErrorForm(true);
    }
  };

  if (succes_change_password) {
    history.push(redirect);
  }

  return (
    <div>
      {cargando && <Modals.ModalLoading></Modals.ModalLoading>}
      <StyledComponents.SpecialContainer maxWidth="300px" margin="auto">
        <form action="">
          <StyledComponents.SpecialContainer padding="0 0 0 18px">
            <label htmlFor="idEmail">Contraseña Actual</label>
          </StyledComponents.SpecialContainer>
          <StyledComponents.MyInput
            id="idEmail"
            margin="0 auto 30px auto"
            maxWidth="260px"
            type="password"
            placeholder="Contraseña"
            value={currentPassword.inputValue}
            onChange={(e) => currentPassword.inputHandleChange(e)}
            className={`${
              currentPassword.validate
                ? "input-success"
                : currentPassword.validate === false
                ? "input-error"
                : ""
            }`}
            border={"1px solid black"}
          ></StyledComponents.MyInput>
          <StyledComponents.SpecialContainer padding="0 0 0 18px">
            <label htmlFor="idPassword">Contraseña Nueva</label>
          </StyledComponents.SpecialContainer>
          <StyledComponents.MyInput
            id="idPassword"
            margin="0 auto 30px auto"
            maxWidth="260px"
            type="password"
            placeholder="8 caracteres mínimo"
            value={password.inputValue}
            onChange={(e) => password.inputHandleChange(e)}
            className={`${
              password.validate
                ? "input-success"
                : password.validate === false
                ? "input-error"
                : ""
            }`}
            border={"1px solid black"}
          ></StyledComponents.MyInput>
          <StyledComponents.SpecialContainer padding="0 0 0 18px">
            <label htmlFor="idConfirmPassword">
              Confirmar Nueva Contraseña
            </label>
          </StyledComponents.SpecialContainer>
          <StyledComponents.MyInput
            id="idConfirmPassword"
            margin="0 auto 30px auto"
            maxWidth="260px"
            type="password"
            placeholder="8 caracteres mínimo"
            value={confirmPassword.inputValue}
            onChange={(e) => confirmPassword.inputHandleChange(e)}
            className={`${
              confirmPassword.validate
                ? "input-success"
                : confirmPassword.validate === false
                ? "input-error"
                : ""
            }`}
            border={"1px solid black"}
          ></StyledComponents.MyInput>
          <StyledComponents.MyButton
            as="input"
            type="submit"
            maxWidth="260px"
            className="btn-green font-weight-bold"
            value="Confirmar"
            onClick={(e) => Action(e)}
          />
        </form>
      </StyledComponents.SpecialContainer>
      <StyledComponents.SpecialContainer
        maxWidth={"300px"}
        className="m-auto d-flex justify-content-center"
      >
        {errorForm && (
          <StyledComponents.MyP
            margin="30px auto"
            className="text-danger font-weight-bold text-center"
          >
            {password.inputValue !== confirmPassword.inputValue
              ? "Las contraseñas no coinciden"
              : ""}
          </StyledComponents.MyP>
        )}
        {error && (
          <StyledComponents.MyP
            margin="30px auto"
            className="text-danger font-weight-bold text-center"
          >
            {error}
          </StyledComponents.MyP>
        )}
      </StyledComponents.SpecialContainer>
    </div>
  );
};

// Mensaje internos de cambio de contraseña exitoso.
export const SuccessChangePasswordFromUser = () => {
  const windowSize = useWindowSize();
  const fontSize = windowSize.width > 767 ? "30px" : "25px";

  return (
    <>
      <div className="">
        <StyledComponents.SpecialContainer
          margin="0 0 50px 0"
          className="d-flex justify-content-center align-items-center "
        >
          <StyledComponents.MyP
            margin="0 20px 0 0"
            fontSize="60px"
            className="mr20"
          >
            ¡Listo!
          </StyledComponents.MyP>
          <Assets.IconCheck color="var(--green30)" size="60"></Assets.IconCheck>
        </StyledComponents.SpecialContainer>
        <div className="text-center">
          <StyledComponents.SpecialContainer margin="0 0 50px 0">
            <StyledComponents.MyP fontSize={fontSize}>
              Su contraseña fue actualizada con éxito.
            </StyledComponents.MyP>
          </StyledComponents.SpecialContainer>
          <StyledComponents.SpecialContainer
            margin="auto"
            className="d-flex justify-content-center"
          >
            <Link to="/dashboard">
              <Assets.IconBack></Assets.IconBack>
            </Link>
          </StyledComponents.SpecialContainer>
        </div>
      </div>
    </>
  );
};

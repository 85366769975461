import React, { useState, useRef, useEffect } from "react";
import { Container, Spinner } from "reactstrap";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import {SpecialContainer} from './StyledComponents'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ReadPdf = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const inputRef = useRef(null);
  const [widthFile, setWidthFile] = useState(1140);

  useEffect(() => {
    const width = inputRef.current?.offsetWidth;
    setWidthFile(width);
    console.log("Input width", width);
  }, [inputRef]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Container className={"d-flex justify-content-center"}>
      
      <SpecialContainer width='inherit' ref={inputRef} className='d-flex justify-content-center mt-5'>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Spinner color="purple" className="d-flex justify-content-center mt-5 text-purple10" />}
        error=''
      >
        <Page width={widthFile} pageNumber={pageNumber} />
      </Document>
      </SpecialContainer>
    </Container>
  );
};

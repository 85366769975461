import { useState, useEffect } from "react";
import axios from "axios";
const PayrollTypes = () => {
  const [payrollTypes, setPayrollTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const tokenSave = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Token ${tokenSave}`,
        "Content-Type": "application/x-www-form-urlencoded",
        "x-app-cocia": localStorage.getItem("x-app-cocia"),
      },
    };
    const response = async () => {
      setIsLoading(true);
      try {
        const respuesta = await axios.get(
          `${
            process.env.REACT_APP_ROUTE
          }/payroll/types/?company=${localStorage.getItem(
            "x-app-cocia"
          )}`,
          config
        );
        setPayrollTypes(respuesta.data)
      } catch {}finally {
        setIsLoading(false);
      }
      //   fetch(
      //     `${
      //       process.env.REACT_APP_ROUTE
      //     }/integrity/payroll/types/?client=${localStorage.getItem(
      //       "x-app-cocia"
      //     )}`,
      //     {
      //       method: "get", // *GET, POST, PUT, DELETE, etc.
      //       headers: {
      //         Authorization: `Token ${tokenSave}`,

      //         //"Content-Type": "application/json",
      //         "Content-Type": "application/x-www-form-urlencoded",
      //       },
      //     }
      //   )
      //     .then((response) => {
      //       response.json();
      //     })
      //     .then((data) => {
      //       setPayrollTypes(data);
      //     });
      setIsLoading(false);
    };
    response();
  }, []);

  return { payrollTypes, isLoading };
};

export default PayrollTypes;

import { axios_api, parser } from "../../components-api/ConfigApi";

export const INCIDENTS_CARGANDO = "INCIDENTS_CARGANDO";
export const INCIDENTS_ERROR = "INCIDENTS_ERROR";
export const INCIDENTS_CLEAN_STATE = "INCIDENTS_CLEAN_STATE";
export const INCIDENTS_CHANGE_STATE = "INCIDENTS_CHANGE_STATE";
export const INCIDENTS_LAYOUT = "INCIDENTS_LAYOUT";
export const INCIDENTS_SEND = "INCIDENTS_SEND";
export const INCIDENTS_KRONOS_NAME = "INCIDENTS_KRONOS_NAME";
export const INCIDENTS_TRANSFER = "INCIDENTS_TRANSFER";
export const INCIDENTS_PAYROLL_LIST = "INCIDENTS_PAYROLL_LIST";
export const INCIDENTS_PAYROLL_PERIOD = "INCIDENTS_PAYROLL_PERIOD";

export const GetIncidentsLayout = async (data) => {
  const answer = await axios_api(
    `/integrity/incidences/layout/download/?client=${data.company_selected}`,
    true,
    "get"
  );
  return { res: answer, type: INCIDENTS_LAYOUT };
};

export const GetListPaychecks = async (data) => {
  const answer = await axios_api(
    `/integrity/payroll/types/?client=${data.company_selected}`,
    true,
    "get"
  );
  return { res: answer, type: INCIDENTS_PAYROLL_LIST };
};

export const GetInfoPayroll = async (data) => {
  const answer = await axios_api(
    `/integrity/payroll/periods/?client=${data.company_selected}&type_payroll=${data.code_payroll}&periodicity=${data.periodicity}`,
    true,
    "get"
  );
  return { res: answer, type: INCIDENTS_PAYROLL_PERIOD };
};

export const PostSendIncidents = async (data) => {
  const answer = await axios_api(`/integrity/incidences/layout/upload/?client=${data.company_selected}`, true, "post", data.file);
  return { res: answer, type: INCIDENTS_SEND };
};

export const GetKronosName = async (data) => {
  const answer = await axios_api(
    `/integrity/kronos/files/?client=${data.company_selected}`,
    true,
    "get"
  );
  return { res: answer, type: INCIDENTS_KRONOS_NAME };
};

export const PostTransferKronos = async (data) => {
  const answer = await axios_api(
    `/integrity/kronos/files/?client=${data.company_selected}`,
    'json',
    "post",
    data,
    1000000
  );

  return { res: answer, type: INCIDENTS_TRANSFER };
};

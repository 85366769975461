import React, { useCallback, forwardRef } from "react";

import StyledInput from "../../config/components/StyledInput";

const Input = forwardRef(({ type, name, isDisabled = false, placeholder, value, onChange, ...restProps }, ref) => {
  const handleChange = useCallback(
    (event) => {
      if (type === "number") {
        event.target.value = Number(event.target.value);
      }
      onChange?.(event);
    },
    [onChange, type]
  );

  return (
    <StyledInput
      id={name}
      ref={ref}
      type={type || "text"}
      name={name}
      disabled={isDisabled}
      placeholder={placeholder}
      defaultValue={value}
      onChange={handleChange}
      {...restProps}
    />
  );
});

export default Input;

import { useSelector } from "react-redux";
import { Modals, StyledComponents } from "../../../components-config";
import { sortBy } from "../utils/collection";
import FormControl from "./FormControl";

const FIELD_NAME_ORDER_BY = "order";

const Form = ({ title, fields = [], submit = {}, onChange, onSubmit }) => {
  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(e);
  }

  const sortedInputs = sortBy(fields, FIELD_NAME_ORDER_BY);

  return (
    <>
      <form onSubmit={handleSubmit}>
        {sortedInputs.map((input, index) => (
          <StyledComponents.SpecialContainer margin="0 20px 0 0" key={`container-${index}`}>
            <FormControl key={index} props={{ ...input, onChange: onChange }} />
          </StyledComponents.SpecialContainer>
        ))}

        <StyledComponents.SpecialContainer margin="20px 0 0 0">
          <StyledComponents.SpecialContainer margin="auto" width="130px">
            <StyledComponents.MyButton
              type={submit.type}
              padding="0"
              height="32px"
              className="bg-green50 pointer font-weight-bold text-grey80">
              {submit.value}
            </StyledComponents.MyButton>
          </StyledComponents.SpecialContainer>
        </StyledComponents.SpecialContainer>
      </form>
    </>
  );
};

export default Form;

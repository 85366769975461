//Falta conectar Reducer
import {
  REPORTS_CARGANDO,
  REPORTS_ERROR,
  REPORTS_CLEAN_STATE,
  REPORTS_CHANGE_STATE,
  REPORTS_FILTERS_LIST,
  REPORTS_DOWNLOAD_LIST,
  REPORTS_SEND_LIST,
} from "./reportTypes";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    error: "",
  },
  report_list: [],
  send_report_list: [],
};

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REPORTS_CARGANDO:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          error: "",
        },
      };
    case REPORTS_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: action.payload,
        },
      };

    case REPORTS_CLEAN_STATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        report_list: [],
        send_report_list: [],
      };

    case REPORTS_DOWNLOAD_LIST:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
      };

    case REPORTS_FILTERS_LIST:
      //debugger
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        report_list: action.payload,
      };
    case REPORTS_SEND_LIST:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
      };

    case REPORTS_CHANGE_STATE:
      return {
        ...state,
        [action.payload.variable]: action.payload.value,
      };

    default:
      return { ...state };
  }
};

export default Reducer;

import * as api from "../services/api";
import {
  conceptFetchSuccess,
  dataOptionsCleanAll,
  fetching,
  filterCleanAll,
  filterConceptAdded,
  filterConceptCleaned,
  filterConceptRemoved,
  filterDateFromChange,
  filterDateToChange,
  filterPayGroupAdded,
  filterPayGroupRemoved,
  filterStructureOptionAdded,
  filterStructureOptionCleaned,
  filterStructureOptionEmployeeChange,
  filterStructureOptionRemoved,
  filterStructureOptionSearchChanged,
  filterStructureTypeChange,
  groupPayFetchSuccess,
  incidenceTypeFilterChange,
  structureOptionFetchSuccess,
  structureTypeFetchSuccess,
  typeFetchSuccess,
} from "./actionTypes";

import {
  selectFilterConceptIsCheck,
  selectFilterIncidenceType,
  selectFilterPayGroupIsCheck,
  selectFilterStrcutureOptionsIsCheck,
  selectFilterStructureType,
} from "../selectors/filters";
import { selectConceptFilteredByGroup } from "../selectors/options";

/**
 * Get type
 */
export const getIncidenceTypes = () => async (dispatch) => {
  dispatch(fetching(true));
  const answer = await api.getIncidenceTypes();
  dispatch(typeFetchSuccess(answer.data));
  dispatch(fetching(false));
};

/**
 * Get concepts
 */
export const getConcepts = (object) => async (dispatch) => {
  dispatch(fetching(true));
  const answer = await api.getConcepts(object);
  dispatch(conceptFetchSuccess(answer.data));
  dispatch(fetching(false));
};

export const cleanAllDataOptions = (object) => async (dispatch) => {
  dispatch(dataOptionsCleanAll());
};

export const cleanAllFilter = (object) => async (dispatch) => {
  dispatch(filterCleanAll());
};

/**
 * Get grouo pay
 */
export const getPayGroups = (value) => async (dispatch) => {
  dispatch(fetching(true));
  const data = { cocia: localStorage.getItem("x-app-cocia") };
  const answer = await api.getPayGroups(data);
  dispatch(groupPayFetchSuccess(answer.data));
  dispatch(fetching(false));
};

/**
 * Get structure type
 */
export const getStructureTypes = () => async (dispatch) => {
  dispatch(fetching(true));
  const answer = await api.getStructureType();
  dispatch(structureTypeFetchSuccess(answer.data));
  dispatch(fetching(false));
};

/**
 * Get departament
 */
export const changeFilterIncidenceType = (item) => async (dispatch, getState) => {
  const incidenceTypeCurrent = selectFilterIncidenceType(getState());

  // Clean concepts
  if (incidenceTypeCurrent !== item.id) {
    dispatch(filterConceptCleaned(item.id));
  }

  // if  selected is current do not continue
  if (incidenceTypeCurrent === item.id) {
    return;
  }

  dispatch(fetching(true));
  dispatch(incidenceTypeFilterChange(item.id));

  const data = { code: item.code, cocia: localStorage.getItem("x-app-cocia") };
  const answer = await api.getConcepts(data);
  dispatch(conceptFetchSuccess(answer.data));

  dispatch(fetching(false));
};

export const changeFilterConceptByGroup = (status, item) => (dispatch, getState) => {
  const filteredByGroup = selectConceptFilteredByGroup(getState(), item);
  const codes = filteredByGroup.map((obj) => obj.code);

  if (status) {
    codes.forEach((code) => {
      dispatch(filterConceptAdded(code));
    });
  } else {
    codes.forEach((code) => {
      dispatch(filterConceptRemoved(code));
    });
  }
};

export const changeFilterConcept = (code) => (dispatch, getState) => {
  const isExist = selectFilterConceptIsCheck(getState(), code);

  if (isExist) {
    dispatch(filterConceptRemoved(code));
  } else {
    dispatch(filterConceptAdded(code));
  }
};

export const changeFilterStructureOptionEmployee = (value) => (dispatch, getState) => {
  dispatch(filterStructureOptionEmployeeChange(value));
};

export const changeFilterStructureOption = (id) => (dispatch, getState) => {
  const isExist = selectFilterStrcutureOptionsIsCheck(getState(), id);

  if (isExist) {
    dispatch(filterStructureOptionRemoved(id));
  } else {
    dispatch(filterStructureOptionAdded(id));
  }
};

export const changeFilterStructureOptionSearch = (value) => (dispatch) => {
  dispatch(filterStructureOptionSearchChanged(value));
};

export const changeFilterPayGroup = (id) => (dispatch, getState) => {
  const isExist = selectFilterPayGroupIsCheck(getState(), id);

  if (isExist) {
    dispatch(filterPayGroupRemoved(id));
  } else {
    dispatch(filterPayGroupAdded(id));
  }
};

export const changeFilterStructureType = (item) => async (dispatch, getState) => {
  const incidenceTypeCurrent = selectFilterStructureType(getState());

  if (incidenceTypeCurrent !== item.code) {
    dispatch(filterStructureOptionCleaned(item.code));
  }

  if (incidenceTypeCurrent === item.code) {
    return;
  }

  dispatch(filterStructureTypeChange(item.code));

  dispatch(fetching(true));

  const data = {
    ...item,
    cocia: localStorage.getItem("x-app-cocia"),
  };

  if(item.code !== 'EMPLOYEE') {
    const answer = await api.getStructureOptions(data);
    dispatch(structureOptionFetchSuccess(answer.data));
  }

  dispatch(fetching(false));
};

export const changeFilterDateFrom = (value) => async (dispatch, getState) => {
  dispatch(filterDateFromChange(value));
};

export const changeFilterDateTo = (value) => async (dispatch, getState) => {
  dispatch(filterDateToChange(value));
};

import {
  DOWNLOAD_CARGANDO,
  DOWNLOAD_ERROR,
  DOWNLOAD_CLEAN_STATE,
  DOWNLOAD_SEND,
  DOWNLOAD_CHANGE_STATE,
} from "./downloadTypes";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    error: "",
  },
  file: "",
  typeFile: "",
  file_name: ""
};

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DOWNLOAD_CARGANDO:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          error: "",
        },
      };
    case DOWNLOAD_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: action.payload,
        },
      };

    case DOWNLOAD_CLEAN_STATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        file: "",
        typeFile: "",
      };
    case DOWNLOAD_CHANGE_STATE:
      return {
        ...state,
        [action.payload.variable]: action.payload.value,
      };
    case DOWNLOAD_SEND:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        file: action.payload.file,
        typeFile: action.payload.extension
          ? action.payload.extension
          : action.payload.typeFile,
      };

    default:
      return { ...state };
  }
};

export default Reducer;

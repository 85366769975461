import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Assets, Dropdown, StyledComponents } from "../components-config";
import { changeFilterPayGroup, getPayGroups } from "./actions/actions";
import CheckBox from "./CheckBox";
import {
  selectFilterPayGroupCount,
  selectFilterPayGroupIsCheck,
  selectFilterPayGroupNotEmpty
} from "./selectors/filters";
import { selectPayGroups } from "./selectors/options";
import { CardConceptStyle } from "./styleComponets";

const ItemWithCheckbox = ({ id, code, description }) => {
  const dispatch = useDispatch();

  const isCheck = useSelector((state) => selectFilterPayGroupIsCheck(state, code));

  function handlerChange() {
    dispatch(changeFilterPayGroup(code));
  }

  return (
    <li key={id}>
      {description}
      <StyledComponents.SpecialContainer margin="0 0 0 3px">
        <CheckBox onChange={handlerChange} status={isCheck}></CheckBox>
      </StyledComponents.SpecialContainer>
    </li>
  );
};

const Item = ({ item }) => {
  return <ItemWithCheckbox {...item} />;
};

const List = ({ options }) => {
  return (
    <ul>
      {options.map((item) => (
        <Item key={item.id} item={item} />
      ))}
    </ul>
  );
};
const OptionPayGroup = () => {
  const dispatch = useDispatch();

  const options = useSelector(selectPayGroups);
  const selectedGreaterThatOne = useSelector(selectFilterPayGroupNotEmpty);
  const selectedCount = useSelector(selectFilterPayGroupCount);

  useEffect(() => {
    dispatch(getPayGroups());
  }, []);

  return (
    <>
      <Dropdown.SelectConcepts
        dropdownTop="32px"
        dropdownToggle={
          <StyledComponents.SpecialContainer
            margin="0 4px 0 0"
            className={`d-flex align-items-center justify-content-between bg-purple90`}
            width="160px"
            minWidth="160px"
            height="32px"
            padding="0 12px"
            borderRadius="4px">
            <StyledComponents.MyP fontSize="12px" className="font-weight-bold" margin="0">
              {selectedGreaterThatOne ? `Grupo de pagos (${selectedCount})` : "Grupo de pagos..."}
            </StyledComponents.MyP>
            <span className="d-flex align-items-center">
              <Assets.IconArrows />
            </span>
          </StyledComponents.SpecialContainer>
        }>
        <StyledComponents.SpecialContainer padding="0 4px 0 0">
            <CardConceptStyle width="200px">
              <List options={options}></List>
            </CardConceptStyle>
        </StyledComponents.SpecialContainer>
      </Dropdown.SelectConcepts>
    </>
  );
};

export default OptionPayGroup;

// Librerias.
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "reactstrap";

// Components Base.
import {
  Table,
  StyledComponents,
  Assets,
  Dropdown,
  Utils,
  Tabs,
  Modals,
  Buttons,
} from "../components-config";

// Reducer.
import * as cafeteriaActions from "./reducer/cafeteriaActions";

// Filtros de fecha de cafetería.
const FilterTitle = () => {
  const cafeteriaReducer = useSelector((state) => state.cafeteriaReducer);
  const dispatch = useDispatch();
  const { month_to, year_to, month_from, year_from, day_from, day_to } =
    cafeteriaReducer;
  const integrityReducer = useSelector((state) => state.integrityReducer);
  const { company_selected, url } = integrityReducer;
  const days_to = Utils.Days(month_to.id, year_to.id);
  const days_from = Utils.Days(month_from.id, year_from.id);
  const searchMeals = () => {
    if (month_from && year_from && day_from && month_to && year_to && day_to) {
      dispatch(
        cafeteriaActions.cafeteriaMethods(
          {
            selected_company: company_selected.id,
            selected_sub_module: url,
            page: 1,
            month_from: month_from.name,
            year_from: year_from.id,
            day_from: day_from.id,
            month_to: month_to.name,
            year_to: year_to.id,
            day_to: day_to.id,
          },
          "GetCafeteriaMealsFilter"
        )
      );
    }
  };

  return (
    <>
      <StyledComponents.MyLayoutSection
        height="70px"
        className="bg-grey50 d-flex align-items-center "
      >
        <Container className="d-flex align-items-center justify-content-center">
          <StyledComponents.MyP
            fontSize="13px"
            className="text-purple40"
            margin="10px"
          >
            Ver desde
          </StyledComponents.MyP>
          <StyledComponents.SpecialContainer margin="0 15px 0 0" width="60px">
            <Dropdown.Select
              overflow={true}
              overflowHeight={"350px"}
              maxWidthToggle="60px"
              reducer="cafeteriaReducer"
              reducerValue="day_from"
              selectClass="filter-cafeteria"
              colorArrow="var(--purple40)"
              items={days_from}
              dropdownTop={"31px"}
              changeState={cafeteriaActions.changeState}
              boxShadow={"0 3px 6px 0 rgba(0, 0, 0, 0.16)"}
            />
          </StyledComponents.SpecialContainer>
          <StyledComponents.SpecialContainer margin="0 15px 0 0" width="120px">
            <Dropdown.Select
              overflow={true}
              overflowHeight={"350px"}
              maxWidthToggle="120px"
              reducer="cafeteriaReducer"
              reducerValue="month_from"
              selectClass="filter-cafeteria"
              colorArrow="var(--purple40)"
              items={Utils.Months}
              dropdownTop={"31px"}
              changeState={cafeteriaActions.changeState}
              boxShadow={"0 3px 6px 0 rgba(0, 0, 0, 0.16)"}
            />
          </StyledComponents.SpecialContainer>
          <StyledComponents.SpecialContainer margin="0 15px 0 0" width="70px">
            <Dropdown.Select
              overflow={true}
              overflowHeight={"350px"}
              maxWidthToggle="70px"
              reducer="cafeteriaReducer"
              reducerValue="year_from"
              selectClass="filter-cafeteria"
              colorArrow="var(--purple40)"
              items={Utils.Years}
              dropdownTop={"31px"}
              changeState={cafeteriaActions.changeState}
              boxShadow={"0 3px 6px 0 rgba(0, 0, 0, 0.16)"}
            />
          </StyledComponents.SpecialContainer>
          <StyledComponents.SpecialContainer margin="0 15px 0 0" width="42px">
            <Assets.IconFilterArrow />
          </StyledComponents.SpecialContainer>
          <StyledComponents.SpecialContainer margin="0 15px 0 0" width="60px">
            <Dropdown.Select
              overflow={true}
              overflowHeight={"350px"}
              maxWidthToggle="60px"
              reducer="cafeteriaReducer"
              reducerValue="day_to"
              selectClass="filter-cafeteria"
              colorArrow="var(--purple40)"
              items={days_to}
              dropdownTop={"31px"}
              changeState={cafeteriaActions.changeState}
              boxShadow={"0 3px 6px 0 rgba(0, 0, 0, 0.16)"}
            />
          </StyledComponents.SpecialContainer>
          <StyledComponents.SpecialContainer margin="0 15px 0 0" width="120px">
            <Dropdown.Select
              overflow={true}
              overflowHeight={"350px"}
              maxWidthToggle="120px"
              reducer="cafeteriaReducer"
              reducerValue="month_to"
              selectClass="filter-cafeteria"
              colorArrow="var(--purple40)"
              items={Utils.Months}
              dropdownTop={"31px"}
              changeState={cafeteriaActions.changeState}
              boxShadow={"0 3px 6px 0 rgba(0, 0, 0, 0.16)"}
            />
          </StyledComponents.SpecialContainer>
          <StyledComponents.SpecialContainer margin="0 15px 0 0" width="70px">
            <Dropdown.Select
              overflow={true}
              overflowHeight={"350px"}
              maxWidthToggle="70px"
              reducer="cafeteriaReducer"
              reducerValue="year_to"
              selectClass="filter-cafeteria"
              colorArrow="var(--purple40)"
              items={Utils.Years}
              dropdownTop={"31px"}
              changeState={cafeteriaActions.changeState}
              boxShadow={"0 3px 6px 0 rgba(0, 0, 0, 0.16)"}
            />
          </StyledComponents.SpecialContainer>
          <div>
            <StyledComponents.MyButton
              maxWidth="90px"
              className="btn-search"
              onClick={searchMeals}
            >
              <div className="d-flex">
                <span>BUSCAR</span>{" "}
                <Assets.IconSearch color="white" size="12" />
              </div>
            </StyledComponents.MyButton>
          </div>
        </Container>
      </StyledComponents.MyLayoutSection>
    </>
  );
};

const TableMeals = ({ items }) => {
  return (
    <>
      <Table
        ths={[
          "Usuario",
          "Asociado",
          "Nombre",
          "Empresa",
          "Cafetería",
          "Fecha",
          "Fila",
          "Consumo",
          "Precio",
          "Validación",
        ]}
        keys={[
          "employee_type",
          "employee_number",
          "full_name",
          "employee_company",
          "cafeteria",
          "date",
          "row",
          "meal_type",
          "meal_price",
          "request_device",
        ]}
        items={items}
      />
    </>
  );
};

const CafeteriaAnalytics = ({ title, number }) => {
  return (
    <StyledComponents.SpecialContainer margin="0 60px 0 0">
      <StyledComponents.MyP fontSize="13px">
        <span className="font-italic">{title}</span>:{" "}
        <span className="font-weight-bold">{number}</span>
      </StyledComponents.MyP>{" "}
    </StyledComponents.SpecialContainer>
  );
};

export const Meals = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("1");

  let [page, setPage] = useState(1);
  let [typeUser, setTypeUser] = useState("all");
  let [filterMeals, setFilterMeals] = useState([]);

  const cafeteriaReducer = useSelector((state) => state.cafeteriaReducer);
  const {
    month_to,
    year_to,
    month_from,
    year_from,
    day_from,
    day_to,
    api_actions: { cargando },
  } = cafeteriaReducer;
  const integrityReducer = useSelector((state) => state.integrityReducer);
  if (integrityReducer.sub_module_selected.name === "Elija un sub-módulo...") {
    history.push(`/dashboard`);
  }
  const { company_selected, url } = integrityReducer;
  const {
    list_cafeteria_meals,
    total_free_registers,
    total_no_subsidy_registers,
    total_registers,
    total_subsidy_registers,
  } = cafeteriaReducer;
  const dispatch = useDispatch();
  useEffect(() => {
    const modelCafeteria = {
      selected_sub_module: url,
      company_selected: company_selected.id,
      page: 1,
    };
    dispatch(
      cafeteriaActions.cafeteriaMethods(modelCafeteria, "GetCafeteriaMeals")
    );
  }, [url, company_selected, dispatch]);

  const showMore = () => {
    let currentPage = page + 1;
    if (month_from && year_from && day_from && month_to && year_to && day_to) {
      dispatch(
        cafeteriaActions.cafeteriaMethods(
          {
            selected_company: company_selected.id,
            selected_sub_module: url,
            page: currentPage,
            month_from: month_from.name,
            year_from: year_from.id,
            day_from: day_from.id,
            month_to: month_to.name,
            year_to: year_to.id,
            day_to: day_to.id,
            add: true,
          },
          "GetCafeteriaMealsFilter"
        )
      );
    } else {
      dispatch(
        cafeteriaActions.cafeteriaMethods(
          {
            selected_company: company_selected.id,
            selected_sub_module: integrityReducer.sub_module_selected.id,
            page: currentPage,
            add: true,
          },
          "GetCafeteriaMeals"
          //"cargandoGeneralReports"
        )
      );
    }
    setPage(currentPage);
  };

  const toggle = (tab) => {
    if (tab === "2") {
      setTypeUser("all");
    }
    if (tab === "2") {
      filterMeals = list_cafeteria_meals.filter(
        (item) => item.employee_type === "TM"
      );
      setTypeUser("tm");
      setFilterMeals(filterMeals.length > 0 ? filterMeals : []);
    }
    if (tab === "3") {
      filterMeals = list_cafeteria_meals.filter(
        (item) => item.employee_type === "Supplier"
      );
      setTypeUser("os");
      setFilterMeals(filterMeals.length > 0 ? filterMeals : []);
    }

    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      {cargando && <Modals.ModalLoading active={true} />}
      <FilterTitle />
      <StyledComponents.MyLayoutSection height="30px" className="bg-grey50 ">
        <Container className="d-flex px-0">
          <Tabs.Titles
            toggle={toggle}
            activeTab={activeTab}
            tabsTitle={[
              "Todos los usuarios",
              "Team Members",
              "On-site-Suppliers",
            ]}
            classTab="tab-cafeteria"
            classTabActive="tab-cafeteria-active"
          />
        </Container>
      </StyledComponents.MyLayoutSection>
      <StyledComponents.MyLayoutSection
        height="77px"
        className="d-flex align-items-center"
      >
        <Container className=" bg-grey10 h-100 ">
          <StyledComponents.SpecialContainer
            padding="0 20px"
            className="d-flex justify-content-between align-items-center h-100"
          >
            <div className="d-flex align-items-center">
              <CafeteriaAnalytics title={"Consumos"} number={total_registers} />
              <CafeteriaAnalytics
                title={"Subsidios"}
                number={total_subsidy_registers}
              />
              <CafeteriaAnalytics
                title={"Gratis"}
                number={total_free_registers}
              />
              <CafeteriaAnalytics
                title={"No Subsidios"}
                number={total_no_subsidy_registers}
              />
            </div>
            <div className="d-flex align-items-center">
              <StyledComponents.SpecialContainer
                margin="0 20px 0 0"
                className="d-flex align-items-center"
              >
                <Buttons.Download
                  fileName={`consumos_${Utils.fixYearOrMonth(
                    day_from.name
                  )}-${Utils.fixYearOrMonth(month_from.id)}-${
                    year_from.name
                  }_${Utils.fixYearOrMonth(
                    day_to.name
                  )}-${Utils.fixYearOrMonth(month_to.id)}-${year_to.name}`}
                  uri={`/integrity/report/cafeteria/download/?client=${company_selected.id}`}
                  data={{
                    type: typeUser,
                    month_from: month_from.name + "",
                    year_from: year_from ? year_from.name + "" : "",
                    day_from: day_from.name + "",
                    month_to: month_to.name + "",
                    year_to: year_to ? year_to.name + "" : "",
                    day_to: day_to.name + "",
                  }}
                  disabled={true}
                />
              </StyledComponents.SpecialContainer>
              <div>
                <Buttons.ButtonSend
                  maxWidth="110px"
                  padding="8px 18px"
                  classButton="btn-send"
                  iconColor="white"
                  ml="ml5"
                  margin="0 4px"
                  isActive={true}
                  description={
                    <StyledComponents.MyP fontSize="18px">
                      El reporte se enviará a la siguiente dirección de correo:
                      <span className="font-weight-bold">
                        {" "}
                        {localStorage.getItem("email")}
                      </span>
                    </StyledComponents.MyP>
                  }
                  description2={
                    <StyledComponents.MyP className="font-weight-bold">
                      Ingrese la dirección de correo a la cual desea que llegue
                      el reporte…
                    </StyledComponents.MyP>
                  }
                  uri={`${url}/send/?client=${company_selected.id}`}
                  modelToSend={{
                    data: {
                      type: typeUser,
                      month_from: month_from.name + "",
                      year_from: year_from ? year_from.name + "" : "",
                      day_from: day_from.name + "",
                      month_to: month_to.name + "",
                      year_to: year_to ? year_to.name + "" : "",
                      day_to: day_to.name + "",
                    },
                  }}
                />
              </div>
            </div>
          </StyledComponents.SpecialContainer>
        </Container>
      </StyledComponents.MyLayoutSection>
      <StyledComponents.OverFlowYY>
        <Container className="px-0">
          <Tabs.Contents
            activeTab={activeTab}
            tabsContent={[
              <TableMeals items={list_cafeteria_meals} />,
              <TableMeals items={list_cafeteria_meals} />,
              <TableMeals items={list_cafeteria_meals} />,
            ]}
          />
        </Container>
        {list_cafeteria_meals.length > 0 ? (
          <div
            className="text-center mb-4 text-blue30 font-weight-bold pointer"
            onClick={showMore}
          >
            Mostrar Más
          </div>
        ) : (
          <div className="text-center mb-2">No hay información disponible</div>
        )}
      </StyledComponents.OverFlowYY>
    </>
  );
};

import {
  FETCHING,
  TYPE_FETCH_SUCCESS,
  CONCEPT_FETCH_SUCCESS,
  PAY_GROUP_FETCH_SUCCESS,
  STRUCTURE_TYPE_FETCH_SUCCESS,
  INCIDENCE_TYPE_FILTER_CHANGE,
  FILTER_CONCEPT_ADDED,
  FILTER_CONCEPT_REMOVED,
  FILTER_CONCEPT_CLEANED,
  FILTER_PAYGROUP_ADDED,
  FILTER_PAYGROUP_REMOVED,
  FILTER_STRUCTURETYPE_CHANGE,
  STRUCTURE_OPTION_FETCH_SUCCESS,
  FILTER_STRUCTUREOPTION_CLEANED,
  FILTER_STRUCTUREOPTION_ADDED,
  FILTER_STRUCTUREOPTION_REMOVED,
  FILTER_DATE_FROM_CHANGED,
  FILTER_DATE_TO_CHANGED,
  FILTER_STRUCTUREOPTION_EMPLOYEE_CHANGE,
  FILTER_STRUCTUREOPTION_SEARCH_CHANGE,
  FILTER_CONCEPT_BY_GROUP_ADDED,
  FILTER_CONCEPT_BY_GROUP_REMOVED,
  FILTER_CLEAN_ALL,
  DATA_OPTIONS_CLEAN_ALL,
} from "./actionTypeKeys";

export const fetching = (status) => {
  return {
    type: FETCHING,
    payload: status,
  };
};

export const typeFetchSuccess = (data) => {
  return {
    type: TYPE_FETCH_SUCCESS,
    payload: data,
  };
};

export const dataOptionsCleanAll = (data) => {
  return {
    type: DATA_OPTIONS_CLEAN_ALL,
    payload: null,
  };
};

export const conceptFetchSuccess = (data) => {
  return {
    type: CONCEPT_FETCH_SUCCESS,
    payload: data,
  };
};
export const filterCleanAll = () => {
  return {
    type: FILTER_CLEAN_ALL,
    payload: null,
  };
};
export const groupPayFetchSuccess = (data) => {
  return {
    type: PAY_GROUP_FETCH_SUCCESS,
    payload: data,
  };
};
export const structureTypeFetchSuccess = (data) => {
  return {
    type: STRUCTURE_TYPE_FETCH_SUCCESS,
    payload: data,
  };
};
export const structureOptionFetchSuccess = (data) => {
  return {
    type: STRUCTURE_OPTION_FETCH_SUCCESS,
    payload: data,
  };
};

export const incidenceTypeFilterChange = (data) => {
  return {
    type: INCIDENCE_TYPE_FILTER_CHANGE,
    payload: data,
  };
};
export const filterConceptCleaned = (data) => {
  return {
    type: FILTER_CONCEPT_CLEANED,
    payload: data,
  };
};

export const filterConceptAdded = (data) => {
  return {
    type: FILTER_CONCEPT_ADDED,
    payload: data,
  };
};

export const filterConceptRemoved = (data) => {
  return {
    type: FILTER_CONCEPT_REMOVED,
    payload: data,
  };
};

export const filterConceptByGroupAdded = (data) => {
  return {
    type: FILTER_CONCEPT_BY_GROUP_ADDED,
    payload: data,
  };
};

export const filterConceptByGroupRemoved = (data) => {
  return {
    type: FILTER_CONCEPT_BY_GROUP_REMOVED,
    payload: data,
  };
};
// Strcuture Option

export const filterStructureOptionCleaned = (data) => {
  return {
    type: FILTER_STRUCTUREOPTION_CLEANED,
    payload: data,
  };
};

export const filterStructureOptionAdded = (data) => {
  return {
    type: FILTER_STRUCTUREOPTION_ADDED,
    payload: data,
  };
};

export const filterStructureOptionEmployeeChange = (data) => {
  return {
    type: FILTER_STRUCTUREOPTION_EMPLOYEE_CHANGE,
    payload: data,
  };
};

export const filterStructureOptionRemoved = (data) => {
  return {
    type: FILTER_STRUCTUREOPTION_REMOVED,
    payload: data,
  };
};

export const filterStructureOptionSearchChanged = (data) => {
  return {
    type: FILTER_STRUCTUREOPTION_SEARCH_CHANGE,
    payload: data,
  };
};

export const filterPayGroupAdded = (data) => {
  return {
    type: FILTER_PAYGROUP_ADDED,
    payload: data,
  };
};

export const filterPayGroupRemoved = (data) => {
  return {
    type: FILTER_PAYGROUP_REMOVED,
    payload: data,
  };
};

export const filterStructureTypeChange = (data) => {
  return {
    type: FILTER_STRUCTURETYPE_CHANGE,
    payload: data,
  };
};

export const filterDateFromChange = (data) => {
  return {
    type: FILTER_DATE_FROM_CHANGED,
    payload: data,
  };
};

export const filterDateToChange = (data) => {
  return {
    type: FILTER_DATE_TO_CHANGED,
    payload: data,
  };
};
import React, { useEffect } from "react";
import { StyledComponents } from "../components-config";
import { useDispatch } from "react-redux";
import { changeFilterDateFrom } from "./actions/actions";

const OptionDateFrom = () => {
  const dispatch = useDispatch();

  function handlerChange(e) {
    dispatch(changeFilterDateFrom(e.target.value));
  }

  useEffect(() => {
    //dispatch(getTypes());
  }, []);

  return (
    <>
      <StyledComponents.SpecialContainer
        margin="0 4px 0 0"
        className="bg-grey130"
        padding="4px"
        borderRadius="40px"
        width="140px"
        height="32px"
      >
      <StyledComponents.MyInput
        height="24px"
        type="date"
        fontSize="12px"
        padding="0"
        style={{fontWeight: 'bold'}}
        onChange={handlerChange}
      ></StyledComponents.MyInput>
      </StyledComponents.SpecialContainer>
    </>
  )
}

export default OptionDateFrom;
// Librerias.
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Container } from "reactstrap";

// Components
import {
  Layouts,
  StyledComponents,
  Dropdown,
  Assets,
} from "../components-config";
import { SendEmail } from "../components-email";

// Actions.
import * as cfdisActions from "./reducer/cfdisActions";

// Hooks.
import { usePagination } from "../components-config/hooks";
import { useHistory } from "react-router-dom";

// CFDIs information.
export const CFDIsInformation = () => {
  return (
    <>
      <Layouts.Info>
        <div>
          <StyledComponents.MyP
            fontSize="14px"
            className="font-weight-bold text-purple40"
          >
            Hola, {localStorage.getItem("username")}.
          </StyledComponents.MyP>
          <StyledComponents.MyP fontSize="14px" className="text-purple40">
            Mediante la parametrización del siguiente filtro podrá obtener los
            CFDI timbrados, de uno o varios empleados hasta todos los del
            periodo, o incluso, los de un año completo y descargarlos, tanto en
            formato “XML” como en “PDF”, así como activar la transferencia de
            los UUID’s hacia el sistema de contabilidad.
          </StyledComponents.MyP>
        </div>
      </Layouts.Info>
    </>
  );
};

export const CardGroups = styled.div`
  background: var(--grey130);
  width: ${(props) => (props.width ? `${props.width}` : "200px")};
  border-radius: 10px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 10px 8px 9px;
    color: black;
  }
  li:hover {
    background-color: var(--grey90);
    //border-radius: 10px;
  }
  li:first-child {
    border-radius: ${(props) =>
      props.borderRadius
        ? `${props.borderRadius}px ${props.borderRadius}px 0 0 `
        : "10px 10px 0 0"};
  }

  li:last-child {
    border: none;
    border-radius: ${(props) =>
      props.borderRadius
        ? ` 0 0 ${props.borderRadius}px ${props.borderRadius}px`
        : "0 0 10px 10px"};
  }
  li:only-child {
    border-radius: ${(props) =>
      props.borderRadius ? `${props.borderRadius}px ` : "10px"};
  }
`;

export const CfDIsSelect = ({
  dropdownTop,
  group,
  reducerValue,
  reducerVariable,
  width,
  overflow = true,
}) => {
  //const integrityReducer = useSelector((state) => state.integrityReducer);
  //const { company_selected } = integrityReducer;
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [value, setValue] = useState(reducerValue);
  const changeStateSelect = (item) => {
    setValue(item.name);
    toggle();
    dispatch(cfdisActions.changeState(reducerVariable, item));
  };
  return (
    <>
      <Dropdown.LayoutDropDown
        maxWidthToggle="224px"
        dropdownToggle={
          <StyledComponents.SpecialContainer
            margin="0"
            className={`d-flex justify-content-between align-items-center bg-grey130`}
            width={width}
            height="32px"
            padding="0 10px"
            borderRadius="40px"
          >
            <StyledComponents.MyP
              fontSize="12px"
              className=" text-black"
              margin="0"
            >
              {value}
            </StyledComponents.MyP>
            <span className="d-flex align-items-center">
              <Assets.IconArrows />
            </span>
          </StyledComponents.SpecialContainer>
        }
        dropdownOpen={dropdownOpen}
        toggle={toggle}
        margin={""}
      >
        <StyledComponents.DivToRelative top={dropdownTop} zIndex="100">
          {overflow ? (
            <StyledComponents.OverFlowSelects height={"235px"}>
              <CardGroups width={width}>
                <ul>
                  {group.map((item) => {
                    return (
                      <li
                        key={item.id}
                        className="pointer"
                        onClick={() => changeStateSelect(item)}
                      >
                        {item?.code ? `${item.code} -` : ""} {item.name}
                      </li>
                    );
                  })}
                </ul>
              </CardGroups>
            </StyledComponents.OverFlowSelects>
          ) : (
            <CardGroups width={width}>
              <ul>
                {group.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className="pointer"
                      onClick={() => changeStateSelect(item)}
                    >
                      {item?.code ? `${item.code} -` : ""} {item.name}
                    </li>
                  );
                })}
              </ul>
            </CardGroups>
          )}
        </StyledComponents.DivToRelative>
      </Dropdown.LayoutDropDown>
    </>
  );
};

// Input global para pintar los inputs en el filtro de CFDIs.
export const CFDIsInput = ({
  disabled,
  placeholder,
  className,
  type = "text",
  onChange,
}) => {
  return (
    <StyledComponents.MyInput
      type={type}
      borderRadius="4px"
      height="32px"
      textAlign="left"
      className={className}
      placeholder={placeholder}
      fontSize="12px"
      padding="10px"
      disabled={disabled}
      onChange={(e) => onChange(e)}
    ></StyledComponents.MyInput>
  );
};

export const CheckAllCFDIs = ({ className }) => {
  const dispatch = useDispatch();
  const cfdisReducer = useSelector((state) => state.cfdisReducer);
  const { list_cfdis } = cfdisReducer;
  const [check, setCheck] = useState(false);
  const checkAll = () => {
    setCheck(!check);
    list_cfdis.forEach((element) => {
      element.is_check = !check;
    });
    dispatch(cfdisActions.changeState("list_cfdis", list_cfdis));
    dispatch(
      cfdisActions.changeState(
        "list_cfdis_selected",
        !check === false ? [] : list_cfdis
      )
    );
  };
  return (
    <>
      <Assets.CheckBoxIcon
        toggle={check}
        onClick={checkAll}
        className={className}
      />
    </>
  );
};

export const CheckCFDIs = ({ CFDIs }) => {
  const history = useHistory();
  const cfdisReducer = useSelector((state) => state.cfdisReducer);
  let { list_page_cfdis, list_cfdis, list_cfdis_selected, overflowY } =
    cfdisReducer;
  const dispatch = useDispatch();

  //const CFDIsList = list_cfdis;
  const updateCheck = (id) => {
    let isCheck = list_cfdis.findIndex((x) => x.id === id);
    list_cfdis[isCheck].is_check = !list_cfdis[isCheck].is_check;
    if (list_cfdis[isCheck].is_check) {
      list_cfdis_selected.push(list_cfdis[isCheck]);
    } else {
      list_cfdis_selected.splice(list_cfdis_selected.indexOf(isCheck), 1);
      //payrollReducer.send_list.splice(payrollReducer.send_list.indexOf(`${pk}`), 1);
    }
    dispatch(cfdisActions.changeState("list_cfdis", list_cfdis));
    //dispatch(cfdisActions.changeState("list_page_cfdis", list_cfdis));
    dispatch(
      cfdisActions.changeState("list_cfdis_selected", list_cfdis_selected)
    );
  };

  const cfdiDetail = (uuid) => {
    history.push(`/dashboard-cfdis-file/${uuid}`);
  };

  return (
    <Container>
      <StyledComponents.OverFlowCfdis height={overflowY}>
        {list_page_cfdis.map((cfdi) => {
          return (
            <div key={cfdi.count}>
              <StyledComponents.SpecialContainer
                fontSize="13px"
                color="var(--purple40)"
                className="d-flex"
              >
                <StyledComponents.SpecialContainer
                  padding="10px 0"
                  margin="2px 2px 0 0"
                  background="var(--purple40)"
                  className="text-white d-flex justify-content-center"
                  fontSize="13px"
                  lineHeight="14px"
                  width="38px"
                >
                  {cfdi.count}
                </StyledComponents.SpecialContainer>

                <div>
                  <StyledComponents.SpecialContainer
                    padding="8px 21px"
                    margin="2px 2px 0 0"
                    background="var(--grey170)"
                    onClick={() => updateCheck(cfdi.id)}
                  >
                    <Assets.CheckBoxIcon
                      toggle={cfdi.is_check}
                      onClick={""}
                    ></Assets.CheckBoxIcon>
                  </StyledComponents.SpecialContainer>
                </div>
                <StyledComponents.SpecialContainer
                  width="70px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--grey50)"
                  lineHeight="14px"
                >
                  {cfdi.numEmpleado}
                </StyledComponents.SpecialContainer>
                <StyledComponents.SpecialContainer
                  width="80px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--grey50)"
                  lineHeight="14px"
                >
                  {cfdi.periodo}
                </StyledComponents.SpecialContainer>
                <StyledComponents.SpecialContainer
                  width="300px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--grey50)"
                  lineHeight="14px"
                  className="crop-text"
                >
                  {cfdi.recNombre}
                </StyledComponents.SpecialContainer>
                <StyledComponents.SpecialContainer
                  width="140px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--grey50)"
                  lineHeight="14px"
                >
                  {cfdi.fechaTimbre?.slice(0, 10)}
                </StyledComponents.SpecialContainer>
                <StyledComponents.SpecialContainer
                  width="330px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--grey50)"
                  lineHeight="14px"
                >
                  {cfdi.uuid}
                </StyledComponents.SpecialContainer>
                <StyledComponents.SpecialContainer
                  width="80px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--grey50)"
                  lineHeight="14px"
                >
                  {cfdi.status === "T" ? "Timbrado" : "Cancelado"}
                </StyledComponents.SpecialContainer>
                <StyledComponents.SpecialContainer
                  width="73px"
                  padding="10px 10px 10px 10px"
                  margin="2px 2px 0 0"
                  background="var(--purple120)"
                  lineHeight="14px"
                  className="d-flex font-weight-bold pointer"
                  onClick={() => cfdiDetail(cfdi.uuid)}
                >
                  <span className="d-flex align-items-center mr-2">
                    <Assets.IconEye />
                  </span>{" "}
                  Ver
                </StyledComponents.SpecialContainer>
                <StyledComponents.SpecialContainer
                  width="83px"
                  padding="10px 10px 10px 10px"
                  margin="2px 0 0 0"
                  background="var(--purple120)"
                  lineHeight="14px"
                  className="font-weight-bold"
                >
                  <SendEmail
                    isActive={true}
                    description={
                      <p>
                        El CFDI será enviado en formato XML y PDF a la siguiente
                        dirección:
                        <span className="font-weight-bold">
                          {" "}
                          {localStorage.getItem("email")}
                        </span>
                      </p>
                    }
                    description2={
                      <StyledComponents.MyP
                        fontSize="18px"
                        className={"font-weight-bold"}
                      >
                        Ingrese la dirección de correo a la cual desea que le
                        llegue el CFDI:
                      </StyledComponents.MyP>
                    }
                    uri={`/payroll/receipts/${cfdi.uuid}/send-receipt/`}
                    //modelToSend={}
                  >
                    <div className="d-flex">
                      <span className="d-flex align-items-center mr-2">
                        <Assets.IconSend2 />
                      </span>
                      Enviar
                    </div>
                  </SendEmail>
                </StyledComponents.SpecialContainer>
              </StyledComponents.SpecialContainer>
            </div>
          );
        })}
      </StyledComponents.OverFlowCfdis>
    </Container>
  );
};

export const Pagination = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [value, setValue] = useState(500);

  const cfdisReducer = useSelector((state) => state.cfdisReducer);
  const { list_cfdis, list_page_cfdis } = cfdisReducer;
  const dispatch = useDispatch();

  const pagination = usePagination(500, cfdisReducer.list_cfdis.length);
  let totalPages = Array.apply(null, {
    length: Math.ceil(list_cfdis.length / value),
  });
  console.log(totalPages);
  // useEffect(() => {
  //   dispatch(cfdisActions.cfdisMethods("?year=2021", "GetCfdisList"));
  // }, [dispatch, cfdisActions]);

  useEffect(() => {
    let listForPage = list_cfdis.slice(
      pagination.rangeItems.posI,
      pagination.rangeItems.posF
    );

    dispatch(cfdisActions.changeState("list_page_cfdis", listForPage));
  }, [pagination.rangeItems]);

  // useEffect(() => {
  //   let listForPage = list_cfdis.slice(
  //     pagination.rangeItems.posI,
  //     pagination.rangeItems.posF
  //   );

  //   dispatch(cfdisActions.changeState("list_page_cfdis", listForPage));
  //   let auxPages = Math.ceil(list_cfdis.length / value)
  //   console.log(auxPages);
  //   //setTotalPages(Array.apply(null, { auxPages }));
  // }, [list_page_cfdis])

  useEffect(() => {
    console.log(pagination.pages);
    totalPages = Array.apply(null, { length: pagination.pages });
  }, [pagination.pages]);

  const updatePage = (page) => {
    pagination.updateCurrentPage(page);
  };

  const updateShowIn = (val) => {
    setValue(val);
    toggle();
    pagination.updateShow(val);
    pagination.calculatePages(list_cfdis.length, val);
    pagination.updateRangeItems(1, val);
    //pagination.updateCurrentPage(1, val);
  };

  let group = [
    { id: 1, name: 500 },
    { id: 2, name: 1000 },
  ];

  // Actualización de la lista del reducer
  // Hook de showIn
  // Calcular el número de páginas
  // regresar una página
  // avanzar una página
  return (
    <>
      <Layouts.Card borderRadius="0 0 20px 20px">
        <StyledComponents.SpecialContainer
          fontSize="12px"
          className="d-flex"
          padding="20px"
        >
          <StyledComponents.SpecialContainer
            fontStyle="italic"
            className="d-flex"
            width="50%"
          >
            <span className="mr10">Mostrar: </span>
            <div>
              <Dropdown.LayoutDropDown
                maxWidthToggle="224px"
                dropdownToggle={
                  <StyledComponents.SpecialContainer
                    margin="0"
                    className={`d-flex justify-content-between align-items-center bg-grey130`}
                    width="80px"
                    height="32px"
                    padding="0 10px"
                    borderRadius="40px"
                  >
                    <StyledComponents.MyP
                      fontSize="12px"
                      className=" text-black"
                      margin="0"
                    >
                      {value}
                    </StyledComponents.MyP>
                    <span className="d-flex align-items-center">
                      <Assets.IconArrows />
                    </span>
                  </StyledComponents.SpecialContainer>
                }
                dropdownOpen={dropdownOpen}
                toggle={toggle}
                margin={""}
              >
                <StyledComponents.DivToRelative top="35px" zIndex="100">
                  <StyledComponents.OverFlowSelects height={"245px"}>
                    <CardGroups>
                      <ul>
                        {group.map((item) => {
                          return (
                            <li
                              key={item.id}
                              className="pointer"
                              onClick={() => updateShowIn(item.name)}
                            >
                              {item.name}
                            </li>
                          );
                        })}
                      </ul>
                    </CardGroups>
                  </StyledComponents.OverFlowSelects>
                </StyledComponents.DivToRelative>
              </Dropdown.LayoutDropDown>
            </div>
          </StyledComponents.SpecialContainer>
          <div className="d-flex w-100 justify-content-end">
            <div>
              <StyledComponents.SpecialContainer
                fontSize="14px"
                className="d-flex flex-wrap"
                gridGap="2px"
                color="var(--purple40)"
              >
                <StyledComponents.SpecialContainer
                  background={"var(--purple110)"}
                  padding="5px 12px 4px 9px"
                  borderRadius=" 4px 0 0 4px "
                >
                  <Assets.IconArrowRight />
                </StyledComponents.SpecialContainer>
                {totalPages?.map((item, i) => {
                  let page = i + 1;
                  return (
                    <StyledComponents.SpecialContainer
                      padding="7px 11px"
                      background={
                        pagination.currentPage === page && "var(--grey180)"
                      }
                      lineHeight="16px"
                      className={`pointer ${
                        pagination.currentPage === page && "font-weight-bold"
                      }`}
                      onClick={() => updatePage(page)}
                    >
                      {page}
                    </StyledComponents.SpecialContainer>
                  );
                })}
                <StyledComponents.SpecialContainer
                  background={"var(--purple110)"}
                  padding="5px 9px 4px 12px"
                  borderRadius="0 4px 4px 0"
                >
                  <Assets.IconArrowLeft />
                </StyledComponents.SpecialContainer>
              </StyledComponents.SpecialContainer>
            </div>
          </div>
        </StyledComponents.SpecialContainer>
      </Layouts.Card>
    </>
  );
};

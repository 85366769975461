export const FETCHING = `reportQuery/fetching`;
export const TYPE_FETCH_SUCCESS = `reportQuery/typeFetchSuccess`;
export const CONCEPT_FETCH_SUCCESS = `reportQuery/conceptFetchSuccess`;
export const PAY_GROUP_FETCH_SUCCESS = `reportQuery/payGroupFetchSuccess`;
export const STRUCTURE_TYPE_FETCH_SUCCESS = `reportQuery/structureTypeFetchSuccess`;
export const STRUCTURE_OPTION_FETCH_SUCCESS = `reportQuery/structureOptionFetchSuccess`;
export const DEPARTAMENT_FETCH_SUCCESS = `reportQuery/departamentFetchSuccess`;

export const DATA_OPTIONS_CLEAN_ALL = `reportQuery/dataOptionsCleanAll`;
export const FILTER_CLEAN_ALL = `reportQuery/filterCleanAll`;

export const INCIDENCE_TYPE_FILTER_CHANGE = `reportQuery/IncidenceTypeFilterChange`;
export const FILTER_PAYGROUP_ADDED = `reportQuery/filterPayGroupAdded`;
export const FILTER_PAYGROUP_REMOVED = `reportQuery/filterPayGroupRemoved`;
export const FILTER_STRUCTURETYPE_CHANGE = `reportQuery/filterStructureTypeChange`;

export const FILTER_CONCEPT_CLEANED = `reportQuery/filterConceptCleaned`;
export const FILTER_CONCEPT_ADDED = `reportQuery/filterConceptAdded`;
export const FILTER_CONCEPT_REMOVED = `reportQuery/filterConceptRemoved`;
export const FILTER_CONCEPT_BY_GROUP_ADDED = `reportQuery/filterConceptByGroupAdded`;
export const FILTER_CONCEPT_BY_GROUP_REMOVED = `reportQuery/filterConceptByGroupRemoved`;

export const FILTER_STRUCTUREOPTION_CLEANED = `reportQuery/filterStructureOptionCleaned`;
export const FILTER_STRUCTUREOPTION_ADDED = `reportQuery/filterStructureOptionAdded`;
export const FILTER_STRUCTUREOPTION_REMOVED = `reportQuery/filterStructureOptionRemoved`;
export const FILTER_STRUCTUREOPTION_SEARCH_CHANGE = `reportQuery/filterStructureSearchChange`;
export const FILTER_STRUCTUREOPTION_EMPLOYEE_CHANGE = `reportQuery/filterStructureOptionEmployeeChange`;

export const FILTER_DATE_FROM_CHANGED = `reportQuery/filterDateFromCahnged`;
export const FILTER_DATE_TO_CHANGED = `reportQuery/filterDateToCahnged`;

import {
  PAYROLL_CARGANDO,
  PAYROLL_ERROR,
  PAYROLL_CLEAN_STATE,
  PAYROLL_HANDLE_CHANGE,
  GetPayrolls,
  GetTypePaychecks,
  GetPayrollsFilter,
} from "./payrollTypes";

import { errorHandler } from "../../components-api/ConfigApi";

const parserJson = (obj) => {
  var jsonSerialize = "";
  for (const prop in obj) {
    jsonSerialize += `${prop}=${prop === "paychecks" ? "[" : ""}${obj[prop]}${
      prop === "paychecks" ? "]" : ""
    }&`;
  }
  jsonSerialize = jsonSerialize.slice(0, -1);
  return jsonSerialize;
};

export const payrollCleanState = () => (dispatch) => {
  dispatch({
    type: PAYROLL_CLEAN_STATE,
  });
};

export const payrollVaribleStateChange = (stateValue, data) => (dispatch) => {
  dispatch({
    type: PAYROLL_HANDLE_CHANGE,
    payload: { stateValue, data },
  });
};

export const payrollMethods = (data, method) => async (dispatch) => {
  dispatch({
    type: PAYROLL_CARGANDO,
  });
  try {
    let answer;
    switch (method) {
      case "GetPayrolls":
        answer = await GetPayrolls();
        break;
      case "GetTypePaychecks":
        answer = await GetTypePaychecks();
        break;
      case "GetPayrollsFilter":
        const modelFilter = parserJson(data);
        answer = await GetPayrollsFilter(modelFilter);
        break;
      default:
        break;
    }

    dispatch({
      type: answer.type,
      payload: answer.res.data,
    });
  } catch (error) {
    let messageError = errorHandler(error);
    dispatch({
      type: PAYROLL_ERROR,
      payload: messageError,
    });
  }
};

import { createSelector } from "reselect";
import { selectModule } from "./module";

export const selectOptions = createSelector(selectModule, (module) => module.options);

export const selectIncidenceTyeList = createSelector(
  selectOptions,
  (options) => options.incidenceTypes.list
);
export const selectConceptList = createSelector(selectOptions, (options) =>
  options.concepts.list
    .filter((objeto) => objeto.code != null)
    .sort((a, b) => a.description - b.description)
);
export const selectPayGroups = createSelector(selectOptions, (options) => options.payGroups);
export const selectStructureTypes = createSelector(
  selectOptions,
  (options) => options.structureTypes
);
export const selectStructureOptions = createSelector(
  selectOptions,
  (options) => options.structureOptions
);
export const selectStructureOptionSearch = createSelector(
  selectOptions,
  (options) => options.structureOptionSearch
);

export const selectConceptFilteredByGroup = createSelector(
  [selectConceptList, (state, byGroup) => byGroup],
  (options, byGroup) => {
    return options.filter((item) => {
      return (
        item.class_concept === byGroup.class_concept &&
        item.group === byGroup.group &&
        item.subgroup === byGroup.subgroup &&
        item.code !== null
      );
    });
  }
);

export const selectStructureOptionsFilterSearch = createSelector(
  [selectStructureOptions, selectStructureOptionSearch],
  (options, search) => {
    if (!search) {
      return options;
    }

    return options.filter((option) => {
      return ""
        .concat(option.code, option.description)
        .toLowerCase()
        .includes(search.toLowerCase());
    });
  }
);

import {
  SelectCompaniesUser as SelectCompaniesUserComponent,
  SelectCompanies as SelectCompaniesComponent,
  SelectModules as SelectModulesComponent,
  SelectSubModules as SelectSubModulesComponent,
  RedirectSelects as RedirectSelectsComponent,
} from "./Selects";

export const SelectCompaniesUser = SelectCompaniesUserComponent;
export const SelectCompanies = SelectCompaniesComponent;
export const SelectModules = SelectModulesComponent;
export const SelectSubModules = SelectSubModulesComponent;
export const RedirectSelects = RedirectSelectsComponent;

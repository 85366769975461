import { createSlice } from "@reduxjs/toolkit";

const ACTION_DOMAIN = "form/request";

const slice = createSlice({
  name: `${ACTION_DOMAIN}`,
  initialState: {
    api_actions: {
        cargando: false,
        error: "",
      },
  },
  reducers: {
    onLoading(state, action) {
        state.api_actions.cargando = true;
    },
    offLoading(state, action) {
        state.api_actions.cargando = false;
    },
  },
});

export const { onLoading, offLoading } =slice.actions;
export default slice.reducer;

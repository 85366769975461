import React from "react";
import OptionPayGroup from "./OptionPayGrups";
import OptionStructureType from "./OptionStructureType";
import StructureTypeValue from "./StructureTypeValue";
import OptionStructureTypeEmployee from "./OptionStructureTypeEmployee";
import { useSelector } from "react-redux";


const OptionParameterList = () => {
  const reportQueryReducer = useSelector((state) => state.reportQueryReducer);
  const { filters } = reportQueryReducer;

  let structureOption;
  if (filters.structureType === 'EMPLOYEE') {
    structureOption = <OptionStructureTypeEmployee />
  } else {
    structureOption = <StructureTypeValue />
  }

  return (
    <>
      {/* Field Grupo de pago*/}
      <OptionPayGroup />
      {/* Field Tipo de Estructura*/}
      <OptionStructureType />
      {/* Field Estructura */}

      {structureOption}
    </>
  );
};

export default OptionParameterList;

import { FETCHING } from "../actions/actionTypeKeys";
import { uiInitialState } from "./initial";

function ui(state = uiInitialState, action) {
  switch (action.type) {
    case FETCHING:
      return {
        ...state,
        cargando: action.payload,
      };
    default:
      return state;
  }
}

export default ui;

import React from "react";
import {
  ChangePasswordFromUser as ChangePassword,
  SuccessChangePasswordFromUser as SuccessChangePasword,
} from "../../components-session";
export const ChangePasswordFromUser = () => {
  return (
    <>
      <ChangePassword redirect="/succes-password-from-user"></ChangePassword>
    </>
  );
};
export const SuccessChangePasswordFromUser = () => {
  return (
    <>
      <SuccessChangePasword></SuccessChangePasword>
    </>
  );
};

// Librerias.
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components Base.
import {
  Layouts,
  StyledComponents,
  Assets,
  Buttons,
  Modals,
} from "../components-config";

// Components.
import { SelectTypePayroll, ModalErrorIncidents } from "./IncidentsComponents";

// Reducers.
import * as incidentsActions from "./reducer/incidentsActions";
import { RedirectSelects } from "../components-integrity";

// Layout de información de incidencias.
export const IndidentsInfo = () => {
  const integrityReducer = useSelector((state) => state.integrityReducer);
  const { company_selected } = integrityReducer;
  return (
    <>
      <Layouts.Info>
        <div>
          <StyledComponents.MyP
            fontSize="14px"
            className="font-weight-bold text-purple40"
          >
            Hola, {localStorage.getItem("username")}.
          </StyledComponents.MyP>
          <StyledComponents.MyP fontSize="14px" className="text-purple40">
            Favor de NO modificar las columnas del archivo
            “Layout_Otras_Incidencias.xls” ni su formato predefinido, de lo
            contrario el sistema no reconocerá el archivo e impedirá su carga.
            <span className="font-weight-bold">
              Si lo requiere, aquí podrá descargar el layout -{">"}
            </span>
          </StyledComponents.MyP>
        </div>
        <StyledComponents.SpecialContainer
          border="1px dashed var(--purple40)"
          borderRadius="13px"
          minWidth="350px"
          width="350px"
          height="56px"
          className="d-flex align-items-center"
          padding="0 10px 0 20px"
        >
          <Assets.IconSheet width="21" height="26" />
          <StyledComponents.MyP
            className="font-weight-bold text-purple40"
            fontSize="12px"
          >
            Layout_Otras_Incidencias.xls
          </StyledComponents.MyP>
          <Buttons.Download
            fileName="Layout_Otras_Incidencias"
            classButton="bg-blue40"
            padding="0 13px"
            borderRadius="40px"
            uri={`/integrity/incidences/layout/download/?client=${company_selected.id}`}
            data={""}
            disabled={true}
          />
        </StyledComponents.SpecialContainer>
      </Layouts.Info>
    </>
  );
};

// Input global para pintar los inputs en el forumalario de incidenias.
const IncidentsInput = ({
  disabled,
  placeholder,
  className,
  type = "text",
  onChange,
}) => {
  return (
    <StyledComponents.MyInput
      type={type}
      borderRadius="4px"
      height="32px"
      textAlign="left"
      className={className}
      placeholder={placeholder}
      fontSize="12px"
      padding="10px"
      disabled={disabled}
      onChange={(e) => onChange(e)}
    ></StyledComponents.MyInput>
  );
};

// Formalario de incidencias, seleccion de tipo de Nomina.
export const IncidentsForm = () => {
  const incidentsReducer = useSelector((state) => state.incidentsReducer);
  const {
    api_actions: { cargando_payrolls },
    type_payroll,
    file,
    list_payrolls,
    period,
    payment_date,
    success_incidents,
    message_loading,
  } = incidentsReducer;
  const integrityReducer = useSelector((state) => state.integrityReducer);
  const { company_selected } = integrityReducer;
  const dispatch = useDispatch();

  const setDatePayment = (e) => {
    dispatch(incidentsActions.changeState("payment_date", e.target.value));
  };
  const disableByTypePayroll = type_payroll?.periodicity !== 0 ? true : false;
  const disableFileSend = file?.name ? false : true;

  useEffect(() => {
    dispatch(
      incidentsActions.reportsMethods(
        { company_selected: company_selected.id },
        "GetListPaychecks"
      )
    );
  }, [dispatch, company_selected]);

  const uploadFile = (e) => {
    const file = e.target.files[0];
    dispatch(incidentsActions.changeState("file", file));
  };

  const sendFile = () => {
    const data = new FormData();
    data.set("type_payroll", type_payroll.code);
    data.set("date_period", period.date_period);
    data.set("date_period_until", period.date_period_until);
    data.set("date_period_payment", payment_date);
    data.set("period", period.period);
    data.append("file", file);
    dispatch(
      incidentsActions.changeState(
        "message_loading",
        "Cargando Incidencias. Este proceso puede tardar uno o varios minutos…"
      )
    );
    dispatch(
      incidentsActions.reportsMethods(
        { file: data, company_selected: company_selected.id },
        "PostSendIncidents"
      )
    );
  };

  return (
    <>
      {cargando_payrolls && !message_loading && (
        <Modals.ModalLoading message={"Un momento, por favor..."} />
      )}
      {success_incidents.is_success && (
        <Modals.ModalSuccessOrError
          status={true}
          message="La carga de Incidencias se realizó con éxito.
          En breve recibirá un correo con la confirmación..."
        />
      )}
      {success_incidents.errors?.length > 0 && (
        <ModalErrorIncidents items={success_incidents.errors} active={true} />
      )}
      {/* {error && <Modals.ModalSuccessOrError status={false} message={error} />} */}
      <Layouts.Card
        margin="4px 0 0 0"
        padding="20px"
        height="133px"
        borderRadius="0 0 20px 20px"
        className="text-purple40 d-flex"
      >
        <StyledComponents.SpecialContainer
          margin="0 40px 0 0"
          maxWidth="820px"
          className="h-100"
        >
          <StyledComponents.SpecialContainer margin="0 0 8px 0">
            Para cargar al sistema de nóminas el archivo:{" "}
            <span className="font-weight-bold">
              Layout_Otras _Incidencias.xls
            </span>
          </StyledComponents.SpecialContainer>
          <div className="d-flex">
            <StyledComponents.SpecialContainer
              width="200px"
              margin="0 40px 0 0"
            >
              <StyledComponents.SpecialContainer
                margin="0 0 12px 0"
                className="font-weight-bold"
              >
                Tipo de Incidencias
              </StyledComponents.SpecialContainer>
              <div>
                <IncidentsInput
                  disabled={true}
                  placeholder="OTRAS INCIDENCIAS"
                  className="bg-grey110 font-weight-bold"
                />
              </div>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              width="200px"
              margin="0 40px 0 0"
            >
              <StyledComponents.SpecialContainer
                margin="0 0 14px 0"
                className="font-weight-bold"
              >
                Tipo de Nómina
              </StyledComponents.SpecialContainer>
              <div>
                <SelectTypePayroll dropdownTop="36px" group={list_payrolls} />
              </div>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer
              width="200px"
              margin="0 40px 0 0"
            >
              <StyledComponents.SpecialContainer
                margin="0 0 14px 0"
                className="font-weight-bold"
              >
                Fecha de Pago {disableByTypePayroll}
              </StyledComponents.SpecialContainer>
              <div>
                {disableByTypePayroll && payment_date ? (
                  <StyledComponents.SpecialContainer
                    borderRadius="4px"
                    height="32px"
                    textAlign="left"
                    fontSize="12px"
                    padding="10px"
                    className="disabled font-weight-bold bg-grey130 text-black"
                  >
                    {incidentsReducer.payment_date}
                  </StyledComponents.SpecialContainer>
                ) : (
                  <IncidentsInput
                    type={"date"}
                    disabled={disableByTypePayroll}
                    placeholder={payment_date ? payment_date : "aaaa/mm/dd"}
                    className={`${
                      disableByTypePayroll ? "input-disable" : ""
                    } bg-grey130 font-weight-bold`}
                    value={incidentsReducer.payment_date}
                    onChange={(e) => setDatePayment(e)}
                  />
                )}
              </div>
            </StyledComponents.SpecialContainer>
            <StyledComponents.SpecialContainer width="100px">
              <StyledComponents.SpecialContainer
                margin="0 0 14px 0"
                className="font-weight-bold"
              >
                Periodo
              </StyledComponents.SpecialContainer>
              <div>
                <IncidentsInput
                  type="number"
                  disabled={true}
                  placeholder={period.period}
                  className={`${
                    true ? "input-disable" : ""
                  } bg-grey130 font-weight-bold`}
                  value={period.period}
                />
              </div>
            </StyledComponents.SpecialContainer>
          </div>
        </StyledComponents.SpecialContainer>
        <div className="h-100">
          <StyledComponents.SpecialContainer
            margin="8px 0 22px 0"
            padding="10px "
            border="1px solid var(--grey140)"
            width="230px"
            height="32px"
            borderRadius="4px"
            className="d-flex align-items-center text-grey150 font-weight-bold"
            fontSize="12px"
          >
            {file && file.name}
          </StyledComponents.SpecialContainer>
          <StyledComponents.SpecialContainer fontSize="12px" className="d-flex">
            <StyledComponents.MyLabel
              type="text"
              width="100px"
              height={"32px"}
              padding="0 20px"
              margin="0 30px 0 0"
              borderRadius="4px"
              fontSize="12px"
              className={`${
                type_payroll.id && payment_date ? "" : "label-disable"
              } d-flex align-items-center bg-blue50 text-white font-weight-bold`}
              htmlFor="upload-incidents"
            >
              Examinar...
            </StyledComponents.MyLabel>
            <StyledComponents.MyInput
              type="file"
              className="input-file"
              id="upload-incidents"
              disabled={type_payroll.id && payment_date ? false : true}
              onChange={(e) => uploadFile(e)}
            ></StyledComponents.MyInput>

            <StyledComponents.MyButton
              width="100px"
              height={"28px"}
              padding="0 20px"
              margin="0"
              className={`${
                disableFileSend ? "btn-disable" : ""
              } bg-green50 text-grey80 font-weight-bold`}
              disabled={disableFileSend}
              onClick={sendFile}
            >
              <Assets.IconUpload />
              <span className="ml5"> Cargar</span>
            </StyledComponents.MyButton>
          </StyledComponents.SpecialContainer>
        </div>
      </Layouts.Card>
    </>
  );
};

// Modulo de Transferencia de Kronos.
export const IncidentsKronos = () => {
  const incidentsReducer = useSelector((state) => state.incidentsReducer);
  const {
    api_actions: { cargando, error, success },
    kronos_name,
    is_transfer_kronos_file,
    message_loading,
  } = incidentsReducer;
  const integrityReducer = useSelector((state) => state.integrityReducer);
  const { company_selected } = integrityReducer;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      incidentsActions.reportsMethods(
        { company_selected: company_selected.id },
        "GetKronosName"
      )
    );
  }, [dispatch, company_selected]);

  const sendKronos = () => {
    const model = {
      company_selected: company_selected.id,
      filename: kronos_name,
    };
    dispatch(
      incidentsActions.changeState(
        "message_loading",
        "Transfiriendo Kronos. Este proceso puede tardar uno o varios minutos…"
      )
    );
    dispatch(incidentsActions.reportsMethods(model, "PostTransferKronos"));
  };
  return (
    <>
      {cargando && <Modals.ModalLoading message={message_loading} />}
      {success && (
        <Modals.ModalSuccessOrError
          status={true}
          message=" La transferencia de las incidencas de Control de Asistencia (KRONOS) se realizó con éxito. En breve recibirá un correo con la confirmación…"
        />
      )}
      {error && <Modals.ModalSuccessOrError status={false} message={error} />}

      <Layouts.Card
        margin="4px 0 0 0"
        padding="28px 300px 27px 20px"
        height="133px"
        borderRadius="20px"
        className="text-purple40"
      >
        {is_transfer_kronos_file || success ? (
          <div>
            <div className="font-weight-bold">
              CONTROL DE ASISTENCIA (KRONOS)
            </div>
            <div>
              El archivo
              <span className="font-weight-bold">{` ${kronos_name}`}</span> ya
              fue transferido al sistema de nóminas.
            </div>
          </div>
        ) : (
          <>
            <div>
              <div className="font-weight-bold">
                CONTROL DE ASISTENCIA (KRONOS)
              </div>

              <div>
                El archivo
                <span className="font-weight-bold">{` ${kronos_name}`}</span> se
                encuentra listo para ser transferido al sistema de nóminas.
              </div>
            </div>
            <StyledComponents.SpecialContainer fontSize="12px" margin="10px 0">
              <StyledComponents.MyButton
                width="200px"
                height={"28px"}
                padding="0 12px"
                margin="0"
                className="bg-green50 text-grey80 font-weight-bold"
                onClick={sendKronos}
              >
                Transferir Archivo de KRONOS
              </StyledComponents.MyButton>
            </StyledComponents.SpecialContainer>
          </>
        )}
      </Layouts.Card>
    </>
  );
};

// Vista del modulo de incidencias.
export const Incidents = () => {
  // Reducer.
  const integrityReducer = useSelector((state) => state.integrityReducer);
  const { company_selected } = integrityReducer;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(incidentsActions.reportsCleanState()); // Limpiamos reducer cuando se desmonta la vista.
    };
  }, [dispatch]);

  RedirectSelects(); // Limpiamos la pantalla si no tenemos un valor en los selects.

  return (
    <>
      <IndidentsInfo />
      <IncidentsForm />
      <Layouts.Border margin="30px 0" border="1px dashed var(--purple40)" />
      {company_selected.id === "TMMGT" && <IncidentsKronos />}
    </>
  );
};

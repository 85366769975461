import axios from "../api/ConfigApi";

const config = {
  headers:{
    "x-api-key": "xu71fNOFaz3Gu8hDFKcI84vKJu0H0NP5SKg3cSu7",
  }
};

export const getIncidenceTypes = async () => {
  const url = `/catalogs/incidents/type/`;
  return await axios.get(url, config);
};

export const getConcepts = async (data) => {
  const url = `/catalogs/concepts/?company=${data.cocia}&concept=${data.code}`;
  return await axios.get(url, config);
};

export const getPayGroups = async (data) => {
  const url = `/catalogs/payments/groups/?company=${data.cocia}`;
  return await axios.get(url, config);
};

export const getStructureType = async () => {
  const url = `/catalogs/structure/type/`;
  return await axios.get(url, config)
};

export const getStructureOptions = async (data) => {
  const url = `/catalogs/structure/options/?company=${data.cocia}&structure=${data.code}`;
  return await axios.get(url, config);
};

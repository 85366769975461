import ReactGa from "react-ga";

// función para leer las rutas de las páginas visitadas en ga.
export const ReadPage = (route) => {
  if (process.env.REACT_APP_GA === "true") {
    ReactGa.initialize("UA-192626654-1");
    ReactGa.pageview(route);
  }
};

// función para crear eventos em ga.
export const Actions = (category, action) => {
  if (process.env.REACT_APP_GA === "true") {
    ReactGa.event({
      category: category,
      action: action,
    });
  }
};

import { axios_api } from "../../components-api/ConfigApi";

export const LOGIN_CARGANDO = "LOGIN_CARGANDO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_HANDLE_CHANGE = "LOGIN_HANDLE_CHANGE";
export const LOGIN_CLEAN_STATE = "LOGIN_CLEAN_STATE";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_HANDLE_VALIDATION = "LOGIN_HANDLE_VALIDATION";

export const Login = async (data) => {
    const answer = await axios_api(
      "/integrity/login/",
      false,
      "post",
      data,
    );
    return { res: answer, type: LOGIN_USER };
  };

export const SendEmailPassword = async (data) => {
    const answer = await axios_api(
      "/integrity/recover_password_link/",
      false,
      "post",
      data,
    );
    return { res: answer, type: LOGIN_USER };
  };

export const ChangePasswordFromEmail = async (data) => {
    const answer = await axios_api(
      "/integrity/recover_password/",
      false,
      "post",
      data,
    );
    return { res: answer, type: LOGIN_USER };
  };

export const ChangePasswordFromUser = async (data) => {
    const answer = await axios_api(
      "/integrity/change_password/",
      true,
      "post",
      data,
    );
    return { res: answer, type: LOGIN_USER };
  };
       
     
 
import { axios_api } from "../../components-api/ConfigApi";

export const SETTLEMENT_CARGANDO = "SETTLEMENT_CARGANDO";
export const SETTLEMENT_ERROR = "SETTLEMENT_ERROR";
export const SETTLEMENT_CLEAN_STATE = "SETTLEMENT_CLEAN_STATE";
export const SETTLEMENT_USER_LIST = "SETTLEMENT_USER_LIST";
export const SETTLEMENT_FILTER_USER = "SETTLEMENT_FILTER_USER";
export const SETTLEMENT_FILTER_PERIOD = "SETTLEMENT_FILTER_PERIOD";
export const SETTLEMENT_CHANGE_STATE = "SETTLEMENT_CHANGE_STATE";

const parserJson = (obj) => {
  var jsonSerialize = "";
  for (const prop in obj) {
    jsonSerialize += `${prop}=${prop === "paychecks" ? "[" : ""}${obj[prop]}${
      prop === "paychecks" ? "]" : ""
    }&`;
  }
  jsonSerialize = jsonSerialize.slice(0, -1);
  return jsonSerialize;
};

//FILTER SETTLEMENT BY USER
export const GetSettlementUser = async () => {
    return {res: '', SETTLEMENT_FILTER_USER};
}
//FILTER SETTLEMENT BY PERIOD
export const GetSettlementPeriod = async () => {
    return {res: '', SETTLEMENT_FILTER_PERIOD};
}
//LIST SETTLEMENT USERS
export const GetSettlementList = async () => {
    return {res: '', SETTLEMENT_USER_LIST};
}




import React from "react";
import { Incidents } from "../../components-incidents";

export const IncidentsModule = () => {
  return (
    <>
      <Incidents></Incidents>
    </>
  );
};

import {
  CAFETERIA_CARGANDO,
  CAFETERIA_ERROR,
  CAFETERIA_CLEAN_STATE,
  CAFETERIA_MEALS_LIST,
  CAFETERIA_MEALS_LIST_ADD,
  CAFETERIA_CHANGE_STATE,
} from "./cafeteriaTypes";
import { Utils } from "../../components-config";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    error: "",
  },
  data: {},
  qr: "",
  list_cafeteria_meals: [],
  // Cafeteria Filters.
  day_to: { id: 0, name: "1" },
  month_to: { id: 0, name: "enero" },
  year_to: { id: 0, name: "2021" },
  day_from: { id: 0, name: "1" },
  month_from: { id: 0, name: "enero" },
  year_from: { id: 0, name: "2012" },
  // Cafeteria Analytics.
  total_free_registers: 0,
  total_no_subsidy_registers: 0,
  total_registers: 0,
  total_subsidy_registers: 0,
};

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CAFETERIA_CARGANDO:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          error: "",
        },
      };
    case CAFETERIA_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: action.payload,
        },
      };

    case CAFETERIA_CLEAN_STATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        list_cafeteria_meals: [],
      };
    case CAFETERIA_MEALS_LIST:
      let cafeteriData = action.payload[0];
      const myState = {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        list_cafeteria_meals: cafeteriData.registers,
        // Cafeteria Filters.
        day_to: {
          id: cafeteriData.day_to ? cafeteriData.day_to : state.day_to.id,
          name: cafeteriData.day_to ? cafeteriData.day_to : state.day_to.name,
        },
        month_to: {
          id: cafeteriData.month_to ? cafeteriData.month_to : state.month_to.id,
          name: cafeteriData.month_to
            ? Utils.ChooseMonth(cafeteriData.month_to)
            : state.month_to.name,
        },
        year_to: {
          id: cafeteriData.year_to ? cafeteriData.year_to : state.year_to.id,
          name: cafeteriData.year_to ? cafeteriData.year_to : state.year_to.id,
        },
        day_from: {
          id: cafeteriData.day_from ? cafeteriData.day_from : state.day_from.id,
          name: cafeteriData.day_from
            ? cafeteriData.day_from
            : state.day_from.name,
        },
        month_from: {
          id: cafeteriData.month_from
            ? cafeteriData.month_from
            : state.month_from.id,
          name: cafeteriData.month_from
            ? Utils.ChooseMonth(cafeteriData.month_from)
            : state.month_from.name,
        },
        year_from: {
          id: cafeteriData.year_from
            ? cafeteriData.year_from
            : state.year_from.id,
          name: cafeteriData.year_from
            ? cafeteriData.year_from
            : state.year_from.id,
        },
        // Cafeteria Filters.
        total_free_registers: cafeteriData.total_free_registers,
        total_no_subsidy_registers: cafeteriData.total_no_subsidy_registers,
        total_registers: cafeteriData.total_registers,
        total_subsidy_registers: cafeteriData.total_subsidy_registers,
      };
      return myState;

    case CAFETERIA_MEALS_LIST_ADD:
      let newOrderList = state.list_cafeteria_meals;
      if (action.payload[0]) {
        newOrderList = state.list_cafeteria_meals.concat(
          action.payload[0].registers
        );
      }
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },

        total_free_registers: action.payload[0].total_free_registers
          ? action.payload[0].total_free_registers
          : "-",
        total_no_subsidy_registers: action.payload[0]
          .total_no_subsidy_registers
          ? action.payload[0].total_no_subsidy_registers
          : "-",
        total_registers: action.payload[0].total_registers
          ? action.payload[0].total_registers
          : "-",
        total_subsidy_registers: action.payload[0].total_subsidy_registers
          ? action.payload[0].total_subsidy_registers
          : "-",

          list_cafeteria_meals: newOrderList,
      };

    case CAFETERIA_CHANGE_STATE:
      return {
        ...state,
        [action.payload.variable]: action.payload.value,
      };
    default:
      return { ...state };
  }
};

export default Reducer;

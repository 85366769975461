import {
  SETTLEMENT_CARGANDO,
  SETTLEMENT_ERROR,
  SETTLEMENT_CLEAN_STATE,
  SETTLEMENT_CHANGE_STATE,
  SETTLEMENT_USER_LIST,
  SETTLEMENT_FILTER_USER,
  SETTLEMENT_FILTER_PERIOD,
} from "./settlementTypes";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    error: "",
  },
  list_settlement: [],
  filter_user_settlement: {},
  // settlement filter
  employee_number: "",
  type_settlement: { id: 0, name: "Tipo de finiquito" },
  discharge_date: "",
  cause_leave: { id: 0, name: "Causa de baja" },
  period_settlement: { id: 0, name: "Actual" },
  // settlement periods
  periods_settlement: { id: 0, name: "Actual" },
  // settlement authorize
  authorize_settlement: { id: 0, name: "Por Autorizar" },
  list_concepts: [
    {
      id: '0001',
      description: 'SUELDO',
      type: 'Percepcion',
      amount : 0,
      factor: 1666.66,
      factor_selected: '',
      list_factors:[
        {id: 1, name:'Salario diario'},
        {id: 2, name:'Salario diario integrado'},
        {id: 3, name:'Importe manual'},
        {id: 4, name:'Veces UMA'}
      ],
      importe: 14999.94,
      updated: ''
    },
    {
      id: '0002',
      description: 'SUBS_EMPLEO PAGADO EFECTIVO',
      type: 'Percepcion',
      amount : 1500,
      factor: 1666.66,
      factor_selected: '',
      list_factors:[
        {id: 1, name:'Salario diario'},
        {id: 2, name:'Salario diario integrado'},
        {id: 3, name:'Importe manual'},
        {id: 4, name:'Veces UMA'}
      ],
      importe: 14999.94,
      updated: ''
    },
    {
      id: '0003',
      description: 'PRIMA VACACIONAL',
      type: 'Percepcion',
      amount : 1500,
      factor: 1666.66,
      factor_selected: '',
      list_factors:[
        {id: 1, name:'Salario diario'},
        {id: 2, name:'Salario diario integrado'},
        {id: 3, name:'Importe manual'},
        {id: 4, name:'Veces UMA'}
      ],
      importe: 14999.94,
      updated: ''
    },
    {
      id: '0004',
      description: 'AGUINALDO GRAVABLE',
      type: 'Percepcion',
      amount : 1500,
      factor: 1666,
      factor_selected: '',
      list_factors:[
        {id: 1, name:'Salario diario'},
        {id: 2, name:'Salario diario integrado'},
        {id: 3, name:'Importe manual'},
        {id: 4, name:'Veces UMA'}
      ],
      importe: 14999.94,
      updated: ''
    },
    {
      id: '0005',
      description: 'EXCENTO PRIMA VACACIONAL',
      type: 'Percepcion',
      amount : 1500,
      factor: 1666.66,
      factor_selected: '',
      list_factors:[
        {id: 1, name:'Salario diario'},
        {id: 2, name:'Salario diario integrado'},
        {id: 3, name:'Importe manual'},
        {id: 4, name:'Veces UMA'}
      ],
      importe: 14999.94,
      updated: '' 
    },
  ],
};

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETTLEMENT_CARGANDO:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          error: "",
        },
      };
    case SETTLEMENT_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: action.payload,
        },
      };

    case SETTLEMENT_CLEAN_STATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        list_cafeteria_meals: [],
      };
    case SETTLEMENT_CHANGE_STATE:
      return {
        ...state,
        [action.payload.variable]: action.payload.value,
      };
    case SETTLEMENT_USER_LIST:
      const myState = {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        list_settlement: action.payload,
      };
      return myState;
    case SETTLEMENT_FILTER_USER:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        filter_user_settlement: action.payload,
      };
    case SETTLEMENT_FILTER_PERIOD:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        period_settlement: action.payload,
      };
    default:
      return { ...state };
  }
};

export default Reducer;
import React, { useState, useEffect } from "react";
import { StyledComponents } from "../components-config";
import SelectGroup from "./SelectGroup";
import { getIncidenceTypes, changeFilterIncidenceType } from "./actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectIncidenceTyeList } from "./selectors/options";

const OptionIncidenceType = () => {
  const dispatch = useDispatch();
  const options = useSelector(selectIncidenceTyeList);
  const name = "Elija una opción...";

  function handlerChange(item) {
    dispatch(changeFilterIncidenceType(item));
  }

  useEffect(() => {
    dispatch(getIncidenceTypes());
  }, []);

  return (
    <StyledComponents.SpecialContainer
      margin="0 4px 0 0"
      borderRadius="40px"
      width="138px"
      minWidth="138px"
      height="32px"
      className="bg-grey130 d-flex justify-content-center align-items-center">
      <SelectGroup
        group={{ name: name, parameters: options }}
        itemKeyValue={'code'}
        itemKeyLabel={'description'}
        dropdownTop="36px"
        handlerChange={handlerChange}></SelectGroup>
    </StyledComponents.SpecialContainer>
  );
};

export default OptionIncidenceType;

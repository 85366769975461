import { DigitalizationForm } from "../../components-digitalization";
import { RedirectSelects } from "../../components-integrity";

export const DigitalizationModule = () => {
  RedirectSelects();
  return (
    <>
      <DigitalizationForm />
    </>
  );
};

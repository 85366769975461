import {
    CFDI_STAMP_BALANCE_CHARGING,
    CFDI_STAMP_BALANCE_ERROR,
    CFDI_STAMP_BALANCE_CLEAN_STATE,
    CFDI_STAMP_BALANCE_CHANGE_STATE,
    GetStampBalances
  } from "./CFDIStampBalanceTypes";
  
  import { errorHandler } from "../../components-api/ConfigApi";
  
  export const cleanState = () => (dispatch) => {
    dispatch({
      type: CFDI_STAMP_BALANCE_CLEAN_STATE,
    });
  };
  
  export const changeState = (variable, value) => (dispatch) => {
    dispatch({
      type: CFDI_STAMP_BALANCE_CHANGE_STATE,
      payload: {
        variable,
        value
      }
    })
  } 
  
  export const cfdiStampBalanceMethods = (data, method) => async (dispatch) => {
    dispatch({
      type: CFDI_STAMP_BALANCE_CHARGING,
    });
    try {
      let answer;
      switch (method) {
        case "GetStampBalances":
          answer = await GetStampBalances(data);
          break;
        default:
          break;
      }
      dispatch({
        type: answer.type,
        payload: answer.res.data,
      });
    } catch (error) {
      let messageError = errorHandler(error);
      dispatch({
        type: CFDI_STAMP_BALANCE_ERROR,
        payload: messageError,
      });
    }
  };

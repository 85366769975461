import { useState } from "react";

export const Pagination = (currentShow = 500, len) => {
  const [show, setShow] = useState(currentShow); // Cantidad de elementos a mostrar por página.
  const [pages, setPages] = useState(Math.ceil(len / currentShow)); // Número de páginas a mostrar.
  const [currentPage, setCurrentPage] = useState(1); // Posición de la página actual.
  const [rangeItems, setRangeItems] = useState({
    // Rango de la lista a mostrar.
    posI: 0,
    posF: currentShow,
  });

  // Actualiza la cantidad de datos a mostrar.
  const updateShow = (value) => {
    setShow(value);
  };

  // Calcula el número de páginas que puede mostrar.
  const calculatePages = (len, current) => {
    const totalPages = Math.ceil(len / current);
    setPages(totalPages);
  };

  // Actualiza el rango de datos a mostrar.
  const updateRangeItems = (page, current = currentShow) => {
    let posF = current * page;
    let posI = current * page - current;

    if (page === 1) {
      posI = 0;
      posF = current;
    } else if (page === pages) {
      posI = current * (page - 1);
      posF = len;
    }
    setRangeItems({ posI: posI, posF: posF });
  };

  // Actualiza la pagina y el rango a mostrar.
  const updateCurrentPage = (page) => {
    setCurrentPage(page);
    updateRangeItems(page);
  };

  // Avanza uno a la izquierda o a la derecha.
  const updateRLPage = (n) => {
    currentPage += n;
    if (currentPage === 0) {
      currentPage = 1;
    } else if (currentPage > pages) {
      currentPage = pages;
    }
    setCurrentPage(currentPage);
    updateRangeItems(currentPage);
  };

  return {
    show,
    updateShow,
    pages,
    calculatePages,
    currentPage,
    updateCurrentPage,
    updateRangeItems,
    updateRLPage,
    rangeItems,
  };
};

import { createSelector } from "reselect";

const selectModule = (state) => state.cmsReducer;
const selectEntity = createSelector(selectModule, (module) => module.component)

// const selectIsTypeForm = createSelector(selectEntity, (entity) => {
//   return entity.type === COMPONENT_TYPE_FORM;
// });

// const selectIsTypeTransfer = createSelector(selectEntity, (entity) => {
//   return entity.type === COMPONENT_TYPE_TRANSFER;
// });

const selectFormType = createSelector(selectEntity, (entity) => {
  return entity.setting.type;
});

const selectFormTitle = createSelector(selectEntity, (entity) => {
  return entity.setting.title;
});

const selectFormSetting = createSelector(selectEntity, (entity) => entity.setting);

export { selectFormSetting, selectFormType, selectFormTitle };

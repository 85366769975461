import { TextField, NumberField } from "./Field";

const fields = {
  text: TextField,
  number: NumberField,
};

const FormControl = ({ props }) => {
  const { type, hidden } = props;
  const FieldBase = fields[type];

  if (!FieldBase) {
    return null;
  }

  return (
    <>
      <FieldBase {...props} />
    </>
  );
};

export default FormControl;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modals } from "../../../../../components-config";
import { Form } from "../../../components";
import { selectFormType } from "../../../store/selectors/component";
import { selectStatusLoading } from "../../../store/selectors/ui";
import { submitForm } from "../../../store/slices/component";
import { offLoading, onLoading } from "../../../store/slices/uiLoading";

function FormContainer({ setting }) {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectStatusLoading);
  const formType = useSelector(selectFormType);

  const [dataForm, setDataForm] = useState({});
  const [fields, setFields] = useState([]);

  function handlerOnChange(event) {
    setDataForm({ ...dataForm, [event.target.name]: event.target.value });
  }

  function updateFieldsWithErrors(error) {
    let copyFields = JSON.parse(JSON.stringify(fields));

    copyFields.map((item) => {
      if (error.some((obj) => obj.id === item.name)) {
        let finx = error.find((obj) => obj.id === item.name);
        item.error = finx["message"];
      } else {
        item.error = "";
      }
    });

    setFields(copyFields);
  }

  function handlerOnSubmit(event) {
    dispatch(onLoading());

    dispatch(submitForm({ formType: formType, data: dataForm }))
      .unwrap()
      .then((data) => {
        const message = data.detail ? data.detail : "Proceso enviado exitosamente.";
        toast.success(message);
      })
      .catch((response) => {
        updateFieldsWithErrors(response.errors);
      })
      .finally((data) => {
        dispatch(offLoading());
      });
  }

  useEffect(() => {
    setFields(setting.fields);
  }, [setting]);

  return (
    <>
      {isLoading && <Modals.ModalLoading active={true} />}
      <Form
        onChange={handlerOnChange}
        onSubmit={handlerOnSubmit}
        fields={fields}
        submit={setting.submit}
        title={setting.title}
      />
    </>
  );
}

export default FormContainer;

import {
  EMAIL_CARGANDO,
  EMAIL_ERROR,
  EMAIL_CLEAN_STATE,
  EMAIL_SEND,
} from "./emailTypes";

const INITIAL_STATE = {
  api_actions: {
    cargando: false,
    error: "",
  },
  send_email: "",
};

const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EMAIL_CARGANDO:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: true,
          error: "",
        },
      };
    case EMAIL_ERROR:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: action.payload,
        },
      };

    case EMAIL_CLEAN_STATE:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        send_email: "",
      };

    case EMAIL_SEND:
      return {
        ...state,
        api_actions: {
          ...state.api_actions,
          cargando: false,
          error: "",
        },
        send_email: action.payload.code === 202 ? "success" : "error",
      };

    default:
      return { ...state };
  }
};

export default Reducer;
